import Cookies from 'js-cookie'

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  TOKEN_REQUEST,
  TOKEN_SUCCESS,
  TOKEN_ERROR,
  TOKEN_CHECK,
  RECOVERY_REQUEST,
  RECOVERY_SUCCESS,
  RECOVERY_ERROR,
  RESET_REQUEST,
  RESET_SUCCESS,
  RESET_ERROR,
} from 'src/types/auth'

const getLocalStorage = {
  userData: () => {
    const userData = Cookies.get('user') || {}
    try {
      const user = JSON.parse(userData)
      if (user) {
        return user
      }
    } catch (e) {
      return false
    }
    return false
  },
}

const initialState = {
  data: getLocalStorage.userData() || {},
  err: {},
  isAuthenticated: false,
  isFetching: false,
  isLogout: false,
}

const authReducer = (state = initialState, action = {}) => {
  const { type = '', payload = {} } = action

  switch (type) {
    case LOGIN_REQUEST: {
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
      }
    }
    case LOGIN_SUCCESS: {
      const { token = '' } = payload
      Cookies.set('token', token)
      Cookies.set('user', JSON.stringify(payload))

      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        isLogout: false,
        errorMessage: '',
        err: false,
        data: payload,
      }
    }
    case LOGIN_FAILURE: {
      const { message = {}, name = '' } = payload
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        errorMessage: message,
        err: name,
      }
    }
    case LOGOUT_SUCCESS: {
      const { message = {} } = payload
      Cookies.remove('token')
      Cookies.remove('user')
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        isLogout: true,
        err: payload,
        errorMessage: message,
      }
    }
    case TOKEN_REQUEST: {
      return {
        ...state,
        isFetching: true,
        isAuthenticated: true,
      }
    }
    case TOKEN_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
      }
    }
    case TOKEN_ERROR: {
      Cookies.remove('token')
      Cookies.remove('user')
      window.location = '/login'

      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
      }
    }
    case TOKEN_CHECK: {
      const { data = {} } = state
      return {
        ...state,
        data: {
          ...data,
          ...payload,
        },
        isFetching: false,
        isAuthenticated: true,
      }
    }
    case RECOVERY_REQUEST: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case RECOVERY_SUCCESS: {
      const { data = {} } = payload
      return {
        ...state,
        isFetching: false,
        err: false,
        data,
      }
    }
    case RECOVERY_ERROR: {
      const { message = {} } = payload
      return {
        ...state,
        isFetching: false,
        errorMessage: message,
        err: payload,
      }
    }
    case RESET_REQUEST: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case RESET_SUCCESS: {
      const { data = {} } = payload
      return {
        ...state,
        isFetching: false,
        err: false,
        data,
      }
    }
    case RESET_ERROR: {
      const { message = {} } = payload
      return {
        ...state,
        isFetching: false,
        errorMessage: message,
        err: payload,
      }
    }
    default: {
      return state
    }
  }
}

export default authReducer
