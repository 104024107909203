import { api } from 'src/utils/helpers/api'

import { transformQueryParams } from 'src/utils/helpers/transformQueryParams'

export const getOrders = (queryParams = {}) => {
  const query = transformQueryParams(queryParams)

  return api({
    method: 'GET',
    url: `/order/ticket`,
    params: query,
  })
}

export const getOrdersQuotes = (queryParams = {}) => {
  const query = transformQueryParams(queryParams)
  return api({
    method: 'GET',
    url: `/order/ticket?paid=false`,
    params: query,
  })
}

export const getOrderPDF = (orderId = 0) => {
  return api({
    method: 'GET',
    url: `/order/ticket/${orderId}/pdf`,
  })
}

export const expireTicket = (orderId = 0, payload = {}, method = 'POST') => {
  return api({
    method,
    url: `/order/ticket/exchange`,
    data: payload,
  })
}

export const updateOrder = (orderId = 0, eventId = 0) => {
  const payload = { event_id: eventId }
  return api({
    method: 'PUT',
    url: `/order/ticket/${orderId}`,
    data: payload,
  })
}

export const getCashierSummary = (queryParams = {}) => {
  const { date_from, date_to } = queryParams

  return api({
    method: 'GET',
    url: `/order/ticket/resume/${date_from}/${date_to}`,
  })
}

export const getCashierDetail = (queryParams = {}) => {
  const { id, date_from, date_to } = queryParams

  return api({
    method: 'GET',
    url: `/order/ticket/user/${id}/resume/${date_from}/${date_to}`,
  })
}

export const getDayStats = (date) => {
  return api({
    method: 'GET',
    url: `/order/ticket/daily/resume/${date}`,
  })
}

export const getMonthStats = (date) => {
  return api({
    method: 'GET',
    url: `/order/ticket/daily/total/${date}`,
  })
}

//TODO
export const getOrderTicket = (orderId = 0) => {
  return api({
    method: 'GET',
    url: `/order/ticket/${orderId}`,
  })
}

export const getConfigTotems = () => {
  return api({
    method: 'GET',
    url: `https://parqueoasis.s3.us-west-2.amazonaws.com/configs/totem_config-dev.json?v=001`,
    // url: `https://parqueoasis.s3.us-west-2.amazonaws.com/configs/totem_config.json?v=001`,
  })
}

export const AddOrderPayment = (orderId = 0, payload = {}) => {
  return api({
    method: 'POST',
    url: `/order/ticket/${orderId}/payment`,
    data: payload,
  })
}

export const AddOrderTicket = (payload = {}) => {
  return api({
    method: 'POST',
    url: `/order/ticket`,
    data: payload,
  })
}

export const CancelOrderTicket = (payload = {}) => {
  return api({
    method: 'DELETE',
    url: `/order/ticket/cancel`,
    data: payload,
  })
}

export const getPayments = (paymentTransactionId = 0, payload = {}) => {
  return api({
    method: 'GET',
    url: `/payment?transaction_id=${paymentTransactionId}`,
    data: payload,
  })
}
