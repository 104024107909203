import {
  GET_ORDERS_REQUEST,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAILURE,
  GET_ORDERS_QUOTES_REQUEST,
  GET_ORDERS_QUOTES_SUCCESS,
  GET_ORDERS_QUOTES_FAILURE,
  PATCH_EXPIRE_ORDER_TICKET_REQUEST,
  PATCH_EXPIRE_ORDER_TICKET_SUCCESS,
  PATCH_EXPIRE_ORDER_TICKET_FAILURE,
} from '../types/orders'

const initialState = {
  err: {},
  ordersList: { orders: [], total: 0, loading: false },
  ordersQuotesList: { orders: [], total: 0, loading: false },
  selected: { loading: false },
  expireTicket: { loading: false, error: false },
}

const ordersReducer = (state = initialState, action = {}) => {
  const { type = '', payload = {} } = action

  switch (type) {
    case GET_ORDERS_REQUEST: {
      return {
        ...state,
        ordersList: { loading: true },
      }
    }
    case GET_ORDERS_SUCCESS: {
      return {
        ...state,
        ordersList: { loading: false, ...payload },
      }
    }
    case GET_ORDERS_FAILURE: {
      return {
        ...state,
        ordersList: { loading: false, orders: [], total: 0, error: true },
      }
    }
    case GET_ORDERS_QUOTES_REQUEST: {
      return {
        ...state,
        ordersQuotesList: { loading: true },
      }
    }
    case GET_ORDERS_QUOTES_SUCCESS: {
      return {
        ...state,
        ordersQuotesList: { loading: false, ...payload },
      }
    }
    case GET_ORDERS_QUOTES_FAILURE: {
      return {
        ...state,
        ordersQuotesList: { loading: false, orders: [], total: 0, error: true },
      }
    }
    case PATCH_EXPIRE_ORDER_TICKET_REQUEST: {
      return {
        ...state,
        expireTicket: { loading: true, error: false },
      }
    }
    case PATCH_EXPIRE_ORDER_TICKET_SUCCESS: {
      return {
        ...state,
        expireTicket: { loading: false, error: false, ...payload },
      }
    }
    case PATCH_EXPIRE_ORDER_TICKET_FAILURE: {
      return {
        ...state,
        expireTicket: { loading: false, error: true, ...payload },
      }
    }

    default: {
      return state
    }
  }
}

export default ordersReducer
