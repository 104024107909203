export const GET_USERS_REQUEST = 'GET_USERS_REQUEST'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE'

export const GET_USER_REQUEST = 'GET_USER_REQUEST'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_FAILURE = 'GET_USER_FAILURE'

export const PUT_UPDATE_USER_REQUEST = 'PUT_UPDATE_USER_REQUEST'
export const PUT_UPDATE_USER_SUCCESS = 'PUT_UPDATE_USER_SUCCESS'
export const PUT_UPDATE_USER_FAILURE = 'PUT_UPDATE_USER_FAILURE'

export const POST_USER_ADMIN_REQUEST = 'POST_USER_ADMIN_REQUEST'
export const POST_USER_ADMIN_SUCCESS = 'POST_USER_ADMIN_SUCCESS'
export const POST_USER_ADMIN_FAILURE = 'POST_USER_ADMIN_FAILURE'
