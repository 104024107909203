import { api, apiWithoutAuth } from 'src/utils/helpers/api'
import { transformQueryParams } from 'src/utils/helpers/transformQueryParams'

export const login = (payload) =>
  apiWithoutAuth({
    method: 'POST',
    url: '/auth/login',
    data: payload,
  })
export const recovery = (payload) =>
  apiWithoutAuth({
    method: 'POST',
    url: '/auth/token',
    data: payload,
  })
export const resetCreds = (payload) =>
  api({
    method: 'PUT',
    url: '/auth/password',
    data: payload,
  })

export const getUsers = (queryParams = {}) => {
  const query = transformQueryParams(queryParams)
  return api({
    method: 'GET',
    url: `/user`,
    params: query,
  })
}

export const getUser = (userId) => {
  return api({
    method: 'GET',
    url: `/user/${userId}`,
  })
}

export const insertUser = (payload) =>
  api({
    method: 'POST',
    url: '/user',
    data: payload,
  })

export const createUser = (payload) =>
  api({
    method: 'POST',
    url: '/user',
    data: payload,
  })

export const putUser = (id, payload) =>
  api({
    method: 'PUT',
    url: `/user/${id}`,
    data: payload,
  })

export const getRoles = () =>
  api({
    method: 'GET',
    url: '/role',
  })
