import React, { useState, useEffect } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
import {
  CButtonGroup,
  CButton,
  CCard,
  CCardHeader,
  CCardBody,
  CSmartPagination,
  CBadge,

} from '@coreui/react-pro'

import SmartTable from 'src/components/SmartTable/SmartTable'

import { getTickets } from 'src/request/tickets'

// Helpers
import { decodeISODate, formatCLP } from 'src/utils/helpers'
import { APPS_NAMES, LIMIT_TO_SHOW, TABLE_COLUMNS } from './constants'

// Styles
import './style.css'

const Cargar = () => {

  const [tickets, setTickets] = useState([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPage] = useState(0)
  const [params, setParams] = useState({
    limit: LIMIT_TO_SHOW,
    offset: 0,
  })

  // const showPage = page + MIN_PAGE

  const getTicketsList = (args) => {
    getTickets(args).then(({ data }) => {
      const { total = 0 } = data || {}
      setTotalPage(Math.ceil(total / LIMIT_TO_SHOW))
      setTickets(data.tickets)
      // console.log(args)
      setLoading(false)
    })
  }

  const handleChangePage = (value) => {
    setLoading(true)
    const offset = (value - 1) * LIMIT_TO_SHOW
    setPage(value === 0 ? 1 : value)
    setParams({
      limit: LIMIT_TO_SHOW,
      offset,
    })
  }

/*   const handleDelete = (item) =>
    swal({
      buttons: {
        cancel: 'Cancelar',
        confirm: 'Eliminar',
      },
      dangerMode: true,
      content: (
        <div className="swal__content">
          <div className="mb-4">
            <h3>¿Desdea eliminar este valor?</h3>
          </div>
        </div>
      ),
    }).then(async (value) => {})
 */

  useEffect(() => {
    getTicketsList(params)
  }, [params])

  return (
    <CCard className="table-list">
      <CCardHeader>
        <h1 className="h3">Cargar Valores</h1>
      </CCardHeader>
      <CCardBody>
        <div className="mb-4">
          <CButtonGroup>
            <CButton href={`/cargar-valores/crear`} color="primary">
              Agregar / Editar Valor
            </CButton>
            {/* <CButton onClick={handleCargar} color="secondary">
            Cargar CSV
          </CButton> */}
          </CButtonGroup>
        </div>

        <SmartTable
          responsive="true"
          items={tickets}
          columns={TABLE_COLUMNS}
          noItemsLabel="Sin resultados"
          // columnFilter
          // columnSorter
          // pagination
          // filter={'false'}
          // tableFilter
          // tableFilterPlaceholder="Ingresar valor"
          // tableFilterLabel="Búsqueda"
          // cleaner
          // itemsPerPageSelect
          // itemsPerPage={10}
          // itemsPerPageLabel="Filas por página"
          loading={loading}
          sorterValue={{
            key: 'date',
          }}
          tableProps={{
            hover: true,
          }}
          scopedColumns={{
            id: (item) => <td width={100}>{item.id}</td>,
            date: (item) => <td width={150}>{decodeISODate(item.date)}</td>,
            web_value: (item) => <td width={150}>{formatCLP(item.price_web)}</td>,
            store_value: (item) => <td width={150}>{formatCLP(item.price_day)}</td>,
            night_value: (item) => <td width={150}>{formatCLP(item.price_night)}</td>,
            school_value: (item) => <td width={150}>{formatCLP(item.price_school)}</td>,

            locked: (item) => (
              <td width={200}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 5,
                    flexWrap: 'wrap',
                    fontSize: 16,
                  }}
                >
                  {item.blocked_application?.map((app) => {
                    return (
                      <CBadge key={app.name} color={APPS_NAMES[app.name].color}>
                        <span style={{ whiteSpace: 'nowrap' }}>{APPS_NAMES[app.name].name}</span>
                      </CBadge>
                    )
                  })}
                </div>
              </td>
            ),

            // edit: (item) => (
            //   <td>
            //     <CButtonGroup>
            //       {/* <CButton href={`/cargar-valores/editar/${item.id}`} color="secondary">
            //         Editar
            //       </CButton> */}
            //       <CButton
            //         onClick={() => handleDelete(item)}
            //         color="danger"
            //         variant="outline"
            //         className="btn--delete"
            //       >
            //         Eliminar
            //       </CButton>
            //     </CButtonGroup>
            //   </td>
            // ),
          }}
          // onFilteredItemsChange={(value) => setFiltered(value)}
          clickableRows
        />

        <CSmartPagination
          activePage={page}
          pages={totalPages}
          onActivePageChange={handleChangePage}
          disabled={loading}
        />
      </CCardBody>
    </CCard>
  )
}

export default Cargar
