import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import { isEmpty } from 'lodash'
import { CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react-pro'
import { addUser } from 'src/actions/user'
import { useHandleForm } from 'src/utils/hooks/useHandleForm'
import Form from '../components/Form'
import PasswordComponent from '../components/PasswordSection'
import { ROLES } from '../constants'
import '../style.scss'
import 'react-toastify/dist/ReactToastify.min.css'
import { getAllRoles } from 'src/actions/roles'

toast.configure()

const Create = () => {
  const dispatch = useDispatch()

  const userState = useSelector((state) => state.user)
  const roleState = useSelector((state) => state.role)
  const { isFetching: isLoadingData = false } = userState || {}
  const { data: rolesData = [], err = {} } = roleState || {}
  const rolesId = rolesData.map(({ id = 0 }) => id)
  const [loading, setLoading] = useState(true)
  const [errors, setErrors] = useState('')

  const [validated, setValidated] = useState(false)

  const [rolesOptions, setRolesOptions] = useState([])

  const [formState, setFormState] = useState({
    email: '',
    firstname: '',
    lastname: '',
    additional: [],
    role: [],
    password: '',
  })

  const [passwordState, setPasswordState] = useState({
    password: {
      value: '',
      visible: false,
    },
    repeat_password: {
      value: '',
      visible: false,
    },
    generated: '',
    equal: true,
  })

  const loadRoles = async () => {
    const rolesToShow = ROLES.filter(({ value = 0 }) => rolesId.includes(value)).map(
      ({ value = 0, text = '' }) => ({ value, text }),
    )
    setRolesOptions(rolesToShow)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setLoading(true)

    /*
    const formErrors = {
      firstname: formState.firstname,
    }

    const hasErrors = formErrors.filter(Boolean)

    console.log(hasErrors)

    const { target } = event
    const [elements] = target || []

    */

    const { password: passwordSelected = {} } = passwordState
    const { value: word = '' } = passwordSelected
    if (word) {
      formState['password'] = word
    }

    dispatch(
      addUser({
        ...formState,
        additional: [
          {
            type: 'phone',
            value: formState.phone,
          },
          {
            type: 'dni',
            value: formState.dni,
          },
        ],
      }),
    ).then((data) => {
      const { payload } = data || {}
      const { response } = payload || {}
      const { data: _data } = response || {}
      const { error = '' } = _data || {}
      error && setErrors((prev) => ({ ...prev, default: error }))
      setLoading(false)
    })
    setValidated(true)
  }

  const { handleChangeInput, handleChangePhone, handleChangeSelector } = useHandleForm(setFormState)

  useEffect(() => {
    dispatch(getAllRoles())
    setLoading(false)
  }, [])

  useEffect(() => {
    if (rolesData.length) {
      loadRoles()
    }
  }, [rolesData])

  useEffect(() => {
    if (!isEmpty(err)) {
      toast.error('Ha ocurrido un error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }, [err])

  return (
    <>
      <CCard>
        <CCardHeader>
          <h1 className="h3">Crear Usuario</h1>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol xs={12} sm={8} md={6} xl={3}>
              {!isLoadingData && (
                <Form
                  formState={formState}
                  errors={errors}
                  loading={loading}
                  handleSubmit={handleSubmit}
                  handleChangeInput={handleChangeInput}
                  handleChangeSelector={handleChangeSelector}
                  handleChangePhone={handleChangePhone}
                  rolesOptions={rolesOptions}
                  isEdit={false}
                  validated={validated}
                  passwordSection={
                    <PasswordComponent
                      isEdit={false}
                      passwordState={passwordState}
                      setPasswordState={setPasswordState}
                    />
                  }
                />
              )}
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <ToastContainer />
    </>
  )
}

export default Create
