import {
  GET_EVENTS_REQUEST,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILURE,
  GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  GET_EVENT_FAILURE,
} from 'src/types/event'

const initialState = {
  err: {},
  eventsList: { list: [], loading: false },
  selected: { loading: false },
}

const eventReducer = (state = initialState, action = {}) => {
  const { type = '', payload = {} } = action

  switch (type) {
    case GET_EVENTS_REQUEST: {
      return {
        ...state,
        eventsList: { loading: true },
      }
    }
    case GET_EVENTS_SUCCESS: {
      return {
        ...state,
        eventsList: { loading: false, ...payload },
      }
    }
    case GET_EVENTS_FAILURE: {
      return {
        ...state,
        eventsList: { loading: false, list: [], error: true },
      }
    }
    case GET_EVENT_REQUEST: {
      return {
        ...state,
        selected: { loading: true },
      }
    }
    case GET_EVENT_SUCCESS: {
      return {
        ...state,
        selected: { loading: false, data: payload },
      }
    }
    case GET_EVENT_FAILURE: {
      return {
        ...state,
        selected: { loading: false, error: true },
      }
    }
    default: {
      return state
    }
  }
}

export default eventReducer
