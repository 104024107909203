import React from 'react'
import { CAlert, CCard, CCardBody, CCardHeader, CSmartPagination } from '@coreui/react-pro'
import SmartTable from 'src/components/SmartTable/SmartTable'
import { LIMIT_TO_SHOW, MIN_PAGE, TABLE_COLUMNS, TITLE } from './constants'
import { useQuotes } from './useQuotes'
import { tableColumns } from './tableColumns'

export const Quotes = () => {
  const { quotes, loading, error, page, totalPages, handleChangePage } = useQuotes()

  return (
    <CCard>
      <CCardHeader>
        <h1 className="h2">{TITLE}</h1>
      </CCardHeader>
      <CCardBody>
        {!error && (
          <>
            <SmartTable
              responsive="true"
              items={quotes}
              columns={TABLE_COLUMNS}
              itemsPerPage={LIMIT_TO_SHOW}
              loading={loading}
              itemsPerPageLabel="Filas por página"
              sorterValue={{
                key: 'id',
              }}
              tableProps={{
                hover: true,
              }}
              scopedColumns={tableColumns()}
            />
            {totalPages && (
              <CSmartPagination
                activePage={page + MIN_PAGE}
                pages={totalPages}
                onActivePageChange={handleChangePage}
              />
            )}
          </>
        )}
        {error && <CAlert color="danger">Ha ocurrido un error al cargar los datos</CAlert>}
      </CCardBody>
    </CCard>
  )
}
