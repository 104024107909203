import React, { useState, useEffect } from 'react'
import {
  CRow,
  CCol,
  CCard,
  CCardBody,
  CContainer,
  CWidgetStatsF,
  CCardHeader,
} from '@coreui/react-pro'
import { Line } from 'react-chartjs-2'

import { formatCLP, formatNumber } from 'src/utils/helpers'
import { CURRENT_DATE, FAKE_DATA, MONTH_DATE, SHOW } from './constants'
import { IoCashOutline, IoTicket, IoTicketOutline } from 'react-icons/io5'
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/DateRangePicker'
import { WidgetValue } from 'src/components/WidgetValue'
import { ProgressGroup } from 'src/components/ProgressGroup'
import { StatsDay, StatsMonth } from './components'
import { useSelector } from 'react-redux'
import { hasMatchingRole } from 'src/utils/helpers/hasMatchingRole'

const URL = process.env.REACT_APP_API_URL
const TOKEN = process.env.REACT_APP_API_TOKEN

const Dashboard = () => {
  const [sales, setSales] = useState([])
  const [chart, setChart] = useState()
  const [viewChart, setViewChart] = useState('months')
  const [loading, setLoading] = useState(true)
  const { data = {} } = useSelector((state) => state.auth)
  const { roles = [], firstname, lastname } = data
  const authRoles = [1]

  const handleChart = async (data) => {
    setChart({
      options: {
        plugins: {
          legend: false,
          tooltip: {
            callbacks: {
              label: (value) => `Total: ${formatCLP(value.parsed.y)}`,
            },
          },
        },
        widgets: {
          resposive: true,
        },
        scales: {
          yAxis: {
            ticks: {
              callback: (value) => formatCLP(value),
            },
          },
        },
      },
      months: {
        labels: data?.current_month_data?.map((x) => x.day),
        datasets: [
          {
            data: data?.current_month_data?.map((x) => x.total),
            fill: false,
            backgroundColor: 'rgba(51, 153, 255, 0.7)',
            borderColor: 'rgba(51, 153, 255, 0.2)',
          },
        ],
      },
      years: {
        labels: [2019, 2020, 2021],
        datasets: [
          {
            label: '# of Votes',
            data: [],
            fill: false,
            backgroundColor: 'rgba(51, 153, 255, 0.7)',
            borderColor: 'rgba(51, 153, 255, 0.2)',
          },
        ],
      },
    })
  }

/*   const handleSales = async () =>
    axios(`${URL}/sales`, {
      headers: { Authorization: `Bearer ${TOKEN}` },
    })
      .then(({ data }) => {
        setSales(data)
        console.log(data)
        // console.log(data.monthly_current_year.map((x) => months(x.month)))
        handleChart(data)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
      })
 */
  useEffect(() => {
    handleChart(FAKE_DATA)
    setSales(FAKE_DATA)
    setLoading(false)
    // handleSales()
  }, [])

  return (
    <div className="section">
      <CContainer>
        {hasMatchingRole(roles, authRoles) ? (
          <div className="widget-dashboard">
            {SHOW.daily && <StatsDay currentDate={CURRENT_DATE} />}
            {SHOW.month && <StatsMonth currentMonth={MONTH_DATE} />}
            {SHOW.season && (
              <CCard className="mb-3">
                <CCardBody>
                  {loading ? (
                    'cargando'
                  ) : (
                    <>
                      <div className="mb-4">
                        <h3>Temporada</h3>
                      </div>
                      <div className="mb-4">
                        <CRow>
                          <CCol>
                            <CWidgetStatsF
                              className="mb-3"
                              color="success"
                              icon={<IoCashOutline />}
                              title="Venta"
                              value={sales.current_season ? formatCLP(sales.current_season) : '$0'}
                            />
                          </CCol>
                          <CCol>
                            <CWidgetStatsF
                              className="mb-3"
                              color="info"
                              icon={<IoTicket />}
                              title="Tickets"
                              value={formatNumber(sales.current_tickets * 30)}
                            />
                          </CCol>
                          <CCol>
                            <CWidgetStatsF
                              className="mb-3"
                              color="warning"
                              icon={<IoTicketOutline />}
                              title="Tickets caducados"
                              value={formatNumber(sales.current_tickets * 20)}
                            />
                          </CCol>
                        </CRow>
                      </div>
                      <Line data={chart[viewChart]} height={75} options={chart.options} />
                    </>
                  )}
                </CCardBody>
              </CCard>
            )}
            {SHOW.restaurant && (
              <CCard className="mb-3">
                <CCardHeader>
                  <h3>Restaurant</h3>
                </CCardHeader>
                <CCardBody>
                  <div className="mb-4">
                    <CRow>
                      <CCol xs={12} md={6} lg={4} xl={3}>
                        <DateRangePicker
                          value={CURRENT_DATE}
                          format="dd-MM-yy"
                          rangeDivider="|"
                          maxDate={CURRENT_DATE}
                        />
                      </CCol>
                    </CRow>
                  </div>
                  <div className="mb-4">
                    <CRow>
                      <CCol xs={12} md={6} lg={4} xl={3}>
                        <WidgetValue
                          value={formatNumber(9123)}
                          title="Menús Vendidos"
                          color="info"
                        />
                      </CCol>
                      <CCol xs={12} md={6} lg={4} xl={3}>
                        <WidgetValue
                          value={formatNumber(2634)}
                          title="Desayunos Vendidos"
                          color="success"
                        />
                      </CCol>
                      <CCol xs={12} md={6} lg={4} xl={3}>
                        <WidgetValue
                          value={formatNumber(9.123)}
                          title="Menús Colegios"
                          color="warning"
                        />
                      </CCol>
                      <CCol xs={12} md={6} lg={4} xl={3}>
                        <WidgetValue value={formatNumber(1300)} title="Menús VIP" color="danger" />
                      </CCol>
                      <CCol xs={12}>
                        <hr />
                      </CCol>
                      <CCol xs={12} lg={6}>
                        <div className="py-3">
                          <div className="mb-3">
                            <h6>Menús más vendidos</h6>
                          </div>
                          <ProgressGroup
                            title="Salchipapas"
                            value={formatNumber(191235)}
                            percent={70}
                            color="success"
                          />
                          <ProgressGroup
                            title="Hamburguesa vegana"
                            value={formatNumber(50000)}
                            percent={30}
                            color="info"
                          />
                          <ProgressGroup
                            title="Hamburguesa vegana"
                            value={formatNumber(50000)}
                            percent={30}
                            color="info"
                          />
                          <ProgressGroup
                            title="Hamburguesa vegana"
                            value={formatNumber(50000)}
                            percent={30}
                            color="info"
                          />
                          <ProgressGroup
                            title="Hamburguesa vegana"
                            value={formatNumber(50000)}
                            percent={30}
                            color="info"
                          />
                        </div>
                      </CCol>
                      <CCol xs={12} lg={6}>
                        <div className="py-3">
                          <div className="mb-3">
                            <h6>Programas más vendidos</h6>
                          </div>
                          <ProgressGroup
                            title="Colegio Full day"
                            value={formatNumber(191235)}
                            percent={70}
                            color="warning"
                          />
                          <ProgressGroup
                            title="Programa VIP Day"
                            value={formatNumber(50000)}
                            percent={30}
                            color="danger"
                          />
                          <ProgressGroup
                            title="Programa VIP Day"
                            value={formatNumber(50000)}
                            percent={30}
                            color="danger"
                          />
                          <ProgressGroup
                            title="Programa VIP Day"
                            value={formatNumber(50000)}
                            percent={30}
                            color="danger"
                          />
                          <ProgressGroup
                            title="Programa VIP Day"
                            value={formatNumber(50000)}
                            percent={30}
                            color="danger"
                          />
                        </div>
                      </CCol>
                    </CRow>
                  </div>
                </CCardBody>
              </CCard>
            )}
          </div>
        ) : (
          <>
            <h3 style={{ marginBottom: 0 }}>Hola</h3>
            <h1>
              {firstname} {lastname}
            </h1>
          </>
        )}
      </CContainer>
    </div>
  )
}

export default Dashboard
