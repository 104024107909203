import { useEffect } from 'react'
import { useState } from 'react'
import { getCashierDetail } from 'src/request/orders'
import { formatDateISO } from 'src/utils/helpers'

const initialState = {
  data: [],
  loading: true,
  error: '',
  dates: [new Date(), new Date()],
}

export const useStats = (id) => {
  const [rangeDates, setRangeDates] = useState(initialState.dates)
  const [detail, setDetail] = useState(initialState.data)
  const [loading, setLoading] = useState(initialState.loading)
  const [error, setError] = useState(false)
  const [params, setParams] = useState({
    id,
    date_from: formatDateISO(rangeDates[0]),
    date_to: formatDateISO(rangeDates[1]),
  })

  const fetchDetails = async () => {
    setLoading(true)
    try {
      await getCashierDetail(params).then(({ data }) => {
        setDetail(data)
        console.log(data)
      })
    } catch (e) {
      console.log(e)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  useEffect(() => {
    console.log(rangeDates)
    setParams((prev) => ({
      ...prev,
      date_from: formatDateISO(rangeDates[0]),
      date_to: formatDateISO(rangeDates[1]),
    }))
  }, [rangeDates])

  return {
    detail,
    loading,
    error,
    rangeDates,
    setRangeDates,
  }
}
