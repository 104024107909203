// import React from 'react'
// import { Redirect } from 'react-router-dom'

// Views
import Dashboard from './views/Dashboard'
import Ventas from './views/Ventas'
import VentasCaja from './views/VentasCaja'
import Cotizaciones from './views/Cotizaciones'
import Usuarios from './views/Usuarios/List'
// import UsuariosDetail from './views/Usuarios/detail'
import UsuariosCreate from './views/Usuarios/Create'
import UsuariosEdit from './views/Usuarios/Edit'
import Cierre from './views/Cierre'
import CierreDetail from './views/Cierre/detail'
import Cargar from './views/Cargar'
import CargarCreate from './views/Cargar/Create'
import ExpireTicket from './views/Ventas/expireTicket'
import PaymentMethods  from './views/PaymentMethods'
import MediosDePagoEdit from './views/PaymentMethods/Edit'
import { Quotes } from './views/quotes'

const routes = [
  { path: '/', exact: true, name: 'Home', auth_roles: [1, 2, 3] },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard, auth_roles: [1, 2, 3] },
  { path: '/ventas', exact: true, name: 'Ventas', component: Ventas, auth_roles: [1, 2, 3] },
  { path: '/ventas/expire/:id', name: 'Expire', component: ExpireTicket, auth_roles: [1, 2, 3] },
  { path: '/ventas-caja', name: 'Caja', component: VentasCaja, auth_roles: [1, 2, 3] },
  { path: '/cotizaciones', name: 'Cotizaciones', component: Cotizaciones, auth_roles: [1, 2, 3] },
  { path: '/medios-de-pago', exact: true,name: 'Medios de pago', component: PaymentMethods, auth_roles: [1, 2, 3] },
  { 
    path: '/medios-de-pago/editar/:id',
    name: 'Editar',
    component: MediosDePagoEdit,
    key: 'Editar',
    auth_roles: [1, 2, 3] },
  {
    path: '/usuarios/detalle/:id',
    name: 'Editar',
    component: UsuariosEdit,
    key: 'Editar',
    auth_roles: [1],
  },
  {
    path: '/usuarios/crear',
    exact: true,
    name: 'Crear',
    component: UsuariosCreate,
    key: 'Crear',
    auth_roles: [1],
  },
  { path: '/usuarios', exact: true, name: 'Usuarios', component: Usuarios, auth_roles: [1] },
  { path: '/cierre-cajas/:id', component: CierreDetail, auth_roles: [1, 2, 3] },
  {
    path: '/cierre-cajas',
    exact: true,
    name: 'Cierre de Cajas',
    component: Cierre,
    auth_roles: [1, 2, 3],
  },
  {
    path: '/cargar-valores',
    exact: true,
    name: 'Cargar Valores',
    component: Cargar,
    auth_roles: [1],
  },
  {
    path: '/cargar-valores/crear',
    exact: true,
    name: 'Crear',
    component: CargarCreate,
    auth_roles: [1],
  },

  // Refactor
  { path: '/quotes', name: 'Cotizaciones', component: Quotes, auth_roles: [1, 2, 3] },
]

export default routes
