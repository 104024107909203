import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  CFormInput,
  CFormLabel,
  CInputGroup,
  CInputGroupText,
  CAlert,
  CLoadingButton,
} from '@coreui/react-pro'
import { IoEyeOutline, IoEyeOffOutline } from 'react-icons/io5'
import { generate } from 'generate-password'
import '../style.scss'

const PaswordSection = ({
  isEdit = false,
  passwordState = {},
  setPasswordState,
  handleUpdate,
  isFetching = false,
}) => {
  const [compare, setCompare] = useState('')

  const icon = passwordState?.password?.visible ? <IoEyeOutline /> : <IoEyeOffOutline />

  const handlePass = () => {
    let generated = generate({ length: 10, numbers: true })
    setPasswordState((prevPass) => ({
      ...prevPass,
      password: {
        ...prevPass.password,
        value: generated,
      },
      repeat_password: {
        ...prevPass.repeat_password,
        value: generated,
      },
      generated,
      equal: true,
      notequal: false,
    }))
    setCompare(true)
  }
  const handleChange = (value, name) => {
    setPasswordState((prevPass) => ({ ...prevPass, [name]: { ...prevPass[name], value: value } }))
  }
  const handleCompare = () => {
    if (passwordState.password.value || passwordState.repeat_password.value) {
      if (passwordState.password.value === passwordState.repeat_password.value) {
        setCompare(true)
        setPasswordState((prevPass) => ({ ...prevPass, equal: true }))
      } else {
        setCompare(false)
        setPasswordState((prevPass) => ({ ...prevPass, equal: false }))
      }
    }
  }

  return (
    <div className="mb-5">
      {isEdit && (
        <div className="mb-5">
          <h5>Modificar Password</h5>
        </div>
      )}
      <div className="mb-4">
        <div className="pg">
          <div className="pg__title">Generar Password Segura</div>
          <code className="pg__value">
            {passwordState?.generated || (
              <div className="pg__placeholder">
                <div className="pg__placeholder__item"></div>
                <div className="pg__placeholder__item"></div>
                <div className="pg__placeholder__item"></div>
                <div className="pg__placeholder__item"></div>
                <div className="pg__placeholder__item"></div>
                <div className="pg__placeholder__item"></div>
                <div className="pg__placeholder__item"></div>
              </div>
            )}
          </code>
          <div className="pg__button" onClick={() => handlePass()}>
            Generar
          </div>
        </div>
      </div>
      <div className="mb-4">
        <CFormLabel htmlFor="password">Password</CFormLabel>
        <CInputGroup>
          <CFormInput
            type={passwordState?.password?.visible ? 'text' : 'password'}
            id="password"
            value={passwordState?.password?.value}
            onChange={(el) => handleChange(el.target.value, 'password')}
            onKeyUp={(el) => handleCompare()}
            valid={compare || false}
            {...(Boolean(!isEdit) && { required: true })}
            // invalid={!compare}
          />
          <CInputGroupText
            onClick={() =>
              setPasswordState((prevPass) => ({
                ...prevPass,
                password: {
                  ...prevPass.password,
                  visible: !prevPass.password.visible,
                },
              }))
            }
          >
            {icon}
          </CInputGroupText>
        </CInputGroup>
      </div>
      <div className="mb-4">
        <CFormLabel htmlFor="password">Repetir Password</CFormLabel>
        <CInputGroup>
          <CFormInput
            type={passwordState?.repeat_password?.visible ? 'text' : 'password'}
            id="repetir-password"
            value={passwordState?.repeat_password?.value}
            onChange={(el) => handleChange(el.target.value, 'repeat_password')}
            onKeyUp={(el) => handleCompare()}
            valid={compare || false}
            {...(Boolean(!isEdit) && { required: true })}
            // invalid={!compare}
          />
          <CInputGroupText
            onClick={() =>
              setPasswordState((prevPass) => ({
                ...prevPass,
                repeat_password: {
                  ...passwordState.repeat_password,
                  visible: !passwordState.repeat_password.visible,
                },
              }))
            }
          >
            {icon}
          </CInputGroupText>
        </CInputGroup>
      </div>
      {!passwordState.equal && (
        <CAlert className="alert--small" color="warning">
          Las contraseñas no coinciden
        </CAlert>
      )}

      {isEdit && (
        <div className="mb-4 text-end">
          <CLoadingButton loading={isFetching} onClick={(e) => handleUpdate(e)} type="button">
            Ingresar
          </CLoadingButton>
        </div>
      )}
    </div>
  )
}

PaswordSection.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  passwordState: PropTypes.object.isRequired,
  setPasswordState: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func,
  isFetching: PropTypes.bool,
}

export default PaswordSection
