/* eslint-disable react/prop-types */
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormLabel,
  CRow,
  CSpinner,
  CWidgetStatsF,
} from '@coreui/react-pro'
import DateTimeRangePicker from '@wojtekmaj/react-daterange-picker'
import React from 'react'
import {
  IoAlertCircleOutline,
  IoCashOutline,
  IoDesktopOutline,
  IoTicketOutline,
  IoWalletOutline,
  IoWarningOutline,
} from 'react-icons/io5'
import { formatCLP } from 'src/utils/helpers'
import { useStats } from '../hooks/useStats'

export const Stats = ({ id }) => {
  const { detail, loading, rangeDates, setRangeDates } = useStats(id)
  const {
    total_cash_normal_cashier = 0,
    total_amount_normal_cashier = 0,
    total_cash_difference = 0,
    total_amount_difference = 0,
    total_cash_web_expired = 0,
    total_amount_web_expired = 0,
    total_cash_free = 0,
    total_amount_free = 0,
    firstname = '',
    lastname = '',
    email = '',
  } = detail

  return (
    <CCard className="mb-3">
      <CCardHeader>
        <h4>
          {firstname} {lastname}
        </h4>
      </CCardHeader>
      <CCardBody>
        <div className="widget-dashboard">
          <CForm className="mb-4" id="form-filters">
            <CRow xs={{ gutterY: 2 }}>
              <CCol xs={12} sm={6} md={4} lg={3}>
                <CFormLabel htmlFor="date">Rango de fecha</CFormLabel>
                <DateTimeRangePicker
                  onChange={setRangeDates}
                  value={rangeDates}
                  format="dd-MM-yy"
                  rangeDivider="|"
                  maxDate={new Date()}
                  disabled={loading}
                />
              </CCol>
            </CRow>
          </CForm>
          <CRow>
            <CCol xs={12} md={6} lg={4} xl={3}>
              <CWidgetStatsF
                className="mb-3"
                color="success"
                icon={<IoCashOutline size="2em" />}
                padding={false}
                title="Total a Rendir"
                value={
                  loading ? (
                    <CSpinner size="sm" />
                  ) : (
                    formatCLP(total_cash_normal_cashier + total_cash_difference)
                  )
                }
              />
            </CCol>
            <CCol xs={12}>
              <hr />
            </CCol>
            <CCol xs={12} md={6} lg={4} xl={3}>
              <CWidgetStatsF
                className="mb-3"
                color="info"
                icon={<IoTicketOutline size="2em" />}
                padding={false}
                title="Ticket caducado web"
                value={loading ? <CSpinner size="sm" /> : total_amount_web_expired}
              />
            </CCol>
            <CCol xs={12} md={6} lg={4} xl={3}>
              <CWidgetStatsF
                className="mb-3"
                color="info"
                icon={<IoDesktopOutline size="2em" />}
                padding={false}
                title="Valor Caducado Web"
                value={loading ? <CSpinner size="sm" /> : formatCLP(total_cash_web_expired)}
              />
            </CCol>
            <CCol xs={12} md={6} lg={4} xl={3}>
              <CWidgetStatsF
                className="mb-3"
                color="warning"
                icon={<IoWalletOutline size="2em" />}
                padding={false}
                title="Valor Venta Caja"
                value={loading ? <CSpinner size="sm" /> : formatCLP(total_cash_normal_cashier)}
              />
            </CCol>
            <CCol xs={12} md={6} lg={4} xl={3}>
              <CWidgetStatsF
                className="mb-3"
                color="warning"
                icon={<IoTicketOutline size="2em" />}
                padding={false}
                title="Cantidad Ticket Caja"
                value={loading ? <CSpinner size="sm" /> : total_amount_normal_cashier}
              />
            </CCol>
            <CCol xs={12} md={6} lg={4} xl={3}>
              <CWidgetStatsF
                className="mb-3"
                color="primary"
                icon={<IoWarningOutline size="2em" />}
                padding={false}
                title="Venta Caja Recargos"
                value={loading ? <CSpinner size="sm" /> : formatCLP(total_cash_difference)}
              />
            </CCol>
            <CCol xs={12} md={6} lg={4} xl={3}>
              <CWidgetStatsF
                className="mb-3"
                color="primary"
                icon={<IoTicketOutline size="2em" />}
                padding={false}
                title="Cantidad Ticket Recargo"
                value={loading ? <CSpinner size="sm" /> : total_amount_difference}
              />
            </CCol>
            <CCol xs={12} md={6} lg={4} xl={3}>
              <CWidgetStatsF
                className="mb-3"
                color="secondary"
                icon={<IoAlertCircleOutline size="2em" />}
                padding={false}
                title="Total Free"
                value={loading ? <CSpinner size="sm" /> : formatCLP(total_cash_free)}
              />
            </CCol>
            <CCol xs={12} md={6} lg={4} xl={3}>
              <CWidgetStatsF
                className="mb-3"
                color="secondary"
                icon={<IoTicketOutline size="2em" />}
                padding={false}
                title="Cantidad Ticket Free"
                value={loading ? <CSpinner size="sm" /> : total_amount_free}
              />
            </CCol>
          </CRow>
        </div>
      </CCardBody>
    </CCard>
  )
}
