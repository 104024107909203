/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react'
import { phone } from '../helpers/phone'

export const useHandleForm = (setForm) => {
  const handleChangeSelector = useCallback((fieldName, value) => {
    setForm((prevState) => ({ ...prevState, [fieldName]: value }))
  }, [])

  const handleCheckboxSelector = (event) => {
    const { target } = event
    const { name, checked } = target
    if (event) {
      setForm((prevState) => ({
        ...prevState,
        [name]: checked,
      }))
    }
  }

  const handleChangeInput = useCallback((event) => {
    const { target } = event
    const { name, value, type } = target

    if (event) {
      setForm((prevState) => ({
        ...prevState,
        [name]: type === 'number' ? Number(value) : value,
      }))
    }
  }, [])

  const handleChangePhone = useCallback((event) => {
    const { target } = event
    const { name, value } = target

    if (event) {
      setForm((prevState) => ({
        ...prevState,
        [name]: phone.format(value),
      }))
    }
  }, [])

  return {
    handleChangeInput,
    handleChangePhone,
    handleChangeSelector,
    handleCheckboxSelector,
  }
}
