/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import {
  CButton,
  CCol,
  CForm,
  CFormCheck,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CFormSwitch,
  CRow,
} from '@coreui/react-pro'
import DatePicker from 'react-date-picker'
import { useEffect } from 'react'
import { formatDateISO } from 'src/utils/helpers'

const Form = ({
  formState = {},
  handleSubmit,
  handleChangeInput,
  handleCheckboxSelector,
  handleCheckboxGroup,
  setDays,
  validated,
}) => {
  const { date_from, date_to } = formState

  const [fromDate, setFromDate] = useState(new Date(date_from))
  const [toDate, setToDate] = useState(new Date(date_to))

  useEffect(() => {
    setDays({
      date_from: formatDateISO(fromDate),
      date_to: formatDateISO(toDate),
    })
  }, [fromDate, setDays, toDate])

  return (
    <CForm onSubmit={handleSubmit} validated={validated}>
      <CRow className="mb-4">
        <CCol xs={12} className="mb-3">
          <h4>Fechas</h4>
        </CCol>
        <CCol xs={12} sm={6}>
          <div className="mb-4">
            <CFormLabel htmlFor="date">Inicio</CFormLabel>
            <DatePicker
              value={fromDate}
              onChange={(value) => {
                setFromDate(value)
              }}
              minDate={fromDate}
              required
            />
            <CFormFeedback invalid>Campo requerido</CFormFeedback>
          </div>
        </CCol>
        <CCol xs={12} sm={6}>
          <div className="mb-4">
            <CFormLabel htmlFor="date">Termino</CFormLabel>
            <DatePicker
              value={toDate}
              onChange={(value) => {
                setToDate(value)
              }}
              minDate={fromDate}
              required
            />
            <CFormFeedback invalid>Campo requerido</CFormFeedback>
          </div>
        </CCol>
      </CRow>
      <CRow className="mb-4">
        <CCol xs={12} className="mb-3">
          <h4>Precios</h4>
        </CCol>
        <CCol xs={12} sm={6}>
          <div className="mb-4">
            <CFormLabel htmlFor="price">Precio internet</CFormLabel>
            <CFormInput
              type="number"
              id="price_web"
              name="price_web"
              onChange={handleChangeInput}
              defaultValue={formState.price_web}
              min={0}
              required
            />
            <CFormFeedback invalid>Campo requerido</CFormFeedback>
          </div>
        </CCol>
        <CCol xs={12} sm={6}>
          <div className="mb-4">
            <CFormLabel htmlFor="price">Precio tienda</CFormLabel>
            <CFormInput
              type="number"
              id="price_day"
              name="price_day"
              onChange={handleChangeInput}
              defaultValue={formState.price_day}
              min={0}
              required
            />
            <CFormFeedback invalid>Campo requerido</CFormFeedback>
          </div>
        </CCol>
        <CCol xs={12} sm={6}>
          <div className="mb-4">
            <CFormLabel htmlFor="price">Precio camping</CFormLabel>
            <CFormInput
              type="number"
              id="price_night"
              name="price_night"
              onChange={handleChangeInput}
              defaultValue={formState.price_night}
              min={0}
              required
            />
            <CFormFeedback invalid>Campo requerido</CFormFeedback>
          </div>
        </CCol>
        <CCol xs={12} sm={6}>
          <div className="mb-4">
            <CFormLabel htmlFor="price_school">Precio colegio</CFormLabel>
            <CFormInput
              type="number"
              id="price_school"
              name="price_school"
              min={0}
              onChange={handleChangeInput}
              required
            />
          </div>
        </CCol>
      </CRow>
      <CRow className="mb-5">
        <CCol xs={12}>
          <h5 className="mb-3">Bloquear Aplicación</h5>
          <div className="mb-5">
            <CFormCheck
              onChange={handleCheckboxGroup}
              inline
              id="1"
              value="1"
              label="Caja"
              name="blocked_application"
            />
            <CFormCheck
              onChange={handleCheckboxGroup}
              inline
              id="2"
              value="2"
              label="Website"
              name="blocked_application"
            />
            <CFormCheck
              onChange={handleCheckboxGroup}
              inline
              id="3"
              value="3"
              label="Totems"
              name="blocked_application"
            />
          </div>
        </CCol>
        <CCol xs={6}>
          <div className="mb-3">
            <CFormSwitch
              id="recharge"
              name="recharge"
              label="Agregar recargo"
              onChange={handleCheckboxSelector}
            />
          </div>
          {/* {recharge && (
            <>
              <div className="mb-3">
                <CFormLabel htmlFor="cost">Valor recargo</CFormLabel>
                <CFormInput
                  type="number"
                  id="cost_recharge"
                  name="cost_recharge"
                  onChange={handleChangeInput}
                  defaultValue={cost_recharge}
                  required
                />
              </div>
            </>
          )} */}
        </CCol>
        <CCol xs={12}>
          <div
            style={{
              display: 'flex',
              gap: 20,
              alignItems: 'flex-end',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <CButton type="submit" color="primary">
              {!!formState ? 'Guardar' : 'Crear'}
            </CButton>
          </div>
        </CCol>
      </CRow>
    </CForm>
  )
}

export default Form
