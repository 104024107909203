import React from 'react'
import PropTypes from 'prop-types'
import { CProgressBar } from '@coreui/react-pro'

export const ProgressGroup = ({ value, title, percent, color }) => {
  return (
    <div className="progress-group">
      <div className="progress-group-header">
        <span>{title}</span>
        <span className="ms-auto fw-semibold">
          {value} <span className="text-medium-emphasis small">({percent}%)</span>
        </span>
      </div>
      <div className="progress-group-bars">
        <div className="progress progress-thin" style={{ display: 'flex' }}>
          <CProgressBar color={color} value={percent} />
        </div>
      </div>
    </div>
  )
}

ProgressGroup.propTypes = {
  value: PropTypes.number,
  percent: PropTypes.number,
  title: PropTypes.string,
  color: PropTypes.string,
}
