import isEmpty from 'lodash/isEmpty'

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  TOKEN_CHECK,
  TOKEN_ERROR,
  RECOVERY_REQUEST,
  RECOVERY_SUCCESS,
  RECOVERY_ERROR,
  RESET_REQUEST,
  RESET_SUCCESS,
  RESET_ERROR,
} from 'src/types/auth'

import { login, recovery, resetCreds } from 'src/request/user'
import { getAllRoles } from './roles'

const requestLogout = () => ({
  type: LOGOUT_REQUEST,
  isFetching: true,
  isAuthenticated: true,
})

const receiveLogout = (payload) => ({
  type: LOGOUT_SUCCESS,
  isFetching: false,
  isAuthenticated: false,
  payload,
})

// Login

const requestLogin = (creds) => ({
  type: LOGIN_REQUEST,
  creds,
})

const receiveLogin = (user) => ({
  type: LOGIN_SUCCESS,
  payload: user,
})

const loginError = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
})

// Token
const receiveCheckUser = (data) => ({
  type: TOKEN_CHECK,
  payload: data,
})

const errorCheckUser = (error) => ({
  type: TOKEN_ERROR,
  payload: error,
})

// Recovery
const requestRecovery = (creds) => ({
  type: RECOVERY_REQUEST,
  creds,
})

const receiveRecovery = (creds) => ({
  type: RECOVERY_SUCCESS,
  creds,
})

const errorRecovery = (error) => ({
  type: RECOVERY_ERROR,
  payload: error,
})

// Reset
const requestReset = (email) => ({
  type: RESET_REQUEST,
  email,
})

const receiveReset = (payload) => ({
  type: RESET_SUCCESS,
  payload,
})

const errorReset = (error) => ({
  type: RESET_ERROR,
  payload: error,
})

const loginUser = (creds) => {
  const { email, password } = creds
  return (dispatch) => {
    dispatch(requestLogin(creds))
    login({
      email: email,
      password: password,
    })
      .then(({ data = {} }) => {
        const { error = '' } = data
        if (error) {
          const [err] = error
          dispatch(loginError(err))
          return Promise.reject(err)
        }
        dispatch(getAllRoles())
        window.location.href = '/dashboard'
        return dispatch(receiveLogin(data))
      })
      .catch((response) => {
        return dispatch(loginError(response.toJSON()))
      })
  }
}

const checkUser = (session = {}) => {
  const { token = '' } = session

  return (dispatch) => {
    if (isEmpty(token)) {
      dispatch(errorCheckUser())
      return
    }
    dispatch(getAllRoles())
    dispatch(receiveCheckUser(session))
  }
}

const recoveryUser = (creds) => {
  const { email = '' } = creds
  return (dispatch) => {
    dispatch(requestRecovery(creds))
    recovery({
      email,
    })
      .then(({ data = {} }) => {
        dispatch(receiveRecovery(data))
      })
      .catch(({ response = {} }) => {
        const { data = {} } = response
        return dispatch(errorRecovery(data))
      })
  }
}

const resetUser = (creds) => {
  const { password = '', token = '' } = creds
  return (dispatch) => {
    dispatch(requestReset(creds))
    resetCreds({
      password,
      token,
    })
      .then(({ data = {} }) => {
        dispatch(receiveReset(data))
      })
      .catch(({ response = {} }) => {
        const { data = {} } = response
        return dispatch(errorReset(data))
      })
  }
}

const logoutUser =
  (payload = {}) =>
  (dispatch) => {
    dispatch(requestLogout(payload))
    dispatch(receiveLogout(payload))
  }

export { loginUser, logoutUser, checkUser, recoveryUser, resetUser }
