import React, { useState } from 'react';
import PropTypes from 'prop-types'
import { cilFilterX } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CButton, CCol, CFormInput, CFormLabel, CRow,CFormSelect } from '@coreui/react-pro'

const FilterTransactionId = ({
  handleSendInput,
  handleChangeFilter,
  handleClearFilter,
  inputValue,
  handleFilterBy,
}) => {
  const [selectedFilterBy, setSelectedFilterBy] = useState('');

    const getPlaceholder = () => {
      
      switch (selectedFilterBy) {
        case 'name':
          return 'Ingrese nombre';
        case 'mail':
          return 'Ingrese email';
        case 'code':
          return 'Ingrese código';
        case 'dni':
            return 'Ingrese RUT cliente';
        default:
          return 'Ingrese valor';
      }
    };

  return (
    <div className="position-relative ">
      <form onSubmit={handleSendInput}>
        <CRow className="mb-3">
  
          <CFormLabel htmlFor="filter-transaction-id" className="">
            Filtrar por 
          </CFormLabel>


          <CCol xs={2}>
          <CFormSelect aria-label="Tipo filter" 
             id="filterBy"
             name="filterBy"
             value={selectedFilterBy}
             onChange={(e) => {
              setSelectedFilterBy(e.target.value);
              handleFilterBy(e)
             }}>
                  <option value="cod">Código</option>
                  <option value="name">Nombre</option>
                  <option value="mail">Email</option>
                  <option value="dni">RUT</option>

            </CFormSelect>

            </CCol>
          <CCol xs={3}>
            <CFormInput
              type="text"
              id="filter-transaction-id"
               placeholder={getPlaceholder()}
              value={inputValue}
              onChange={(e) => handleChangeFilter(e)}
            />
          </CCol>
          <CCol xs={2} className={'filter-buttons'}>
            <CButton type="submit" color="info" variant="outline">
              Buscar
            </CButton>
            <CButton color="info" variant="outline" onClick={() => handleClearFilter()}>
              <CIcon icon={cilFilterX} size="lg" />
            </CButton>
          </CCol>
        </CRow>
      </form>
    </div>
  )
}

FilterTransactionId.propTypes = {
  handleSendInput: PropTypes.func.isRequired,
  handleChangeFilter: PropTypes.func.isRequired,
  handleClearFilter: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  handleFilterBy: PropTypes.func.isRequired,
}

export default FilterTransactionId
