export const LIMIT_TO_SHOW = 10

export const ROLES = [
  {
    value: 1,
    text: 'Admin',
  },
  {
    value: 5,
    text: 'Api',
  },
  {
    value: 2,
    text: 'Cajero/a',
  },
  {
    value: 4,
    text: 'Cliente',
  },
  {
    value: 3,
    text: 'Revendedor/a',
  },
]

export const TABLE_HEAD = [
  {
    key: 'firstname',
    label: 'Nombre',
    filter: false,
  },
  {
    key: 'lastname',
    label: 'Apellido',
    filter: false,
  },
  {
    key: 'email',
    label: 'Email',
    filter: false,
  },
  {
    key: 'role',
    label: 'Rol',
    filter: false,
  },
  {
    key: 'edit',
    label: 'Acciones',
    filter: false,
  },
]
