import { updatePaymentMethod } from 'src/request/paymentMethod'
import {
  PUT_UPDATE_PM_REQUEST,
  PUT_UPDATE_PM_SUCCESS,
  PUT_UPDATE_PM_FAILURE,
} from 'src/types/payment-method'

const getUpdatePMRequest = (data) => ({
  type: PUT_UPDATE_PM_REQUEST,
  payload: data,
})

const getUpdatePMSuccess = (data) => ({
  type: PUT_UPDATE_PM_SUCCESS,
  payload: data,
})

const getUpdatePMFailured = (data) => ({
  type: PUT_UPDATE_PM_FAILURE,
  payload: data,
})

const updatePaymentMethodAction =
  (id = 0, pm = {}) =>
  (dispatch) => {
    console.log("updatePaymentMethodAction {} - {}",id, pm)
    dispatch(getUpdatePMRequest(pm))
    updatePaymentMethod(id, pm)
      .then(() =>{
        console.log("updatePaymentMethodAction ok")
        window.location = '/medios-de-pago'
      })
      .catch(({ response = {} }) => {
        const { data = {} } = response
        return dispatch(getUpdatePMFailured(data))
      })
  }

export { updatePaymentMethodAction }
