import { combineReducers } from 'redux'

import authReducer from './authReducer'
import eventReducer from './eventReducer'
import menuReducer from './menuReducer'
import ordersReducer from './ordersReducer'
import roleReducer from './roleReducer'
import userReducer from './userReducer'

const rootReducer = combineReducers({
  auth: authReducer,
  menu: menuReducer,
  orders: ordersReducer,
  event: eventReducer,
  user: userReducer,
  role: roleReducer,
})

export default rootReducer
