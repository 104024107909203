import { api } from 'src/utils/helpers/api'
import axios from 'axios';
import Cookies from 'js-cookie'

export const getPaymentMethodById = (pmId) => {
  return api({
    method: 'GET',
    url: `/payment/method/${pmId}`,
  })
}


export const updatePaymentMethod = (pmId, payload) => {
  const token = Cookies.get('token');
  const config = {};

  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  const baseURL = process.env.REACT_APP_API_URL;
  const completeUrl = `${baseURL}/payment/method/${pmId}`;

  return axios.put(completeUrl, payload, config);
};

