import React, { useState, useEffect } from 'react'
import { AppContent, AppAside, AppSidebar, AppFooter, AppHeader } from '../components/index'

const DefaultLayout = () => {
  const [dark, setDark] = useState('true')

  useEffect(() => {
    const _body = document.body
    dark ? _body.classList.add('dark-theme') : _body.classList.remove('dark-theme')
  }, [dark])
  return (
    <div className="root-container">
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
        <AppHeader setDark={setDark} dark={dark} />
        <div className="body flex-grow-1">
          <AppContent />
        </div>
        <AppFooter />
      </div>
      <AppAside />
    </div>
  )
}

export default DefaultLayout
