import { useEffect } from 'react'
import { useState } from 'react'
import { getOrdersQuotes } from 'src/request/orders'
import { LIMIT_TO_SHOW, TYPE_ID } from './constants'

const initialState = {
  data: [],
  loading: true,
  error: '',
}

export const useQuotes = () => {
  const [quotes, setQuotes] = useState(initialState.data)
  const [loading, setLoading] = useState(initialState.loading)
  const [error, setError] = useState(false)
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [params, setParams] = useState({
    limit: LIMIT_TO_SHOW,
    offset: 0,
    order_type_id: TYPE_ID,
  })

  const fetchQuotes = async () => {
    setLoading(true)
    try {
      await getOrdersQuotes(params).then(({ data }) => {
        const { orders, total } = data
        setQuotes(orders || initialState.data)
        setError(!orders)
        setTotal(total || 0)
      })
    } catch (e) {
      setError(e)
      setQuotes(initialState.data)
      setTotal(0)
    }
    setLoading(false)
  }

  const handleOffset = (offset) => (offset >= 0 ? offset : 0)

  const handleChangePage = (value = 1) => {
    const pageToQuery = value ? +value - 1 : 0
    setPage(pageToQuery)
    setParams((prev) => ({
      ...prev,
      offset: handleOffset(Math.ceil(pageToQuery * LIMIT_TO_SHOW)),
    }))
  }

  useEffect(() => {
    fetchQuotes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  useEffect(() => {
    if (total) setTotalPages(Math.ceil(total / LIMIT_TO_SHOW))
  }, [total])

  return {
    quotes,
    loading,
    error,
    page,
    totalPages,
    handleChangePage,
  }
}
