import React from 'react'
import PropTypes from 'prop-types'

export const WidgetValue = ({ value, title, color }) => {
  return (
    <div
      className={`border-start border-start-4 py-1 px-3 ${color ? `border-start-${color}` : ''}`}
    >
      <div className="text-medium-emphasis small">{title}</div>
      <div className="fs-5 fw-semibold">{value}</div>
    </div>
  )
}

WidgetValue.propTypes = {
  value: PropTypes.number,
  title: PropTypes.string,
  color: PropTypes.string,
}
