// eslint-disable-next-line react-hooks/exhaustive-deps
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CCard, CCardBody, CCardHeader, CSpinner } from '@coreui/react-pro';
import { useParams } from 'react-router-dom';
import { useHandleForm } from 'src/utils/hooks/useHandleForm';
import Form from '../Form';
import { getPaymentMethodById } from 'src/request/paymentMethod'; 
import { toast } from 'react-toastify'
import '../styles.css'
import { useDispatch, useSelector } from 'react-redux'
import { updatePaymentMethodAction } from 'src/actions/payment-method'


toast.configure()

// eslint-disable-next-line react/prop-types
const Edit = () => {
  const [loading, setLoading] = useState(true);

  const [paymentMethod, setPaymentMethod] = useState({});

  const { id } = useParams();
  const [formState, setFormState] = useState({
    name: '',
    displayName: '',
    discount: 0,
    enabled: false,
    created: '',
    updated: '',
  });

  const history = useHistory();

  useEffect(() => {

    const fetchPaymentMethod = async () => {
      try {
        console.log('fetchPaymentMethod id ',id)
        const response = await getPaymentMethodById(id);

        setPaymentMethod(response.data);
        console.log('fetchPaymentMethod ',response.data)
        setFormState(response.data); 
        setLoading(false);
      } catch (error) {
        console.error('Error al obtener el medio de pago:', error);
      }
    };

    fetchPaymentMethod();
  }, [id]);

  const { handleChangeInput } = useHandleForm(setFormState);
  const dispatch = useDispatch()
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      const payload ={
        name:formState.name,
        enabled:formState.enabled,
        discount:formState.discount,
        display_name:formState.display_name,
      }
      dispatch(updatePaymentMethodAction(id, payload))
      history.push('/medios-de-pago');
    } catch (error) {
      console.error('Error al actualizar el medio de pago:', error);
    }
  };

  return loading ? (
    <CSpinner />
  ) : (
    <>
      <CCard>
        <CCardHeader>
          <h1 className="h3">Actualizar medio de pago</h1>
        </CCardHeader>
        <CCardBody>
          <Form
            setFormState={setFormState}
            formState={formState}
            handleChangeInput={handleChangeInput}
            handleSubmit={handleSubmit} // Agrega el controlador de envío al formulario
          />
        </CCardBody>
      </CCard>
    </>
  );
};

export default Edit;
