import React from 'react';
import PropTypes from 'prop-types';

import {
  CButton,
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CFormSwitch,
  CRow,
} from '@coreui/react-pro';

const Form = ({
  formState = {},
  handleSubmit,
  handleChangeInput,
  setFormState,
}) => {
  const { name, display_name, discount, enabled } = formState;
  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === 'checkbox' ? checked : value;
  console.log("newValue",newValue);
    setFormState(prevState => ({
      ...prevState,
      [name]: name === 'enabled' ? checked : newValue
    }));
  };

  return (
    <CForm onSubmit={handleSubmit}>
      <CRow className="mb-4">
        <CCol xs={12} className="mb-3">
          <h4>Datos del Medio de Pago</h4>
        </CCol>
        <CCol xs={12} sm={6}>
          <div className="mb-4">
            <CFormLabel htmlFor="name">Nombre</CFormLabel>
            <CFormInput
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={handleChangeInput}
              required
            />
            <CFormFeedback invalid>Campo requerido</CFormFeedback>
          </div>
        </CCol>
        <CCol xs={12} sm={6}>
          <div className="mb-4">
            <CFormLabel htmlFor="displayName">Nombre Visible</CFormLabel>
            <CFormInput
              type="text"
              id="display_name"
              name="display_name"
              value={display_name}
              onChange={handleChangeInput}
              required
            />
            <CFormFeedback invalid>Campo requerido</CFormFeedback>
          </div>
        </CCol>
        <CCol xs={12} sm={6}>
          <div className="mb-4">
            <CFormLabel htmlFor="discount">Descuento</CFormLabel>
            <CFormInput
              type="number"
              id="discount"
              name="discount"
              value={discount}
              onChange={handleChangeInput}
              min={0}
              required
            />
            <CFormFeedback invalid>Campo requerido</CFormFeedback>
          </div>
        </CCol>
        <CCol xs={12} sm={6}>
          <div className="mb-4">
            <CFormLabel htmlFor="enabled">Habilitado</CFormLabel>
            <CFormSwitch
              id="enabled"
              name="enabled"
              color="primary"
              checked={enabled}
               onChange={handleInputChange} 
            />
          </div>
        </CCol>
      </CRow>
      <CRow className="mb-5">
        <CCol xs={12}>
          <div
            style={{
              display: 'flex',
              gap: 20,
              alignItems: 'flex-end',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <CButton type="submit" color="primary">
              Actualizar
            </CButton>
          </div>
        </CCol>
      </CRow>
    </CForm>
  );
};

Form.propTypes = {
  setFormState: PropTypes.object.isRequired, 
  formState: PropTypes.object.isRequired, 
  handleSubmit: PropTypes.func.isRequired,
  handleChangeInput: PropTypes.func.isRequired,
};

export default Form;
