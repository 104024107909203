import React from 'react'
import PropTypes from 'prop-types'
import { cilFilterX } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CButton, CCol, CFormInput, CFormLabel, CRow } from '@coreui/react-pro'

const Filter = ({
  handleSendInput,
  handleChangeFilter,
  handleClearFilter,
  inputValue,
  placeholder = '',
  button,
}) => {
  return (
    <div className="position-relative ">
      <form onSubmit={handleSendInput}>
        <CRow className="mb-3">
          <CCol xs={12} md={2} lg={2} xl={1}>
            <CFormLabel htmlFor="filter-email-user" className="col-form-label">
              Búsqueda
            </CFormLabel>
          </CCol>

          <CCol xs={12} sm={6} md={6} lg={4} xl={3}>
            <CFormInput
              type="text"
              id="filter-email-user"
              placeholder={placeholder}
              value={inputValue}
              onChange={(e) => handleChangeFilter(e)}
            />
          </CCol>
          <CCol xs={12} sm={6} md={4} lg={3} xl={2} className={'filter-buttons'}>
            <CButton type="submit" color="info" variant="outline">
              Filtrar
            </CButton>
            <CButton color="info" variant="outline" onClick={() => handleClearFilter()}>
              <CIcon icon={cilFilterX} size="lg" />
            </CButton>
          </CCol>
          {button && button}
        </CRow>
      </form>
    </div>
  )
}

Filter.propTypes = {
  handleSendInput: PropTypes.func.isRequired,
  handleChangeFilter: PropTypes.func.isRequired,
  handleClearFilter: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  button: PropTypes.node,
}

export default Filter
