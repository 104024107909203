const format = (value = '') => {
  let numbers = value.match(/\d+/g)
  if (!numbers) return ''
  return numbers
}

const validate = (value = '') => {
  const re = /^(\+?56)?(2)[0-9]\d{6}$/gi
  return re.test(String(value).toLowerCase())
}

export const phone = { validate, format }
