import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CCloseButton, CLink, CSidebar, CSidebarHeader } from '@coreui/react-pro'
import { IoPowerSharp } from 'react-icons/io5'
import { SET } from 'src/types/menu'

const AppAside = () => {
  const dispatch = useDispatch()
  const asideShow = useSelector((state) => state.asideShow)

  return (
    <CSidebar
      colorScheme="light"
      selfHiding="xxl"
      size="lg"
      overlaid
      placement="end"
      visible={asideShow}
      onHide={() => {
        dispatch({ type: SET, asideShow: false })
      }}
      className="sidebar"
    >
      <CSidebarHeader className="bg-transparent">
        <CCloseButton
          className="float-end"
          onClick={() => dispatch({ type: SET, asideShow: false })}
        />
      </CSidebarHeader>
      <ul className="sidebar__menu">
        <li className="sidebar__menu__item">
          <CLink href="#" className="sidebar__menu__link">
            <IoPowerSharp className="sidebar__menu__icon" />
            Salir
          </CLink>
        </li>
      </ul>
    </CSidebar>
  )
}

export default React.memo(AppAside)
