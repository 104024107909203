import {
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  PUT_UPDATE_USER_REQUEST,
  POST_USER_ADMIN_REQUEST,
  PUT_UPDATE_USER_FAILURE,
  POST_USER_ADMIN_FAILURE,
  POST_USER_ADMIN_SUCCESS,
  PUT_UPDATE_USER_SUCCESS,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
} from 'src/types/user'

const initialState = {
  err: {},
  usersList: { users: [], total: 0, loading: false },
  selected: { loading: false },
  user: { isFetching: false, success: false, error: {} },
  isFetching: false,
}

const userReducer = (state = initialState, action = {}) => {
  const { type = '', payload = {} } = action

  switch (type) {
    case GET_USERS_REQUEST: {
      return {
        ...state,
        usersList: { loading: true },
      }
    }
    case GET_USERS_SUCCESS: {
      return {
        ...state,
        usersList: { loading: false, ...payload },
      }
    }
    case GET_USERS_FAILURE: {
      return {
        ...state,
        usersList: { loading: false, users: [], total: 0, error: false },
      }
    }
    case GET_USER_REQUEST: {
      const { user = {} } = state
      return {
        ...state,
        isFetching: true,
        user: {
          ...user,
          isFetching: true,
        },
      }
    }
    case GET_USER_SUCCESS: {
      return {
        ...state,
        user: { ...payload, isFetching: false },
        isFetching: false,
      }
    }

    case GET_USER_FAILURE: {
      const { user = {} } = state
      return {
        ...state,
        isFetching: false,
        user: {
          ...user,
          isFetching: false,
        },
      }
    }
    case PUT_UPDATE_USER_REQUEST:
    case POST_USER_ADMIN_REQUEST: {
      return {
        ...state,
        user: {
          ...payload,
          isFetching: true,
          success: false,
          error: {},
        },
      }
    }

    case PUT_UPDATE_USER_FAILURE:
    case POST_USER_ADMIN_FAILURE: {
      const { user = {} } = state
      const { phone = '', dni = '', ...rest } = user
      return {
        ...state,
        user: {
          ...rest,
          additional: { phone, dni },
          isFetching: false,
          success: false,
          error: payload,
        },
      }
    }

    case POST_USER_ADMIN_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        user: {
          ...payload,
          success: false,
          error: {},
        },
      }
    }
    case PUT_UPDATE_USER_SUCCESS: {
      const { user = {} } = state
      return {
        ...state,
        isFetching: false,
        user: {
          ...user,
          isFetching: false,
          success: true,
          error: {},
        },
      }
    }

    default: {
      return state
    }
  }
}

export default userReducer
