export const GET_ORDERS_REQUEST = 'GET_ORDERS_REQUEST'
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS'
export const GET_ORDERS_FAILURE = 'GET_ORDERS_FAILURE'

export const GET_ORDERS_QUOTES_REQUEST = 'GET_ORDERS_QUOTES_REQUEST'
export const GET_ORDERS_QUOTES_SUCCESS = 'GET_ORDERS_QUOTES_SUCCESS'
export const GET_ORDERS_QUOTES_FAILURE = 'GET_ORDERS_QUOTES_FAILURE'

export const GET_ORDER_PDF_REQUEST = 'GET_ORDERS_PDF_REQUEST'
export const GET_ORDER_PDF_SUCCESS = 'GET_ORDERS_PDF_SUCCESS'
export const GET_ORDER_PDF_FAILURE = 'GET_ORDERS_PDF_FAILURE'

export const PATCH_EXPIRE_ORDER_TICKET_REQUEST = 'PATCH_EXPIRE_ORDER_TICKET_REQUEST'
export const PATCH_EXPIRE_ORDER_TICKET_SUCCESS = 'PATCH_EXPIRE_ORDER_TICKET_SUCCESS'
export const PATCH_EXPIRE_ORDER_TICKET_FAILURE = 'PATCH_EXPIRE_ORDER_TICKET_FAILURE'

export const PUT_UPDATE_ORDER_REQUEST = 'PUT_UPDATE_ORDER_REQUEST'
export const PUT_UPDATE_ORDER_SUCCESS = 'PUT_UPDATE_ORDER_SUCCESS'
export const PUT_UPDATE_ORDER_FAILURE = 'PUT_UPDATE_ORDER_FAILURE'
