import React, { useEffect, useState } from 'react'
import {
  CRow,
  CCol,
  CForm,
  CFormLabel,
  CCard,
  CCardHeader,
  CCardBody,
  CButtonGroup,
  CButton,
  CCollapse,
  CSmartPagination,
} from '@coreui/react-pro'
import { useDispatch } from 'react-redux'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import SmartTable from 'src/components/SmartTable/SmartTable'
import { formatCLP, formatDate, formatDateISO } from 'src/utils/helpers'
import { listUsers } from 'src/actions/user'
import { getOrders } from 'src/request/orders'
import Filter from '../Usuarios/components/Filter'
import { COLUMNS, LIMIT_TO_SHOW } from './constants'
import { useParams } from 'react-router-dom'
import { Stats } from './components/stats'
import Detail from './listDetail'

const CierreDetail = () => {
  const dispatch = useDispatch()
  const [loadingHistory, setLoadingHistory] = useState(true)
  const { id: user_id } = useParams()

  const [total, setTotal] = useState(0)
  const [sales, setSales] = useState([])
  const [details, setDetails] = useState([])
  const [cajasDate, setCajasDate] = useState([new Date(), new Date()])
  const [inputValue, setInputValue] = useState('')
  const [page, setPage] = useState(0)
  const [filters, setFilters] = useState({})
  const MIN_PAGE = 1
  const showPage = page + MIN_PAGE
  const count = total ? Math.ceil(total / LIMIT_TO_SHOW) : 1

  const getBadge = (status) => {
    switch (status) {
      case true:
        return 'success'
      default:
        return ''
    }
  }

  const toggleDetails = (index) => {
    const position = details.indexOf(index)
    let newDetails = details.slice()
    if (position !== -1) {
      newDetails.splice(position, 1)
    } else {
      newDetails = [...details, index]
    }
    setDetails(newDetails)
  }

  const handleChangeFilter = (e) => {
    const { target } = e
    const { value = '' } = target
    setInputValue(value)

    if (value !== '') {
      setFilters({
        transaction_id: value,
      })
    }
  }

  const handleSendInput = (e) => {
    e.preventDefault()
    setFilters({
      transaction_id: String(inputValue),
    })
  }

  const handleClearFilter = () => {
    setInputValue('')
    setFilters({})
    document.getElementById('form-filters').reset()
  }

  const handleChangePage = (value = 1) => {
    const pageToQuery = value ? +value - 1 : 0
    setPage(pageToQuery)

    const offset = (value - 1) * LIMIT_TO_SHOW
    setFilters({
      ...filters,
      limitFrom: offset,
      limitTo: LIMIT_TO_SHOW,
    })
  }

  const getSales = (filters = {}, cajasDate) => {
    const [eventFrom, eventTo] = cajasDate
    const eventFromFmt = formatDateISO(eventFrom)
    const eventToFmt = formatDateISO(eventTo)
    setLoadingHistory(true)

    getOrders({
      timeout: true,
      ...filters,
      created_from: eventFromFmt,
      created_to: eventToFmt,
      application_id: 1,
      user_id,
    })
      .then(({ data }) => {
        const { orders = [], total = 0 } = data
        setTotal(total)
        setSales(orders)
        setLoadingHistory(false)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  useEffect(() => {
    getSales(filters, cajasDate)
  }, [filters, cajasDate])

  useEffect(() => {
    dispatch(listUsers({ roleIds: '1,2' }))
  }, [dispatch])

  return (
    <div className="section cierre-cajas">
      <Stats id={user_id} />
      <CCard className="mb-3">
        <CCardHeader>
          <h4>Histórico</h4>
        </CCardHeader>
        <CCardBody>
          <div className="widget-dashboard">
            <CForm className="mb-4" id="form-filters">
              <CRow xs={{ gutterY: 2 }} className="align-items-end">
                <CCol xs={12} sm={6} md={4} lg={3}>
                  <CFormLabel htmlFor="date">Rango de fecha</CFormLabel>
                  <DateRangePicker
                    onChange={(info) => {
                      setCajasDate(info || [new Date(), new Date()])
                    }}
                    value={cajasDate}
                    format="dd-MM-yy"
                    rangeDivider="|"
                    maxDate={new Date()}
                    className=""
                  />
                </CCol>
                {/* <CCol xs={12} sm={6} md={8} lg={9}>
                  <div className="d-flex gap-1 justify-content-end">
                    <CFormCheck
                      button={{ color: 'info', variant: 'outline' }}
                      autoComplete="off"
                      label="Web"
                      checked
                    />
                    <CFormCheck
                      button={{ color: 'warning', variant: 'outline' }}
                      autoComplete="off"
                      label="Caja"
                      checked
                    />
                    <CFormCheck
                      button={{ color: 'primary', variant: 'outline' }}
                      autoComplete="off"
                      label="Recargos"
                      checked
                    />
                    <CFormCheck
                      button={{ color: 'secondary', variant: 'outline' }}
                      autoComplete="off"
                      label="Free"
                      checked
                    />
                  </div>
                </CCol> */}
              </CRow>
            </CForm>
            <CCol xs={12} sm={6} md={4} lg={12}>
              <Filter
                handleChangeFilter={handleChangeFilter}
                handleSendInput={handleSendInput}
                handleClearFilter={handleClearFilter}
                inputValue={inputValue}
                placeholder="ingrese código"
              />
            </CCol>
            <CRow>
              <CCol>
                <SmartTable
                  responsive
                  items={sales}
                  columns={COLUMNS}
                  columnSorter
                  itemsPerPageSelect
                  itemsPerPage={LIMIT_TO_SHOW}
                  loading={loadingHistory}
                  sorterValue={{
                    key: 'date',
                  }}
                  tableProps={{
                    hover: true,
                  }}
                  itemsPerPageLabel="Filas por página"
                  noItemsLabel={'Sin Resultados'}
                  scopedColumns={{
                    id: (item) => (
                      <td
                        className="text-center"
                        style={{ display: details.includes(item.id) ? 'none' : '' }}
                      >
                        {item.id}
                      </td>
                    ),

                    code: (item) => (
                      <td
                        className="text-center"
                        style={{ display: details.includes(item.id) ? 'none' : '' }}
                      >
                        {item.transaction_id}
                      </td>
                    ),
                    user_name: (item) => {
                      return (
                        <td style={{ display: details.includes(item.id) ? 'none' : '' }}>
                          {`${item.user_name.toUpperCase()}`}
                        </td>
                      )
                    },

                    /*
                    client: (item) => (
                      <td style={{ display: details.includes(item.id) ? 'none' : '' }}>
                        {`${item.client.firstname.toUpperCase()} ${item.client.lastname.toUpperCase()}`}
                      </td>
                    ),
                    */
                    /*
                    cashier: (item) => {
                      const { cashier = {} } = item;
                      const { firstname = '', lastname = '' } = cashier;
                      return (
                        <td>
                          <p>{firstname} {lastname}</p>
                        </td>
                      );
                    },
                    */

                    quantity: (item) => (
                      <td
                        className="text-center"
                        style={{ display: details.includes(item.id) ? 'none' : '' }}
                      >
                        {item.quantity}
                      </td>
                    ),

                    total: (item) => (
                      <td
                        className="text-end"
                        style={{ display: details.includes(item.id) ? 'none' : '' }}
                      >
                        {/* {formatCLP(item?.price + item?.difference)} */}
                        {formatCLP(item?.price * item?.quantity)}
                      </td>
                    ),

                    date: (item) => (
                      <td
                        className="text-center"
                        style={{ display: details.includes(item.id) ? 'none' : '' }}
                      >
                        {formatDate(item.date_from)}
                      </td>
                    ),

                    /*
                    status: (item) => (
                      <td
                        className="text-center"
                        style={{ display: details.includes(item.id) ? 'none' : '' }}
                      >
                        <CBadge color={getBadge(item.status)}>{item.status}</CBadge>
                      </td>
                    ),
                    */

                    detail: (item) => (
                      <td
                        className="text-center"
                        style={{ display: details.includes(item.id) ? 'none' : '' }}
                      >
                        <CButtonGroup size="sm" role="group">
                          <CButton
                            color="secondary"
                            onClick={() => {
                              toggleDetails(item.id)
                            }}
                          >
                            Ver
                          </CButton>
                        </CButtonGroup>
                      </td>
                    ),

                    details: (item) => {
                      const { used = false, used_date: usedDate = '' } = item
                      return (
                        <CCollapse visible={details.includes(item.id)}>
                          {details.includes(item.id) && (
                            <Detail item={item} toggleDetails={toggleDetails} />
                          )}
                        </CCollapse>
                      )
                    },
                  }}
                  // onFilteredItemsChange={(value) => setFiltered(value)}
                  clickableRows
                />
                <CSmartPagination
                  activePage={showPage}
                  pages={count}
                  onActivePageChange={handleChangePage}
                />
              </CCol>
            </CRow>
          </div>
        </CCardBody>
      </CCard>
    </div>
  )
}

export default CierreDetail
