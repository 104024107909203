import { sygnet } from './sygnet'
import { logo } from './logo'
import { logoNegative } from './logo-negative'

import { cilApplicationsSettings, cilMenu, cilSpeedometer } from '@coreui/icons'

export const icons = Object.assign(
  {
    sygnet,
    logo,
    logoNegative,
  },
  {
    cilApplicationsSettings,
    cilMenu,
    cilSpeedometer,
  },
)
