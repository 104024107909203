/* eslint-disable react/prop-types */
import React from 'react'
import {
  CAlert,
  CButton,
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CRow,
  CSpinner,
} from '@coreui/react-pro'
import DatePicker from 'react-date-picker'
import { formatCLP } from 'src/utils/helpers'

export const Form = ({
  state,
  formState = {},
  validated = false,
  errors,
  loading,
  disableInput,
  dayValue,
  // validUser,
  pdf,
  cashier,
  handleDate,
  handleDni,
  handleUserDni,
  handleDiscount,
  handlePaymentMethod,
  handleQuantity,
  handlePay,
  handleChangeInput,
  handleSubmit,
  handlePrint,
  handleCancelMP,
  handleTryAgain,
  paymentMethod,
}) => {
  return (
    <CForm onSubmit={handleSubmit} validated={validated} className="needs-validation">
{/*       <CRow>
        <CCol xs={12}>
          <h5>Cliente</h5>
          <hr />
        </CCol>
        <CCol xs={12} sm={6} md={5} lg={4} xl={3}>
          <div className="mb-4">
            <CFormLabel htmlFor="dni">Rut</CFormLabel>
            <CFormInput
              type="text"
              id="dni"
              name="dni"
              onChange={(e) => {
                handleDni(e)
                handleChangeInput(e)
              }}
              onBlur={handleUserDni}
              disabled={disableInput}
              required={Boolean(errors.dni)}
              maxLength={13}
            />
            {errors.dni && (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                {errors.dni}
              </div>
            )}
          </div>
        </CCol>
        <CCol xs={12} sm={6} md={5} lg={4} xl={3}>
          <div className="mb-4">
            <CFormLabel htmlFor="firstname">Nombre</CFormLabel>
            <CFormInput
              type="text"
              id="firstname"
              name="firstname"
              value={formState.firstname}
              onChange={handleChangeInput}
              disabled={disableInput || loading.dni}
              required={Boolean(errors.firstname)}
            />
            {errors.firstname && (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                {errors.firstname}
              </div>
            )}
          </div>
        </CCol>
        <CCol xs={12} sm={6} md={5} lg={4} xl={3}>
          <div className="mb-4">
            <CFormLabel htmlFor="lastname">Apellido</CFormLabel>
            <CFormInput
              type="text"
              id="lastname"
              name="lastname"
              value={formState.lastname}
              onChange={handleChangeInput}
              disabled={disableInput || loading.dni}
              required={Boolean(errors.lastname)}
            />
            {errors.lastname && (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                {errors.lastname}
              </div>
            )}
          </div>
        </CCol>
        <CCol xs={12} sm={6} md={5} lg={4} xl={3}>
          <div className="mb-4">
            <CFormLabel htmlFor="email">Email</CFormLabel>
            <CFormInput
              type="text"
              id="email"
              name="email"
              value={formState.email}
              onChange={handleChangeInput}
              disabled={disableInput || loading.dni}
              required={Boolean(errors.email)}
            />
            {errors.email && (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                {errors.email}
              </div>
            )}
          </div>
        </CCol>
      </CRow> */}
      <CRow>
        <CCol xs={12}>
          <h5>Detalles de venta {loading.global && <CSpinner size="sm" />}</h5>
          <hr />
          {errors.ticket && <CAlert color="warning">{errors.ticket}</CAlert>}
        </CCol>
        <CCol xs={12} sm={6} md={5} lg={4} xl={3}>
          <div className="mb-4">
            <CFormLabel htmlFor="date">Fecha</CFormLabel>
            <DatePicker
              name="date"
              id="date"
              value={formState.date}
              onChange={(date) => handleDate(date)}
              disabled={disableInput}
              clearIcon={false}
              required
            />
            <CFormFeedback invalid>Ingresa un método de pago</CFormFeedback>
          </div>
        </CCol>
        <CCol xs={12} sm={6} md={5} lg={4} xl={3}>
          <div className="mb-4">
            <CFormLabel htmlFor="ticket-number">Valor por día</CFormLabel>
            <CFormInput
              type="text"
              id="priceByDay"
              label="priceByDay"
              text="priceByDay"
              value={formatCLP(dayValue)}
              disabled
            />
          </div>
        </CCol>
        <CCol xs={12} sm={6} md={5} lg={4} xl={3}>
          <div className="mb-4">
            <CFormLabel htmlFor="cashier">Cajero(a)</CFormLabel>
            <CFormInput type="text" id="cashier" defaultValue={formState.cashier} disabled={true} />
          </div>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs={12} sm={6} md={5} lg={4} xl={3}>
          <div className="mb-5">
            <CFormLabel htmlFor="quantity">Cantidad</CFormLabel>
            <CFormInput
              type="number"
              id="quantity"
              value={formState.quantity}
              onChange={handleQuantity}
              disabled={disableInput}
              min={1}
            />
          </div>
        </CCol>
        <CCol xs={12} sm={6} md={5} lg={4} xl={3}>
          <div className="mb-4">
            <CFormLabel htmlFor="value">Total</CFormLabel>
            <CFormInput
              type="text"
              id="value"
              value={formatCLP(dayValue * formState.quantity)}
              disabled
            />
          </div>
        </CCol>
        <CCol xs={12} sm={6} md={5} lg={4} xl={3}>
          <div className="mb-4">
            <CFormLabel htmlFor="promo-type">Descuento</CFormLabel>
            <CFormSelect
              aria-label="Descuento"
              id="promo-type"
              name="promo-type"
              value={formState.discount}
              disabled={disableInput}
              onChange={handleDiscount}
            >
              <option value="">Ninguno</option>
              {/* <option value="2">Especial</option> */}
              <option value="free">Gratis</option>
              <option value="school">Colegio</option>
            </CFormSelect>
          </div>
        </CCol>
        <CCol xs={12} sm={6} md={5} lg={4} xl={3}>
          <div className="mb-4">
            <CFormLabel htmlFor="payment_method">Método de pago</CFormLabel>
            <CFormSelect
              aria-label="Descuento"
              id="payment_method"
              name="payment_method"
              onChange={handlePaymentMethod}
              disabled={disableInput || formState.discount === 'free'}
              required
              defaultValue={formState.discount === 'free' ? '3' : ''}
            >
              {formState.discount !== 'free' ? (
                <>
                  <option value="2">Transbank</option>
                  <option value="3">Efectivo</option>
                  {cashier?.identify && <option value="1">Mercado Pago</option>}

                </>
              ) : (
                <option value="3">Ninguno</option>
              )}
            </CFormSelect>
            <CFormFeedback invalid>Ingresa un método de pago</CFormFeedback>
          </div>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs={12} className="mb-5">
          <CFormLabel htmlFor="comment">Comentario</CFormLabel>
          <CFormTextarea
            id="comment"
            name="comment"
            rows="3"
            disabled={disableInput}
            onChange={handleChangeInput}
            required={formState.discount}
          ></CFormTextarea>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs={12} style={{ textAlign: 'right' }}>
          <div className="mb-5">
            <CFormLabel htmlFor="quantity">Total a pagar</CFormLabel>
            {/* <CFormInput type="number" id="quantity" /> */}
            <h1>
              {formState.discount === 'free' ? '$0' : formatCLP(dayValue * formState.quantity)}
            </h1>
          </div>
        </CCol>
      </CRow>
      <CRow>
        {state === 'approved' && (
          <CCol xs={12} md={6} lg={4} className="align-self-end" style={{ marginLeft: 'auto' }}>
            <CAlert color="success" style={{ textAlign: 'center' }}>
              La venta se ha ingresado con éxito
            </CAlert>
          </CCol>
        )}
        {state === 'cancelled' && (
          <CCol xs={12} md={6} lg={4} className="align-self-end" style={{ marginLeft: 'auto' }}>
            <CAlert color="danger" style={{ textAlign: 'center' }}>
              La venta ha sido cancelada, prueba reintentar
            </CAlert>
          </CCol>
        )}
        {state === 'error' && (
          <CCol xs={12} md={6} lg={4} className="align-self-end" style={{ marginLeft: 'auto' }}>
            <CAlert color="danger" style={{ textAlign: 'center' }}>
              Ha ocurrido un error al ingresar esta venta, prueba reintentar
            </CAlert>
          </CCol>
        )}
      </CRow>
      <CRow className="justify-content-end">
        {/* <CCol xs={6} md={4} lg={3} xl={2} className="d-grid mb-3">
          {state !== 'approved' && (
            <CButton
              href="/ventas-caja"
              color={`${state === 'error' ? 'dark' : 'danger'}`}
              variant={`${state === 'error' ? null : 'outline'}`}
              size="lg"
            >
              {state === 'error' ? 'Reintentar' : 'Cancelar'}
            </CButton>
          )}
        </CCol> */}
        {state === 'printed' && (
          <CCol xs={6} md={4} lg={3} xl={2} className="d-grid mb-3">
            <CButton href="/ventas-caja" color="dark" size="lg">
              Hacer otro pago
            </CButton>
          </CCol>
        )}
        {(state === 'approved' || state === 'printed') && (
          <CCol xs={6} md={4} lg={3} xl={2} className="d-grid mb-3">
            <CButton
              color="primary"
              size="lg"
              style={{ color: 'white' }}
              disabled={loading.global}
              onClick={handlePrint}
            >
              Imprimir
            </CButton>
          </CCol>
        )}

        {paymentMethod === 1 && (
          <>
            {state === 'new' && (
              <>
                {loading.pay && (
                  <CCol xs={6} md={4} lg={3} xl={2} className="d-grid mb-3">
                    <CButton
                      color="dark"
                      size="lg"
                      style={{ color: 'white' }}
                      onClick={handleCancelMP}
                    >
                      Cancelar
                    </CButton>
                  </CCol>
                )}
                <CCol xs={6} md={4} lg={3} xl={2} className="d-grid mb-3">
                  <CButton
                    type="submit"
                    color="info"
                    size="lg"
                    style={{ color: 'white' }}
                    disabled={loading.global || errors.ticket}
                  >
                    Generar venta {loading.pay && <CSpinner size="sm" />}
                  </CButton>
                </CCol>
              </>
            )}
            {state === 'pending' && (
              <CCol xs={6} md={4} lg={3} xl={2} className="d-grid mb-3">
                <CButton
                  onClick={handlePay}
                  color="success"
                  size="lg"
                  style={{ color: 'white' }}
                  disabled={loading.pay}
                >
                  Pagar {loading.pay && <CSpinner size="sm" />}
                </CButton>
              </CCol>
            )}
          </>
        )}
        {paymentMethod !== 1 && (
          <>
            {state === 'new' && (
              <CCol xs={6} md={4} lg={3} xl={2} className="d-grid mb-3">
                <CButton
                  type="submit"
                  color="success"
                  size="lg"
                  style={{ color: 'white' }}
                  disabled={loading.global || errors.ticket}
                >
                  Pagar {loading.pay && <CSpinner size="sm" />}
                </CButton>
              </CCol>
            )}
          </>
        )}
        {(state === 'cancelled' || state === 'error') && (
          <CCol xs={6} md={4} lg={3} xl={2} className="d-grid mb-3">
            <CButton color="dark" size="lg" style={{ color: 'white' }} onClick={handleTryAgain}>
              Reintentar
            </CButton>
          </CCol>
        )}
      </CRow>
    </CForm>
  )
}
