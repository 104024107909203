import { api } from 'src/utils/helpers/api'

export const insertValue = (payload) =>
  api({
    method: 'POST',
    url: '/ticket',
    data: payload,
  })

export const addValue =
  (value = {}, setResponse) =>
  (dispatch) => {
    insertValue(value)
      .then(({ data = {} }) => {
        setResponse({ status: 'success', data: value })
        // window.location = '/cargar-valores'
      })
      .catch((error) => {
        setResponse({ status: 'error', data: error })
        console.log(error)
        return 'Error'
      })
  }
