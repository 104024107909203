// eslint-disable-next-line react-hooks/exhaustive-deps
import React, { useState, useEffect } from 'react';
//import { useHistory } from 'react-router-dom';
import {
  CCard,
  CCardHeader,
  CCardBody,
  CButton
} from '@coreui/react-pro';
import { Link } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.min.css'
import SmartTable from 'src/components/SmartTable/SmartTable'
import Cookies from 'js-cookie'

const PaymentMethods = () => {
  //const history = useHistory();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const fetchData = async () => {
      try {

        const token = Cookies.get('token');
        let bearerToken = 'Bearer ' +token;
        let urlAPI = process.env.REACT_APP_API_URL;
        let endpoint = urlAPI+'/payment/method'
      //  console.log('endpoint->',endpoint);
        const response = await fetch(endpoint, {
          headers: {
            'Authorization': bearerToken,
          }
        });
        if (!response.ok) {
          throw new Error('Error al obtener medios de pago');
        }
        const data = await response.json();

     //   console.log('response data ->',data);
        const formattedMethods = data.payment_methods.map(method => ({
          ...method,
          created: formatDate(method.created),
          updated: formatDate(method.updated)
        }));
        setPaymentMethods(formattedMethods);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };


  const TABLE_HEAD = [
    {
      key: 'name',
      label: 'Nombre',
      filter: false,
    },
    {
      key: 'display_name',
      label: 'Nombre Visible',
      filter: false,
    },
    {
      key: 'discount',
      label: 'Descuento',
      filter: false,
    },
    {
      key: 'enabled',
      label: 'Habilitado',
      filter: false,
    },
    {
      key: 'created',
      label: 'Fecha Creacion',
      filter: false,
    },
    {
      key: 'updated',
      label: 'Fecha Actualización',
      filter: false,
    },
    {
      key: 'edit',
      label: 'Acciones',
      filter: false,
    },
  ]
  

  return (
    <CCard className="table-list">
      <CCardHeader>
        <h1 className="h3">Medios de Pago</h1>
      </CCardHeader>
      <CCardBody>

        <SmartTable
          responsive="true"
          items={paymentMethods}
          columns={TABLE_HEAD}
          columnFilter
          filter={false}
          // itemsPerPageSelect
          // itemsPerPage={LIMIT_TO_SHOW}
          loading={loading}
          sorterValue={{
            key: 'id',
          }}
          tableProps={{
            hover: true,
          }}
          scopedColumns={{
            enabled: (item) => (
              <td>
                  {item.enabled ? 'Sí' : 'No'}
              </td>
            ),
            edit: (item) => (
              <td>
                <Link to={`/medios-de-pago/editar/${item.id}`}>
                  <CButton color="secondary">Editar</CButton>
                </Link>
              </td>
            ),
          }}
          clickableRows
        />
      </CCardBody>
    </CCard>
  );
};

export default PaymentMethods;
