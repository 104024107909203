import axios from 'axios'
import { useEffect, useState } from 'react'

export const useCashierMP = (id) => {
  const [cashier, setCashier] = useState({})

  const fetchCashier = async () => {
    try {
      const response = await axios.get(
        'https://parqueoasis.s3.us-west-2.amazonaws.com/configs/totem_config-dev.json',
        // 'https://parqueoasis.s3.us-west-2.amazonaws.com/configs/totem_config.json',
      )
      const { cashiers } = response.data
      const cashier = cashiers.find((x) => x.identify === `user_${id}`)
      setCashier(cashier)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchCashier()
    console.log({
      user_id: id,
      cashier_data: cashier,
    })
  }, [])

  return cashier
}
