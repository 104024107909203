export const CURRENT_DATE = new Date()
export const FULL_DATE = CURRENT_DATE.toLocaleDateString('es-CL', { month: 'long', day: 'numeric' })
export const MONTH_DATE = `${CURRENT_DATE.getFullYear()}-${CURRENT_DATE.getMonth() + 1}`
export const MONTH = CURRENT_DATE.toLocaleDateString('es-CL', { month: 'long' })
export const SHOW = {
  daily: true,
  month: true,
  season: false,
  restaurant: false,
}
export const FAKE_DATA = {
  current_day: 380000,
  current_web: 320200,
  current_cashier: 78000,
  current_tickets: 342,
  current_season: 30000000,
  current_month_data: [
    {
      day: '01',
      total: 2000100,
    },
    {
      day: '02',
      total: 303000,
    },
    {
      day: '03',
      total: 3800000,
    },
    {
      day: '04',
      total: 3500000,
    },
    {
      day: '05',
      total: 1000000,
    },
    {
      day: '06',
      total: 2000100,
    },
    {
      day: '07',
      total: 303000,
    },
    {
      day: '08',
      total: 3800000,
    },
    {
      day: '09',
      total: 3500000,
    },
    {
      day: '10',
      total: 1000000,
    },
    {
      day: '11',
      total: 2000100,
    },
    {
      day: '12',
      total: 303000,
    },
    {
      day: '13',
      total: 3800000,
    },
    {
      day: '14',
      total: 3500000,
    },
    {
      day: '15',
      total: 1000000,
    },
    {
      day: '16',
      total: 2000100,
    },
    {
      day: '17',
      total: 303000,
    },
    {
      day: '18',
      total: 3800000,
    },
    {
      day: '19',
      total: 3500000,
    },
    {
      day: '20',
      total: 1000000,
    },
  ],
  current_season_data: [
    {
      month: 'June',
      year: 2022,
      total: 20000,
    },
    {
      month: 'July',
      year: 2022,
      total: 10000,
    },
  ],
  lasts_seasons: [
    {
      month: 'December',
      year: 2021,
      total: 6000,
    },
    {
      month: 'January',
      year: 2021,
      total: 1117000,
    },
    {
      month: 'February',
      year: 2021,
      total: 1270000,
    },
    {
      month: 'March',
      year: 2021,
      total: 510000,
    },
    {
      month: 'September',
      year: 2021,
      total: 6020,
    },
    {
      month: 'October',
      year: 2021,
      total: 105000,
    },
    {
      month: 'November',
      year: 2021,
      total: 2020,
    },
  ],
}
