import { getRoles } from 'src/request/role'
import { GET_ROLES_REQUEST, GET_ROLES_SUCCESS, GET_ROLES_FAILURE } from 'src/types/role'

const getAllRoles = () => (dispatch) => {
  dispatch({
    type: GET_ROLES_REQUEST,
  })
  getRoles()
    .then(({ data = {} }) => {
      dispatch({
        type: GET_ROLES_SUCCESS,
        payload: data,
      })
    })
    .catch((err) => {
      const { response = {} } = err
      dispatch({
        type: GET_ROLES_FAILURE,
        payload: response,
      })
    })
}

export { getAllRoles }
