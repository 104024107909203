import Cookies from 'js-cookie'

import { LOGOUT_REQUEST, LOGOUT_SUCCESS } from 'src/types/auth'

const requestLogout = () => ({
  type: LOGOUT_REQUEST,
  isFetching: true,
  isAuthenticated: false,
})

const receiveLogout = () => ({
  type: LOGOUT_SUCCESS,
  isFetching: false,
  isAuthenticated: false,
})

export function logoutUser() {
  return (dispatch) => {
    Cookies.remove('token')
    Cookies.remove('user')
    dispatch(requestLogout())
    dispatch(receiveLogout())
  }
}
