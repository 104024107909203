import React, { useEffect, useState } from 'react'
import {
  CRow,
  CCol,
  CForm,
  CFormLabel,
  CFormSelect,
  CCard,
  CCardHeader,
  CCardBody,
  CWidgetStatsF,
  CButtonGroup,
  CButton,
  CCollapse,
  CSmartPagination,
  CSpinner,
} from '@coreui/react-pro'
import {
  IoChevronDownOutline,
  IoTicketOutline,
  IoDesktopOutline,
  IoWalletOutline,
} from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'

import DateRangePicker from '@wojtekmaj/react-daterange-picker'

import SmartTable from 'src/components/SmartTable/SmartTable'

import { formatCLP, formatDate, formatDateISO, getExactDate } from 'src/utils/helpers'
import { listUsers } from 'src/actions/user'
import { getCashierSummary, getOrders } from 'src/request/orders'
import Filter from '../Usuarios/components/Filter'
import { COLUMNS, LIMIT_TO_SHOW } from './constants'
import { Link } from 'react-router-dom'
import Detail from './listDetail'

const Cierre = () => {
  const dispatch = useDispatch()
  const [loadingHistory, setLoadingHistory] = useState(true)
  const users = useSelector((state) => state.user)
  const { usersList = {} } = users || {}
  const { users: usersData = [] } = usersList

  const [total, setTotal] = useState(0)
  const [sales, setSales] = useState([])
  const [details, setDetails] = useState([])
  const [cajasDate, setCajasDate] = useState([new Date(), new Date()])
  const [inputValue, setInputValue] = useState('')
  const [page, setPage] = useState(0)
  const [filters, setFilters] = useState({})

  const [loadingCashierSummary, setLoadingCashierSummary] = useState(true)
  const [cashiersSummary, setCashiersSummary] = useState([])
  const [datesCashierSummary, setDatesCashierSummary] = useState([new Date(), new Date()])

  const MIN_PAGE = 1
  const showPage = page + MIN_PAGE
  const count = total ? Math.ceil(total / LIMIT_TO_SHOW) : 1

  const getBadge = (status) => {
    switch (status) {
      case true:
        return 'success'
      default:
        return ''
    }
  }

  const toggleDetails = (index) => {
    const position = details.indexOf(index)
    let newDetails = details.slice()
    if (position !== -1) {
      newDetails.splice(position, 1)
    } else {
      newDetails = [...details, index]
    }
    setDetails(newDetails)
  }

  const handleChangeFilter = (e) => {
    const { target } = e
    const { value = '' } = target
    setInputValue(value)

    if (value !== '') {
      setFilters({
        transaction_id: value,
      })
    }
  }

  const handleSendInput = (e) => {
    e.preventDefault()
    setFilters({
      transaction_id: String(inputValue),
    })
  }

  const handleClearFilter = () => {
    setInputValue('')
    setFilters({})
    document.getElementById('form-filters').reset()
  }

  const handleChangePage = (value = 1) => {
    const pageToQuery = value ? +value - 1 : 0
    setPage(pageToQuery)

    const offset = (value - 1) * LIMIT_TO_SHOW
    setFilters((prev) => ({
      ...prev,
      limitFrom: offset,
      limitTo: LIMIT_TO_SHOW,
    }))
  }

  const setCashier = (e) => {
    e.preventDefault()
    setFilters((prev) => ({
      ...prev,
      user_id: e.target.value,
    }))
  }

  const getSales = async (filters = {}, cajasDate) => {
    const [eventFrom, eventTo] = cajasDate
    const eventFromFmt = formatDateISO(eventFrom)
    const eventToFmt = formatDateISO(eventTo)
    setLoadingHistory(true)

    await getOrders({
      timeout: true,
      ...filters,
      created_from: eventFromFmt,
      created_to: eventToFmt,
      application_id: 1,
    })
      .then(({ data }) => {
        const { orders = [], total = 0 } = data
        setTotal(total)
        setSales(orders)
      })
      .catch(function (error) {
        setSales([])
        setTotal(0)
      })

    console.log('SALES', sales)
    console.log('TOTAL', total)

    setLoadingHistory(false)
  }

  const getCashiersSummary = async (dates = {}) => {
    const [eventFrom, eventTo] = dates
    const eventFromFmt = formatDateISO(eventFrom)
    const eventToFmt = formatDateISO(eventTo)
    setLoadingCashierSummary(true)

    try {
      const { data } = await getCashierSummary({
        date_from: eventFromFmt,
        date_to: eventToFmt,
      })

      setCashiersSummary(data)
    } catch (error) {
      setCashiersSummary([])
    }
    setLoadingCashierSummary(false)
  }

  useEffect(() => {
    getSales(filters, cajasDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, cajasDate])

  useEffect(() => {
    dispatch(listUsers({ roleIds: '1,2' }))
  }, [dispatch])

  useEffect(() => {
    getCashiersSummary(datesCashierSummary)
  }, [datesCashierSummary])

  return (
    <div className="section cierre-cajas">
      <CCard className="mb-3">
        <CCardHeader>
          <h4>Resumen</h4>
        </CCardHeader>
        <CCardBody>
          <div className="widget-dashboard">
            <CForm className="mb-4" id="form-filters">
              <CRow xs={{ gutterY: 2 }}>
                <CCol xs={12} sm={6} md={4} lg={3}>
                  <CFormLabel htmlFor="date">Rango de fecha</CFormLabel>
                  <DateRangePicker
                    onChange={(info) => {
                      setDatesCashierSummary(info || [new Date(), new Date()])
                    }}
                    value={datesCashierSummary}
                    format="dd-MM-yy"
                    rangeDivider="|"
                    maxDate={new Date()}
                    className=""
                  />
                </CCol>
              </CRow>
            </CForm>
            <CRow className="row-gap">
              {loadingCashierSummary ? (
                <CCol>
                  <CSpinner />
                </CCol>
              ) : (
                cashiersSummary.map(
                  ({
                    id,
                    firstname = '',
                    lastname = '',

                    // Caja
                    total_amount_normal_cashier = 0,
                    total_cash_normal_cashier = 0,

                    // Web
                    total_amount_web_expired = 0,
                    total_cash_web_expired = 0,

                    // Diferencia
                    total_amount_difference = 0,
                    total_cash_difference = 0,

                    // Gratis
                    total_amount_free = 0,
                    total_cash_free = 0,
                  }) => (
                    <CCol xs={12} md={6} lg={4} xl={3} key={id}>
                      <CCard className="mb-3 card-fh" color="light">
                        <CCardBody>
                          <p>
                            {firstname} {lastname}
                          </p>
                          <hr />
                          <div className="mb-4">
                            <CWidgetStatsF
                              className="mb-3"
                              color="info"
                              icon={<IoTicketOutline size="2em" />}
                              padding={false}
                              title="Ticket caducado web"
                              value={total_amount_web_expired}
                            />
                          </div>
                          <div className="mb-4">
                            <CWidgetStatsF
                              className="mb-3"
                              color="info"
                              icon={<IoDesktopOutline size="2em" />}
                              padding={false}
                              title="Valor Caducado Web"
                              value={formatCLP(total_cash_web_expired)}
                            />
                          </div>
                          <div className="mb-4">
                            <CWidgetStatsF
                              className="mb-3"
                              color="warning"
                              icon={<IoTicketOutline size="2em" />}
                              padding={false}
                              title="Cantidad Ticket Caja"
                              value={total_amount_normal_cashier}
                            />
                          </div>
                          <div className="mb-4">
                            <CWidgetStatsF
                              className="mb-3"
                              color="warning"
                              icon={<IoWalletOutline size="2em" />}
                              padding={false}
                              title="Valor Venta Caja"
                              value={formatCLP(total_cash_normal_cashier)}
                            />
                          </div>
                          <div className="d-grid">
                            <Link to={`/cierre-cajas/${id}`} className="btn btn-outline-primary">
                              Ver detalle
                            </Link>
                          </div>
                        </CCardBody>
                      </CCard>
                    </CCol>
                  ),
                )
              )}
            </CRow>
          </div>
        </CCardBody>
      </CCard>
      <CCard className="mb-3">
        <CCardHeader>
          <h4>Histórico</h4>
        </CCardHeader>
        <CCardBody>
          <div className="widget-dashboard">
            <CForm className="mb-4" id="form-filters">
              <CRow xs={{ gutterY: 2 }}>
                <CCol xs={12} sm={6} md={4} lg={5} xl={4} xxl={3}>
                  <CFormLabel htmlFor="date">Rango de fecha</CFormLabel>
                  <DateRangePicker
                    onChange={(info) => {
                      setCajasDate(info || [new Date(), new Date()])
                    }}
                    value={cajasDate}
                    format="dd-MM-yy"
                    rangeDivider="|"
                    maxDate={new Date()}
                    className=""
                  />
                </CCol>
                <CCol xs={12} sm={6} md={4} lg={5} xl={4} xxl={3}>
                  <CFormLabel htmlFor="cashier">Cajera</CFormLabel>
                  <div className="custom-select">
                    <CFormSelect
                      aria-label="Cajera"
                      id="cashier"
                      name="cashier"
                      onChange={(event) => setCashier(event)}
                    >
                      <option value="0">Todos</option>
                      {usersData.map(({ id = 0, firstname = '', lastname = '' }) => (
                        <option key={id} value={id} name={`${firstname}_${lastname}`}>
                          {firstname} {lastname}
                        </option>
                      ))}
                    </CFormSelect>
                    <IoChevronDownOutline className="custom-select__arrow" size="24" />
                  </div>
                </CCol>
              </CRow>
            </CForm>
            <Filter
              handleChangeFilter={handleChangeFilter}
              handleSendInput={handleSendInput}
              handleClearFilter={handleClearFilter}
              inputValue={inputValue}
              placeholder="ingrese código"
            />
            <CRow>
              <CCol>
                <SmartTable
                  responsive
                  items={sales || []}
                  columns={COLUMNS}
                  columnSorter
                  itemsPerPageSelect
                  itemsPerPage={LIMIT_TO_SHOW}
                  loading={loadingHistory}
                  sorterValue={{
                    key: 'date',
                  }}
                  tableProps={{
                    hover: true,
                  }}
                  itemsPerPageLabel="Filas por página"
                  noItemsLabel={'Sin Resultados'}
                  scopedColumns={{
                    id: (item) => (
                      <td
                        className="text-center"
                        style={{ display: details.includes(item.id) ? 'none' : '' }}
                      >
                        {item.id}
                      </td>
                    ),

                    code: (item) => (
                      <td
                        className="text-center"
                        style={{ display: details.includes(item.id) ? 'none' : '' }}
                      >
                        {item.transaction_id}
                      </td>
                    ),

                    user_name: (item) => {
                      return (
                        <td style={{ display: details.includes(item.id) ? 'none' : '' }}>
                          {`${item.user_name.toUpperCase()}`}
                        </td>
                      )
                    },

                    // cashier: (item) => {
                    //   const { cashier = {} } = item
                    //   const { firstname = '', lastname = '' } = cashier
                    //   return (
                    //     <td>
                    //       <p>
                    //         {firstname} {lastname}
                    //       </p>
                    //     </td>
                    //   )
                    // },

                    quantity: (item) => (
                      <td
                        className="text-center"
                        style={{ display: details.includes(item.id) ? 'none' : '' }}
                      >
                        {item.quantity}
                      </td>
                    ),

                    total: (item) => (
                      <td
                        className="text-end"
                        style={{ display: details.includes(item.id) ? 'none' : '' }}
                      >
                        {/* {formatCLP(item?.price + item?.difference)} */}
                        {formatCLP(item?.price * item?.quantity)}
                      </td>
                    ),

                    date: (item) => (
                      <td
                        className="text-center"
                        style={{ display: details.includes(item.id) ? 'none' : '' }}
                      >
                        {formatDate(item.date_from)}
                      </td>
                    ),

                    /*
                    status: (item) => (
                      <td
                        className="text-center"
                        style={{ display: details.includes(item.id) ? 'none' : '' }}
                      >
                        <CBadge color={getBadge(item.status)}>{item.status}</CBadge>
                      </td>
                    ),
                    */

                    detail: (item) => (
                      <td
                        className="text-center"
                        style={{ display: details.includes(item.id) ? 'none' : '' }}
                      >
                        <CButtonGroup size="sm" role="group">
                          <CButton
                            color="secondary"
                            onClick={() => {
                              toggleDetails(item.id)
                            }}
                          >
                            Ver
                          </CButton>
                        </CButtonGroup>
                      </td>
                    ),

                    details: (item) => {
                      const { used = false, used_date: usedDate = '' } = item
                      return (
                        <CCollapse visible={details.includes(item.id)}>
                          {details.includes(item.id) && (
                            <Detail item={item} toggleDetails={toggleDetails} />
                          )}
                        </CCollapse>
                      )
                    },
                  }}
                  // onFilteredItemsChange={(value) => setFiltered(value)}
                  clickableRows
                />
                <CSmartPagination
                  activePage={showPage}
                  pages={count}
                  onActivePageChange={handleChangePage}
                />
              </CCol>
            </CRow>
          </div>
        </CCardBody>
      </CCard>
    </div>
  )
}

export default Cierre
