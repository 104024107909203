/* eslint-disable prettier/prettier */

import { formatInTimeZone } from 'date-fns-tz'

export function formatCLP(value) {
  if (!isNaN(value)) {
    return new Intl.NumberFormat('es-CL', {
      style: 'currency',
      currency: 'CLP',
    }).format(value)
  }
  return 0
}

export function formatNumber(value) {
  if (!isNaN(value)) {
    return new Intl.NumberFormat().format(value)
  }
  return 0
}

export function formatDate(date) {
  return (
    date &&
    new Date(date).toLocaleDateString('es-CL', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
  )
}

export function formatDateISO(date) {
  const _date = new Date(date)
  const _year = _date.getFullYear()
  const _month = _date.getMonth() + 1 < 10 ? `0${_date.getMonth() + 1}` : _date.getMonth() + 1
  const _day = _date.getDate() < 10 ? `0${_date.getDate()}` : _date.getDate()
  return `${_year}-${_month}-${_day}`
}

export function decodeISODate(date) {
  if (date) {
    return new Date(date.slice(0, -1)).toLocaleDateString('es-CL', {
      day: '2-digit',
      year: 'numeric',
      month: '2-digit',
    })
  }
}

export const getTotalPages = (total = 1, limit) => {
  return total >= 0 ? Math.ceil(total / limit) : 0
}

export const getExactDate = (date = '') => formatInTimeZone(date, 'America/Santiago', 'dd-MM-yyyy')

export function validateEmail(email) {
  if (email) {
    var regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
    return regex.test(email) ? true : false
  }
}

export const getAdditionals = (arr = []) => {
  return arr.reduce((acc, entry) => {
    let { type, value } = entry
    return {
      ...acc,
      [type]: value,
    }
  }, {})
}
