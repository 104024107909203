/* eslint-disable react/prop-types */
import React from 'react'
import DatePicker from 'react-date-picker'
import { CCol, CRow, CSpinner, CWidgetStatsA } from '@coreui/react-pro'
import { formatCLP, formatNumber } from 'src/utils/helpers'
import { useDay } from '../hooks'
import Divider from 'src/components/Dividers'

export const StatsDay = ({ currentDate }) => {
  const { stats, loading, date, handleChangeDate } = useDay(currentDate)

  const {
    total_amount_web_expired = 0,
    total_cash = 0,
    total_cash_normal_cashier = 0,
    total_cash_normal_web = 0,
    total_tickets=0,
    total_tickets_cashier=0,
    total_tickets_free=0,
    total_tickets_orders=0,
    total_tickets_sold=0,
  } = stats || {}

  return (
    <CRow>
      <CCol xs={12}>
        <div className="mb-4">
          <CRow>
            <CCol xs={12} md={6} lg={4} xl={3}>
              <DatePicker
                value={date}
                format="dd-MM-yy"
               // maxDate={currentDate}
                onChange={handleChangeDate}
              />
            </CCol>
          </CRow>
        </div>
      </CCol>
      <CCol xs={12} md={6} lg={4} xl={3}>
        <CWidgetStatsA
          className="mb-3"
          inverse
          style={{ background: 'linear-gradient(45deg, #1A7EDA 0%, #4E8FD1 100%)' }}
          title={<span>Ventas del Día</span>}
          value={loading ? <CSpinner size="sm" /> : formatCLP(total_cash)}
          chart={
            <Divider type="waves" color="rgba(255,255,255,.2)" width="100%" height="50" rotateX />
          }
        />
      </CCol>
      <CCol xs={12} md={6} lg={4} xl={3}>
        <CWidgetStatsA
          className="mb-3"
          inverse
          style={{ background: 'linear-gradient(45deg, #1A7EDA 0%, #4E8FD1 100%)' }}
          title={<span>Cantidad de ticket vendidos</span>}
          value={loading ? <CSpinner size="sm" /> : formatNumber(total_tickets_sold)}
          chart={
            <Divider type="waves" color="rgba(255,255,255,.2)" width="100%" height="50" rotateX />
          }
        />
      </CCol>
      <CCol xs={12} md={6} lg={4} xl={3}>
        <CWidgetStatsA
          className="mb-3"
          inverse
          style={{ background: 'linear-gradient(45deg, #1A7EDA 0%, #4E8FD1 100%)' }}
          title={<span>Cantidad de tickets para el día</span>}
          value={loading ? <CSpinner size="sm" /> : formatNumber(total_tickets)}
          chart={
            <Divider type="waves" color="rgba(255,255,255,.2)" width="100%" height="50" rotateX />
          }
        />
      </CCol>
      <CCol xs={12} md={6} lg={4} xl={3}>
        <CWidgetStatsA
          className="mb-3"
          inverse
          style={{ background: 'linear-gradient(45deg, #1A7EDA 0%, #4E8FD1 100%)' }}
          title={<span>Cantidad de ticket caja</span>}
          value={loading ? <CSpinner size="sm" /> : formatNumber(total_tickets_cashier)}
          chart={
            <Divider type="waves" color="rgba(255,255,255,.2)" width="100%" height="50" rotateX />
          }
        />
      </CCol>
      <CCol xs={12} md={6} lg={4} xl={3}>
        <CWidgetStatsA
          className="mb-3"
          inverse
          style={{ background: 'linear-gradient(45deg, #1A7EDA 0%, #4E8FD1 100%)' }}
          title={<span>Cantidad de ticket free</span>}
          value={loading ? <CSpinner size="sm" /> : formatNumber(total_tickets_free)}
          chart={
            <Divider type="waves" color="rgba(255,255,255,.2)" width="100%" height="50" rotateX />
          }
        />
      </CCol>
      <CCol xs={12} md={6} lg={4} xl={3}>
        <CWidgetStatsA
          className="mb-3"
          inverse
          style={{ background: 'linear-gradient(45deg, #1A7EDA 0%, #4E8FD1 100%)' }}
          title={<span>Cantidad de órdenes</span>}
          value={loading ? <CSpinner size="sm" /> : formatNumber(total_tickets_orders)}
          chart={
            <Divider type="waves" color="rgba(255,255,255,.2)" width="100%" height="50" rotateX />
          }
        />
      </CCol>
      <CCol xs={12} md={6} lg={4} xl={3}>
        <CWidgetStatsA
          className="mb-3"
          style={{ background: 'linear-gradient(45deg, #509467 0%, #40AA58 100%)' }}
          inverse
          title={<span>Ventas Web</span>}
          value={loading ? <CSpinner size="sm" /> : formatCLP(total_cash_normal_web)}
          chart={
            <Divider
              type="waves-opacity"
              color="rgba(255,255,255,.2)"
              width="100%"
              height="50"
              rotateX
            />
          }
        />
      </CCol>
      <CCol xs={12} md={6} lg={4} xl={3}>
        <CWidgetStatsA
          className="mb-3"
          style={{ background: 'linear-gradient(45deg, #E1A82D 0%, #DD9124 100%)' }}
          inverse
          title="Venta Caja"
          value={loading ? <CSpinner size="sm" /> : formatCLP(total_cash_normal_cashier)}
          chart={
            <Divider
              type="mountain-sharp"
              color="rgba(255,255,255,.2)"
              width="100%"
              height="50"
              rotateX
            />
          }
        />
      </CCol>
      <CCol xs={12} md={6} lg={4} xl={3}>
        <CWidgetStatsA
          className="mb-3"
          style={{ background: 'linear-gradient(45deg, #D16767 0%, #C14F4F 100%)' }}
          inverse
          title="Tickets Caducados"
          value={loading ? <CSpinner size="sm" /> : formatNumber(total_amount_web_expired)}
          chart={
            <Divider
              type="triangle-a"
              color="rgba(255,255,255,.2)"
              width="100%"
              height="50"
              rotateX
            />
          }
        />
      </CCol>
    </CRow>
  )
}
