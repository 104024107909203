import { SET } from '../types/menu'

const initialState = {
  sidebarShow: true,
  asideShow: false,
  theme: 'default',
}

const menuReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case SET: {
      return { ...state, ...rest }
    }
    default: {
      return state
    }
  }
}

export default menuReducer
