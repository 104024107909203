import React, { useEffect, useState } from 'react'
import { CCard, CCardBody, CCardHeader, CCol, CForm, CRow, CSpinner } from '@coreui/react-pro'
import { ToastContainer, toast } from 'react-toastify'
import { isEmpty } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { getAllRoles } from 'src/actions/roles'
import { getDataUser, updateUser } from 'src/actions/user'
import { useHandleForm } from 'src/utils/hooks/useHandleForm'
import Form from '../components/Form'
import PaswordSection from '../components/PasswordSection'
import { ROLES } from '../constants'
import '../style.scss'
import 'react-toastify/dist/ReactToastify.min.css'
import { getAdditionals } from 'src/utils/helpers'

toast.configure()

const Edit = () => {
  const dispatch = useDispatch()
  const { id } = useParams()

  const userState = useSelector((state) => state.user)
  const roleState = useSelector((state) => state.role)
  const { user: userData = {} } = userState || {}
  const { data: rolesData = [] } = roleState || {}

  const rolesId = rolesData.map(({ id = 0 }) => id)

  const {
    roles = [],
    firstname = '',
    lastname = '',
    email: prevEmail = '',
    additionals = [],
    id: userId = 0,
    isFetching: isLoadingData = false,
    success = false,
    error = {},
  } = userData

  console.log(userState)

  const prevRole = roles.map(({ id }) => id)
  const [loading, setLoading] = useState(true)

  const [validated, setValidated] = useState(false)

  const [rolesOptions, setRolesOptions] = useState([])

  const [formState, setFormState] = useState({
    email: '',
    firstname: '',
    lastname: '',
    role: '',
    additional: [],
  })

  const [passwordState, setPasswordState] = useState({
    password: {
      value: '',
      visible: false,
    },
    repeat_password: {
      value: '',
      visible: false,
    },
    generated: '',
    equal: true,
  })

  const loadRoles = (currentRoles) => {
    const rolesToShow = ROLES.filter(({ value = 0 }) => rolesId.includes(value)).map(
      ({ value = 0, text = '' }) => {
        if (currentRoles.includes(value)) {
          return { value, text, selected: true }
        }
        return { value, text, selected: false }
      },
    )

    setRolesOptions(rolesToShow)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setValidated(true)
    const { password: passwordSelected = {} } = passwordState
    const { value: word = '' } = passwordSelected
    if (word) {
      formState['password'] = word
    }

    const form = event.currentTarget

    if (form.checkValidity()) {
      dispatch(updateUser(userId, formState))
    }
    setTimeout(() => {
      setValidated(false)
    }, 3000)
  }

  const { handleChangeInput, handleChangeSelector } = useHandleForm(setFormState)
  const handleUpdatePassword = () => {
    const { password: passwordSelected = {} } = passwordState
    const { value: word = '' } = passwordSelected
    formState['password'] = word
    dispatch(updateUser(userId, formState))
  }

  const notify = () =>
    toast.success('Datos actualizados con éxito', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: 'success-info',
    })

  useEffect(() => {
    dispatch(getDataUser(id))
    dispatch(getAllRoles())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isLoadingData) {
      const { phone = '', dni = '' } = getAdditionals(additionals)
      setFormState({
        email: prevEmail,
        firstname: firstname,
        lastname: lastname,
        dni,
        phone,
        additional: [
          { type: 'dni', value: dni },
          { type: 'phone', value: phone },
        ],
        role: prevRole,
      })
      loadRoles(prevRole)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingData])

  useEffect(() => {
    if (validated && !isEmpty(error)) {
      toast.error('Datos inválidos', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 'error-info',
      })
    }
    if (validated && success) {
      notify()
    }
  }, [error, validated, success])

  return isLoadingData ? (
    <CSpinner />
  ) : (
    <>
      <CCard>
        <CCardHeader>
          <h1 className="h3">Editar Usuario</h1>
        </CCardHeader>
        <CCardBody>
          {loading ? (
            <CSpinner />
          ) : (
            <CRow>
              <>
                <CCol xs={12} sm={8} md={6} xl={3}>
                  <Form
                    formState={formState}
                    handleSubmit={handleSubmit}
                    handleChangeInput={handleChangeInput}
                    handleChangeSelector={handleChangeSelector}
                    rolesOptions={rolesOptions}
                    isEdit={true}
                    validated={validated}
                    isLoading={loading}
                    setLoading={setLoading}
                  />
                </CCol>
                <CCol xs={12} sm={8} md={6} xl={{ span: 3, offset: 2 }}>
                  <CForm>
                    <PaswordSection
                      isEdit={true}
                      passwordState={passwordState}
                      setPasswordState={setPasswordState}
                      handleUpdate={handleUpdatePassword}
                      isFetching={isLoadingData}
                    />
                  </CForm>
                </CCol>
              </>
            </CRow>
          )}
          <ToastContainer autoClose={2000} />
        </CCardBody>
      </CCard>
    </>
  )
}

export default Edit
