/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */

/**
 * Tipos de Dividers
 * ------------------
 * waves = Olas
 * waves-opacity = Olas varias con opacidad
 * curve = Una curva no tan fina
 * curve-a = Una curva más asimetrica
 * triangle = Un Triangulo
 * triangle-a = Un Triangulo ladeado
 * tilt = Forma diagonal
 * arrow = Un triangulo pequeño que asemeja una flecha
 * split = Un efecto de separación
 * book = Efecto de libro abierto
 **/

import React from 'react'

const Divider = ({
  type,
  color = '#fff',
  rotateX = 0,
  rotateY = 0,
  rotateZ = 0,
  height = 500,
  width = 100,
}) => (
  <div className="divider">
    {type === 'waves' && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
        fill={color}
        width={width}
        height={height}
        style={{
          transform: `${rotateX ? 'rotateX(180deg)' : ''} ${rotateY ? 'rotateY(180deg)' : ''} ${
            rotateZ ? 'rotateZ(180deg)' : ''
          }`,
        }}
      >
        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"></path>
      </svg>
    )}
    {type === 'waves-hard' && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 255.69"
        preserveAspectRatio="none"
        fill={color}
        width={width}
        height={height}
        style={{
          transform: `${rotateX ? 'rotateX(180deg)' : ''} ${rotateY ? 'rotateY(180deg)' : ''} ${
            rotateZ ? 'rotateZ(180deg)' : ''
          }`,
        }}
      >
        <path
          d="M2273.56,925.78c-36.9,21.19-179.36,78.55-238.52,85.14-79.13,8.8,8.87-103.2-116.13-88.2-61.45,7.38-154.07,24.58-215.22,33.07-157.78,21.93-127.26-41.07-101.56-84.76,35.64-60.57,80.68-97.35,49.17-110.53-60.25-25.19-433.22,147.57-577.74,209.56V755.72h1200Z"
          transform="translate(-1073.56 -755.72)"
        />
      </svg>
    )}
    {type === 'waves-opacity' && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
        fill={color}
        width={width}
        height={height}
        style={{
          transform: `${rotateX ? 'rotateX(180deg)' : ''} ${rotateY ? 'rotateY(180deg)' : ''} ${
            rotateZ ? 'rotateZ(180deg)' : ''
          }`,
        }}
      >
        <path
          d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
          opacity=".25"
        ></path>
        <path
          d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
          opacity=".5"
        ></path>
        <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"></path>
      </svg>
    )}
    {type === 'curve' && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
        fill={color}
        width={width}
        height={height}
        style={{
          transform: `${rotateX ? 'rotateX(180deg)' : ''} ${rotateY ? 'rotateY(180deg)' : ''} ${
            rotateZ ? 'rotateZ(180deg)' : ''
          }`,
        }}
      >
        <path d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z"></path>
      </svg>
    )}
    {type === 'curve-a' && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
        fill={color}
        width={width}
        height={height}
        style={{
          transform: `${rotateX ? 'rotateX(180deg)' : ''} ${rotateY ? 'rotateY(180deg)' : ''} ${
            rotateZ ? 'rotateZ(180deg)' : ''
          }`,
        }}
      >
        <path d="M0,0V6c0,21.6,291,111.46,741,110.26,445.39,3.6,459-88.3,459-110.26V0Z"></path>
      </svg>
    )}
    {type === 'triangle' && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
        fill={color}
        width={width}
        height={height}
        style={{
          transform: `${rotateX ? 'rotateX(180deg)' : ''} ${rotateY ? 'rotateY(180deg)' : ''} ${
            rotateZ ? 'rotateZ(180deg)' : ''
          }`,
        }}
      >
        <path d="M1200 0L0 0 598.97 114.72 1200 0z"></path>
      </svg>
    )}
    {type === 'triangle-opacity' && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 2.646 0.529"
        preserveAspectRatio="none"
        fill={color}
        width={width}
        height={height}
        style={{
          transform: `${rotateX ? 'rotateX(180deg)' : ''} ${rotateY ? 'rotateY(180deg)' : ''} ${
            rotateZ ? 'rotateZ(180deg)' : ''
          }`,
        }}
      >
        <path d="M0 0h2.646L1.323.265z"></path>
        <path d="M0 0l1.323.53L2.646 0z" opacity=".66"></path>
      </svg>
    )}
    {type === 'triangle-a' && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
        fill={color}
        width={width}
        height={height}
        style={{
          transform: `${rotateX ? 'rotateX(180deg)' : ''} ${rotateY ? 'rotateY(180deg)' : ''} ${
            rotateZ ? 'rotateZ(180deg)' : ''
          }`,
        }}
      >
        <path d="M1200 0L0 0 892.25 114.72 1200 0z"></path>
      </svg>
    )}
    {type === 'tilt' && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
        fill={color}
        width={width}
        height={height}
        style={{
          transform: `${rotateX ? 'rotateX(180deg)' : ''} ${rotateY ? 'rotateY(180deg)' : ''} ${
            rotateZ ? 'rotateZ(180deg)' : ''
          }`,
        }}
      >
        <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z"></path>
      </svg>
    )}
    {type === 'arrow' && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
        fill={color}
        width={width}
        height={height}
        style={{
          transform: `${rotateX ? 'rotateX(180deg)' : ''} ${rotateY ? 'rotateY(180deg)' : ''} ${
            rotateZ ? 'rotateZ(180deg)' : ''
          }`,
        }}
      >
        <path d="M649.97 0L550.03 0 599.91 54.12 649.97 0z"></path>
      </svg>
    )}
    {type === 'split' && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
        fill={color}
        width={width}
        height={height}
        style={{
          transform: `${rotateX ? 'rotateX(180deg)' : ''} ${rotateY ? 'rotateY(180deg)' : ''} ${
            rotateZ ? 'rotateZ(180deg)' : ''
          }`,
        }}
      >
        <rect x="1200" height="3.6"></rect>
        <rect height="3.6"></rect>
        <path d="M0,0V3.6H580.08c11,0,19.92,5.09,19.92,13.2,0-8.14,8.88-13.2,19.92-13.2H1200V0Z"></path>
      </svg>
    )}
    {type === 'book' && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
        fill={color}
        width={width}
        height={height}
        style={{
          transform: `${rotateX ? 'rotateX(180deg)' : ''} ${rotateY ? 'rotateY(180deg)' : ''} ${
            rotateZ ? 'rotateZ(180deg)' : ''
          }`,
        }}
      >
        <path d="M1200,0H0V120H281.94C572.9,116.24,602.45,3.86,602.45,3.86h0S632,116.24,923,120h277Z"></path>
      </svg>
    )}
    {type === 'mountain-sharp' && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100.8 22.4"
        preserveAspectRatio="none"
        fill={color}
        width={width}
        height={height}
        style={{
          transform: `${rotateX ? 'rotateX(180deg)' : ''} ${rotateY ? 'rotateY(180deg)' : ''} ${
            rotateZ ? 'rotateZ(180deg)' : ''
          }`,
        }}
      >
        <path d="M101 22L94 8 84 18 76 7l-9 9-7-15-10 17-8-16-8 9-9 11-8-20-9 14-8 6V0h101z"></path>
      </svg>
    )}
    {type === 'tech' && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 203.29"
        preserveAspectRatio="none"
        fill={color}
        width={width}
        height={height}
        style={{
          transform: `${rotateX ? 'rotateX(180deg)' : ''} ${rotateY ? 'rotateY(180deg)' : ''} ${
            rotateZ ? 'rotateZ(180deg)' : ''
          }`,
        }}
      >
        <polygon points="0 0 0 3.87 60 70.54 180 70.54 240 136.67 300 70.39 360 136.91 480 136.91 540 203.29 660 202.8 720 136.91 840 136.91 900 70.24 960 136.91 1080 136.91 1200 3.87 1200 0 0 0" />
      </svg>
    )}
  </div>
)

export default Divider
