import { useState, useEffect } from 'react'
import { getDayStats } from 'src/request/orders'
import { formatDateISO } from 'src/utils/helpers'

const initialState = {
  data: [],
  loading: true,
  error: '',
}

export const useDay = (currentDate) => {
  const [stats, setStats] = useState(initialState.data)
  const [loading, setLoading] = useState(initialState.loading)
  const [date, setDate] = useState(currentDate)
  const [error, setError] = useState(false)

  const fetchStats = async () => {
    setLoading(true)
    try {
      await getDayStats(formatDateISO(date)).then(({ data }) => {
        setStats(data || initialState.data)
        setError(!data)
      })
    } catch (e) {
      setError(e)
      setStats(initialState.data)
    }
    setLoading(false)
  }

  const handleChangeDate = (date) => {
    setDate(date)
  }

  useEffect(() => {
    fetchStats()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date])

  return {
    stats,
    loading,
    error,
    date,
    handleChangeDate,
  }
}
