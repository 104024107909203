import { expireTicket, getOrders, getOrdersQuotes } from 'src/request/orders'
import {
  GET_ORDERS_REQUEST,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAILURE,
  GET_ORDERS_QUOTES_REQUEST,
  GET_ORDERS_QUOTES_SUCCESS,
  GET_ORDERS_QUOTES_FAILURE,
  PATCH_EXPIRE_ORDER_TICKET_REQUEST,
  PATCH_EXPIRE_ORDER_TICKET_SUCCESS,
  PATCH_EXPIRE_ORDER_TICKET_FAILURE,
} from 'src/types/orders'

const getOrdersRequest = (data) => ({
  type: GET_ORDERS_REQUEST,
  payload: data,
})

const getOrdersSuccess = (data) => ({
  type: GET_ORDERS_SUCCESS,
  payload: data,
})

const getOrdersFailured = (error) => ({
  type: GET_ORDERS_FAILURE,
  payload: error,
})

const getOrdersQuotesRequest = (data) => ({
  type: GET_ORDERS_QUOTES_REQUEST,
  payload: data,
})

const getOrdersQuotesSuccess = (data) => ({
  type: GET_ORDERS_QUOTES_SUCCESS,
  payload: data,
})

const getOrdersQuotesFailured = (error) => ({
  type: GET_ORDERS_QUOTES_FAILURE,
  payload: error,
})

const patchUseOrderTicketRequest = (data) => ({
  type: PATCH_EXPIRE_ORDER_TICKET_REQUEST,
  payload: data,
})
const patchUseOrderTicketSuccess = (data) => ({
  type: PATCH_EXPIRE_ORDER_TICKET_SUCCESS,
  payload: data,
})
const patchUseOrderTicketFailure = (error) => ({
  type: PATCH_EXPIRE_ORDER_TICKET_FAILURE,
  payload: error,
})

const listOrders =
  (filter = {}) =>
  (dispatch) => {

    dispatch(getOrdersRequest(filter))
    return getOrders(filter)
      .then(({ data = {} }) => {
        dispatch(getOrdersSuccess(data))
      })
      .catch(({ response = {} }) => {
        const { data = {} } = response
        return dispatch(getOrdersFailured(data))
      })
  }

const listOrdersQuotes =
  (filter = {}) =>
  (dispatch) => {
    dispatch(getOrdersQuotesRequest(filter))
    return getOrdersQuotes(filter)
      .then(({ data = {} }) => {
        dispatch(getOrdersQuotesSuccess(data))
      })
      .catch(({ response = {} }) => {
        const { data = {} } = response
        return dispatch(getOrdersQuotesFailured(data))
      })
  }

const expireOrderTicket =
  (orderId = 0, payload = {}) =>
  (dispatch) => {
    dispatch(patchUseOrderTicketRequest(orderId))
    return expireTicket(orderId, payload)
      .then(({ data = {} }) => {
        dispatch(patchUseOrderTicketSuccess(data))
      })
      .catch(({ response = {} }) => {
        const { data = {} } = response
        return dispatch(patchUseOrderTicketFailure(data))
      })
  }

export { listOrders, listOrdersQuotes, expireOrderTicket }
