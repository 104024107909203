import React from 'react'
import PropTypes from 'prop-types'
import {
  CAlert,
  CButton,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CMultiSelect,
  CSpinner,
} from '@coreui/react-pro'
import { isEmpty } from 'lodash'
import '../style.scss'
// import { handleDni } from 'src/views/VentasCaja/handlers'

const Form = ({
  formState = {},
  errors,
  loading,
  handleSubmit,
  handleChangeInput,
  // handleChangePhone,
  handleChangeSelector,
  rolesOptions = [],
  isEdit = false,
  validated = false,
  passwordSection,
}) => {
  return (
    <CForm onSubmit={handleSubmit} validated={validated}>
      {isEdit && (
        <div className="mb-5">
          <h5>Modificar Datos</h5>
        </div>
      )}
      <div className="mb-4">
        <CFormLabel htmlFor="firstname">Nombre</CFormLabel>
        <CFormInput
          type="text"
          id="firstname"
          name="firstname"
          value={formState?.firstname}
          onChange={handleChangeInput}
          required
        />
        <CFormFeedback invalid required>
          Campo requerido
        </CFormFeedback>
      </div>
      <div className="mb-4">
        <CFormLabel htmlFor="lastname">Apellido</CFormLabel>
        <CFormInput
          type="text"
          id="lastname"
          name="lastname"
          value={formState?.lastname}
          onChange={handleChangeInput}
          required
        />
        <CFormFeedback invalid>Campo requerido</CFormFeedback>
      </div>
      <div className="mb-4">
        <CFormLabel htmlFor="email">Email</CFormLabel>
        <CFormInput
          type="email"
          id="email"
          name="email"
          value={formState?.email}
          onChange={handleChangeInput}
          required
        />
        <CFormFeedback invalid>Campo requerido</CFormFeedback>
      </div>
      <div className="mb-4">
        <CFormLabel htmlFor="email">Teléfono</CFormLabel>
        <CFormInput
          type="phone"
          id="phone"
          name="phone"
          value={formState?.phone}
          onChange={handleChangeInput}
          required
        />
        <CFormFeedback invalid>Campo requerido</CFormFeedback>
      </div>
      <div className="mb-4">
        <CFormLabel htmlFor="email">Rut</CFormLabel>
        <CFormInput
          type="rut"
          id="dni"
          name="dni"
          value={formState?.dni}
          onChange={handleChangeInput}
          required
        />
        <CFormFeedback invalid>Campo requerido</CFormFeedback>
      </div>
      <div className="mb-4">
        <CFormLabel htmlFor="role">Rol</CFormLabel>
        {!isEmpty(rolesOptions) && (
          <CMultiSelect
            required
            placeholder="Seleccionar"
            selectAllLabel="Seleccionar todos"
            search
            options={rolesOptions}
            onChange={(selected) => {
              const selectedIds = selected.map(({ value = 0 }) => ({ id: value }))
              handleChangeSelector('role', selectedIds)
            }}
          />
        )}
        <CFormFeedback invalid>Campo requerido</CFormFeedback>
      </div>

      {!isEdit && passwordSection}
      {errors?.default && <CAlert color="danger">{errors?.default}</CAlert>}
      <div className="mb-4 text-end">
        <CButton type="submit" disabled={loading}>
          {!isEdit ? 'Crear' : 'Guardar'}
          {loading && <CSpinner style={{ marginLeft: 10 }} size="sm" />}
        </CButton>
      </div>
      <div className="mb-5"></div>
      <div className="mb-5"></div>
    </CForm>
  )
}

Form.propTypes = {
  formState: PropTypes.object.isRequired,
  errors: PropTypes.object,
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  handleChangeInput: PropTypes.func.isRequired,
  // handleChangePhone: PropTypes.func.isRequired,
  handleChangeSelector: PropTypes.func.isRequired,
  // handleDni: PropTypes.func.isRequired,
  // handlePhone: PropTypes.func.isRequired,
  rolesOptions: PropTypes.array.isRequired,
  isEdit: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool,
  validated: PropTypes.bool.isRequired,
  passwordSection: PropTypes.node,
}

export default Form
