export const LIMIT_TO_SHOW = 10
export const MIN_PAGE = 1

export const CREADO = 'CREADO'
export const APROBADO = 'APROBADO'
export const CADUCADO = 'CADUCADO'
export const TYPE_ID = 1

export const TABLE_COLUMNS = [
  {
    key: 'id',
    label: 'ID',
    filter: false,
    _style: { width: '100px' },
    _props: { align: 'middle', className: 'text-center' },
  },
  {
    key: 'transaction_id',
    label: 'Código',
    filter: false,
    _style: { width: '170px' },
    _props: { align: 'middle', className: 'text-center' },
  },
  {
    key: 'comment',
    label: 'Email',
    filter: false,
    _props: { align: 'middle' },
  },
  {
    key: 'date_from',
    filter: false,
    label: 'Fecha entrada',
    _style: { width: '15%' },
    _props: { align: 'middle', className: 'text-center' },
  },
  {
    key: 'date_to',
    filter: false,
    label: 'Fecha salida',
    _style: { width: '15%' },
    _props: { align: 'middle', className: 'text-center' },
  },
  {
    key: 'entry_type',
    filter: false,
    label: 'Horario de Ingreso',
    _style: { width: '15%' },
    _props: { align: 'middle', className: 'text-center' },
  },
  {
    key: 'quantity',
    label: 'Cantidad',
    filter: false,
    _style: { width: '150px' },
    _props: { align: 'middle', className: 'text-center' },
  },
  {
    key: 'price',
    label: 'Precio ticket',
    filter: false,
    _style: { width: '10%' },
    _props: { align: 'middle', className: 'text-end' },
  },
  {
    key: 'total',
    label: 'Precio total',
    filter: false,
    _style: { width: '10%' },
    _props: { align: 'middle', className: 'text-end' },
  },

  // {
  //   key: 'status',
  //   label: 'Estado',
  //   filter: false,
  //   _style: { width: '120px' },
  //   _props: { align: 'middle', className: 'text-center' },
  // },
  // {
  //   key: 'detail',
  //   label: 'Acciones',
  //   filter: false,
  //   sorter: false,
  //   _style: { width: '200px' },
  //   _props: { align: 'middle', className: 'text-center' },
  // },
]

export const ENTRY_TYPES = {
  school: {
    label: 'Colegio',
  },
  full_day: {
    label: 'Full day',
    description: '09:00 a 16:00 hrs',
  },
  exempt_payment: {
    label: 'Día exento de pago',
    description: '18:00 a 19:30 hrs',
  },
}
