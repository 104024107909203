import React from 'react'
import PropTypes from 'prop-types'
import { cilFilterX } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CButton, CCol, CFormInput, CFormLabel, CRow, CFormSelect } from '@coreui/react-pro'

const roleOptions = [
  { label: 'Admin', value: 1 },
  { label: 'Cashier', value: 2 },
  { label: 'Reseller', value: 3 },
  { label: 'User', value: 4 },
  { label: 'API', value: 5 },
];


const MultiFilter = ({
  handleSendInput,
  handleChangeFilter,
  handleClearFilter,
  filters,
  placeholder = '',
  button,
}) => {
  return (
    <div className="position-relative">
      <form onSubmit={handleSendInput}>
        <CRow className="mb-3">
          <CCol xs={12} md={2} lg={2} xl={1}>
            <CFormLabel className="col-form-label">Búsqueda</CFormLabel>
          </CCol>

          {/* Email */}
          <CCol xs={12} sm={6} md={6} lg={3} xl={3}>
            <CFormInput
              type="text"
              id="filter-email-user"
              placeholder="Ingrese email"
              value={filters.email || ''}
              onChange={(e) => handleChangeFilter('email', e.target.value)}
            />
          </CCol>

          {/* Nombre */}
          <CCol xs={12} sm={6} md={6} lg={3} xl={3}>
            <CFormInput
              type="text"
              id="filter-name-user"
              placeholder="Ingrese nombre"
              value={filters.firstname || ''}
              onChange={(e) => handleChangeFilter('firstname', e.target.value)}
            />
          </CCol>

          {/* Rol */}
{/*           <CCol xs={12} sm={6} md={6} lg={3} xl={3}>
            <CFormSelect
              id="filter-role-user"
              multiple
              value={filters.role_ids || []}
              onChange={(e) => {
                const selectedRoles = Array.from(e.target.selectedOptions, (option) => parseInt(option.value,10));
                handleChangeFilter('role_ids', selectedRoles);
              }}
            >
              {roleOptions.map((role) => (
                <option key={role.value} value={role.value}>
                  {role.label}
                </option>
              ))}
            </CFormSelect>
          </CCol> */}

          {/* Botones */}
          <CCol xs={12} sm={6} md={4} lg={3} xl={2} className={'filter-buttons'}>
            <CButton type="submit" color="info" variant="outline">
              Filtrar
            </CButton>
            <CButton color="info" variant="outline" onClick={handleClearFilter}>
              <CIcon icon={cilFilterX} size="lg" />
            </CButton>
          </CCol>

          {button && button}
        </CRow>
      </form>
    </div>
  )
}

MultiFilter.propTypes = {
  handleSendInput: PropTypes.func.isRequired,
  handleChangeFilter: PropTypes.func.isRequired,
  handleClearFilter: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    email: PropTypes.string,
    firstname: PropTypes.string,
    //role_ids: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  placeholder: PropTypes.string,
  button: PropTypes.node,
};

export default MultiFilter
