import React from 'react'

import { NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
  CButtonGroup,
  CButton,
  // CTooltip,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilMoon, cilSun } from '@coreui/icons'

import { AppBreadcrumb } from './index'
import { IoPowerSharp } from 'react-icons/io5'
import { SET } from 'src/types/menu'
import { logoutUser } from 'src/actions/logout'
import Logo from '../assets/img/logo.svg'

/* eslint-disable react/prop-types */
const AppHeader = ({ setDark, dark }) => {
  const dispatch = useDispatch()

  const sidebarShow = useSelector((state) => state.sidebarShow)

  const handleDark = (state) => setDark(state)

  const handleLogout = (e) => {
    e.preventDefault()
    dispatch(logoutUser())
    window.location = '/login'
  }

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch({ type: SET, sidebarShow: !sidebarShow })}
        >
          <CIcon icon="cil-menu" size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none" to="/">
          {/* <CIcon icon="logo" height={48} alt="Logo" /> */}
          <img src={Logo} alt="Parque Oasis" height="55" />
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto">
          <CNavItem>
            <CNavLink to="/dashboard" component={NavLink} activeClassName="active">
              Dashboard
            </CNavLink>
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav className="ms-auto px-3">
          <CButtonGroup role="group" aria-label="Basic example">
            <CButton
              color={dark ? 'secondary' : 'dark'}
              active={dark}
              onClick={() => handleDark(true)}
              title="Dark Theme"
            >
              <CIcon icon={cilMoon} />
            </CButton>
            <CButton
              color={!dark ? 'primary' : 'light'}
              active={!dark}
              onClick={() => handleDark(false)}
              title="Light Theme"
            >
              <CIcon icon={cilSun} />
            </CButton>
          </CButtonGroup>
        </CHeaderNav>
        <CButton
          variant="ghost"
          color="danger"
          style={{
            display: 'inline-flex',
            alignItems: 'center',
          }}
          onClick={handleLogout}
        >
          <IoPowerSharp
            style={{
              marginRight: 5,
            }}
          />
          Salir
        </CButton>
      </CContainer>
      <CHeaderDivider />
      <CContainer fluid>
        <AppBreadcrumb />
      </CContainer>
    </CHeader>
  )
}

export default AppHeader
