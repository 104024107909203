import { getEvent, getEvents } from 'src/request/event'
import {
  GET_EVENTS_REQUEST,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILURE,
  GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  GET_EVENT_FAILURE,
} from 'src/types/event'

const getEventsRequest = (data) => ({
  type: GET_EVENTS_REQUEST,
  payload: data,
})

const getEventsSuccess = (data) => ({
  type: GET_EVENTS_SUCCESS,
  payload: data,
})

const getEventsFailured = (error) => ({
  type: GET_EVENTS_FAILURE,
  payload: error,
})

const getEventRequest = (data) => ({
  type: GET_EVENT_REQUEST,
  payload: data,
})

const getEventSuccess = (data) => ({
  type: GET_EVENT_SUCCESS,
  payload: data,
})

const getEventFailured = (error) => ({
  type: GET_EVENT_FAILURE,
  payload: error,
})

const listEvents =
  (filter = {}) =>
  (dispatch) => {
    dispatch(getEventsRequest(filter))
    return getEvents(filter)
      .then(({ data = {} }) => {
        dispatch(getEventsSuccess(data))
      })
      .catch(({ response = {} }) => {
        const { data = {} } = response
        return dispatch(getEventsFailured(data))
      })
  }

const getEventById =
  (eventId = 0) =>
  (dispatch) => {
    dispatch(getEventRequest(eventId))
    return getEvent(eventId)
      .then(({ data = {} }) => {
        dispatch(getEventSuccess(data))
      })
      .catch(({ response = {} }) => {
        const { data = {} } = response
        return dispatch(getEventFailured(data))
      })
  }

export { listEvents, getEventById }
