import React from 'react'
import { CNavItem } from '@coreui/react-pro'
// import { cilSpeedometer, cilCart } from '@coreui/icons'
import {
  IoSpeedometerOutline,
  IoCartOutline,
  IoClipboardOutline,
  IoPersonOutline,
  IoTicketOutline,
  IoCubeOutline,
  IoCalendarOutline
} from 'react-icons/io5'
import { MdOutlinePayment } from "react-icons/md";
const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <IoSpeedometerOutline className="nav-icon" />,
    auth_roles: [1, 2],
    // badge: {
    //   color: 'info-gradient',
    //   text: 'NEW',
    // },
  },
  {
    component: CNavItem,
    name: 'Ventas',
    to: '/ventas',
    icon: <IoCartOutline className="nav-icon" />,
    auth_roles: [1, 2],
  },
  {
    component: CNavItem,
    name: 'Caja',
    to: '/ventas-caja',
    icon: <IoCubeOutline className="nav-icon" />,
    auth_roles: [1, 2],
  },
  {
    component: CNavItem,
    name: 'Cotizaciones',
    to: '/cotizaciones',
    icon: <IoClipboardOutline className="nav-icon" />,
    auth_roles: [1],
  },
  {
    component: CNavItem,
    name: 'Usuarios',
    to: '/usuarios',
    icon: <IoPersonOutline className="nav-icon" />,
    auth_roles: [1],
  },
  {
    component: CNavItem,
    name: 'Cierre de Cajas',
    to: '/cierre-cajas',
    icon: <IoTicketOutline className="nav-icon" />,
    auth_roles: [1, 2],
  },
  {
    component: CNavItem,
    name: 'Cargar Valores',
    to: '/cargar-valores',
    icon: <IoCalendarOutline className="nav-icon" />,
    auth_roles: [1],
  },
  {
    component: CNavItem,
    name: 'Medios de Pagos',
    to: '/medios-de-pago',
    icon: <MdOutlinePayment className="nav-icon" />,
    auth_roles: [1],
  },
]

export default _nav
