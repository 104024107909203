import { getUser, getUsers, insertUser, putUser } from 'src/request/user'
import {
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  POST_USER_ADMIN_REQUEST,
  POST_USER_ADMIN_SUCCESS,
  POST_USER_ADMIN_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  PUT_UPDATE_USER_REQUEST,
  PUT_UPDATE_USER_SUCCESS,
  PUT_UPDATE_USER_FAILURE,
} from 'src/types/user'
import { getAllRoles } from './roles'

const getUsersRequest = (data) => ({
  type: GET_USERS_REQUEST,
  payload: data,
})

const getUsersSuccess = (data) => ({
  type: GET_USERS_SUCCESS,
  payload: data,
})

const getUsersFailured = (error) => ({
  type: GET_USERS_FAILURE,
  payload: error,
})

const getUserRequest = (data) => ({
  type: GET_USER_REQUEST,
  payload: data,
})

const getUserSuccess = (data) => ({
  type: GET_USER_SUCCESS,
  payload: data,
})

const getUserFailure = (error) => ({
  type: GET_USER_FAILURE,
  payload: error,
})

const addUserAdminRequest = (data) => ({
  type: POST_USER_ADMIN_REQUEST,
  payload: data,
})

const addUserAdminSuccess = (data) => ({
  type: POST_USER_ADMIN_SUCCESS,
  payload: data,
})

const addUserAdminFailure = (error) => ({
  type: POST_USER_ADMIN_FAILURE,
  payload: error,
})

const getUpdateUserRequest = (data) => ({
  type: PUT_UPDATE_USER_REQUEST,
  payload: data,
})

const getUpdateUserSuccess = (data) => ({
  type: PUT_UPDATE_USER_SUCCESS,
  payload: data,
})

const getUpdateUserFailured = (data) => ({
  type: PUT_UPDATE_USER_FAILURE,
  payload: data,
})

const listUsers =
  (filter = {}) =>
  (dispatch) => {
    dispatch(getUsersRequest(filter))
    return getUsers(filter)
      .then(({ data = {} }) => {
        dispatch(getUsersSuccess(data))
      })
      .catch(({ response = {} }) => {
        const { data = {} } = response
        dispatch(getAllRoles())
        return dispatch(getUsersFailured(data))
      })
  }

const addUser =
  (user = {}) =>
  async (dispatch) => {
    dispatch(addUserAdminRequest(user))

    try {
      const { data: data_1 = {} } = await insertUser(user)
      dispatch(addUserAdminSuccess(data_1))
      window.location = '/usuarios'
    } catch (error) {
      return dispatch(addUserAdminFailure(error))
    }
  }

const getDataUser =
  (id = 0) =>
  (dispatch) => {
    dispatch(getUserRequest(id))
    return getUser(id)
      .then(({ data = {} }) => {
        dispatch(getUserSuccess(data))
      })
      .catch(({ response = {} }) => {
        const { data = {} } = response

        return dispatch(getUserFailure(data))
      })
  }

const updateUser =
  (id = 0, user = {}) =>
  (dispatch) => {
    dispatch(getUpdateUserRequest(user))
    putUser(id, user)
      .then(({ data = {} }) => {
        dispatch(getUpdateUserSuccess(data))
        dispatch(getDataUser(id))
      })
      .catch(({ response = {} }) => {
        const { data = {} } = response
        return dispatch(getUpdateUserFailured(data))
      })
  }

export { listUsers, addUser, getDataUser, updateUser }
