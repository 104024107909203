/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react'
import {
  IoCalendarClearOutline,
  IoCashOutline,
  IoPeopleOutline,
  IoPersonSharp,
} from 'react-icons/io5'

// Core-UI
import {
  CBadge,
  CButton,
  CButtonGroup,
  CCol,
  CRow,
  CSpinner,
  CWidgetStatsF,
} from '@coreui/react-pro'

// Requests
import { getUser } from 'src/request/user'

// Helpers
import { getState } from './helpers'
import { formatCLP, formatPhoneNumber, getExactDate } from 'src/utils/helpers'

// eslint-disable-next-line react/prop-types
const Detail = ({ item, toggleDetails, handleCaducar }) => {
  const [loading, setLoading] = useState(true)

  // User Data
  const [user, setUser] = useState([])
  const {
    id,
    user_id,
    transaction_id: code,
    order_status_id: status,
    quantity,
    price,
    used,
  } = item || {}
  useEffect(() => {
    getUser(user_id).then((response) => {
      setUser(response.data)
      setLoading(false)
    })
  }, [user_id])

  // Order status helper
  const orderStatus = getState(status)
  const { label = '', color = '' } = orderStatus

  return loading ? (
    <CSpinner />
  ) : (
    <>
      <CBadge color={color} style={{ fontSize: '0,5rem' }}>
        {label}
      </CBadge>
      <h3>
        <code>#{code}</code>
      </h3>
      <h5>
        {user?.firstname?.toUpperCase()} {user?.lastname?.toUpperCase()}
      </h5>
      {user?.email && <p>{user.email}</p>}
      {/* <p>
        {user?.additionals?.map((additional) => {
          const validateType = ({ type, value }) => {
            if (type === 'phone') {
              return (
                <>
                  <strong>{type.toUpperCase()}: </strong>
                  {value}
                </>
              )
            }
            return (
              <>
                <strong>{additional.type.toUpperCase()}: </strong>
                {value}
              </>
            )
          }
          return <div key={additional.value}>{validateType(additional)}</div>
        })}
      </p> */}
      <CRow>
        <CCol xs={12} sm={4} md={3}>
          <CWidgetStatsF
            className="mb-3"
            color="info"
            icon={<IoPeopleOutline size="1.5em" />}
            padding={false}
            title="Cantidad"
            value={quantity}
          />
        </CCol>
        <CCol xs={12} sm={4} md={3}>
          <CWidgetStatsF
            className="mb-3"
            color="success"
            icon={<IoCashOutline size="1.5em" />}
            padding={false}
            title="Precio total"
            // value={formatCLP(item?.price + item?.difference)}
            value={formatCLP(price * quantity)}
          />
        </CCol>
        {used && (
          <>
            <CCol xs={12} sm={4} md={3}>
              <CWidgetStatsF
                className="mb-3"
                color="warning"
                icon={<IoCalendarClearOutline size="1.5em" />}
                padding={false}
                title="Fecha Caducada"
                // value={getExactDate(usedDate)}
              />
            </CCol>
            <CCol xs={12} sm={4} md={3}>
              <CWidgetStatsF
                className="mb-3"
                color="warning"
                icon={<IoPersonSharp size="1.5em" />}
                padding={false}
                title="Caducado por"
                // value={`${item?.cashier?.firstname} ${item?.cashier?.lastname}`}
              />
            </CCol>
          </>
        )}
      </CRow>
      <div className="text-end">
        <CButtonGroup role="group" className="text-end">
          <CButton
            color="dark"
            onClick={() => {
              toggleDetails(id)
            }}
          >
            Cerrar
          </CButton>
          {status !== 3 && (
            <CButton color="danger" disabled={loading} onClick={() => handleCaducar(item)}>
              Caducar
            </CButton>
          )}
        </CButtonGroup>
      </div>
    </>
  )
}

export default Detail
