import React, { useEffect, useState } from 'react'
import {
  CAlert,
  CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableRow,
} from '@coreui/react-pro'
import { useDispatch } from 'react-redux'
import swal from '@sweetalert/with-react'
import {  useHistory } from 'react-router-dom'

// Hooks, Request & Helpers
import { useHandleForm } from 'src/utils/hooks/useHandleForm'
import { formatCLP } from 'src/utils/helpers'
import { addValue } from 'src/request/values'

// Components
import Form from '../Form'

const Create = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [sending, setSending] = useState(false)
  const history = useHistory()

  const [validated, setValidated] = useState(false)
  const [days, setDays] = useState({
    date_from: new Date(),
    date_to: new Date(),
  })
  const [blockedApps, setBlockedApps] = useState([])

  const [formState, setFormState] = useState({
    date_from: days.date_from,
    date_to: days.date_to,
    price_web: 0,
    price_day: 0,
    price_night: 0,
    price_school: 0,
    recharge: false,
    // cost_recharge: 0,
    blocked_application: blockedApps,
  })

  const [response, setResponse] = useState({})

  const handleCheckboxGroup = ({ target }) => {
    const { name } = target
    const checkeds = document.querySelectorAll(`input[name=${name}]:checked`)
    const apps = []

    checkeds.length && checkeds.forEach((check) => apps.push(Number(check.value)))

    setBlockedApps(apps)
  }

  const { handleChangeInput, handleChangeSelector, handleCheckboxSelector } =
    useHandleForm(setFormState)

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    dispatch(addValue(formState, handleResponse))
    setValidated(true)
    setSending(true)
  }

  const handleResponse = (resp) => {
    if (resp) {
      const { data, status } = resp

      setSending(false)
      setResponse(resp)

      console.log(resp)

      if (status === 'success')
        swal({
          buttons: {
            // cancel: 'Cancelar',
            confirm: 'Aceptar',
          },
          allowOutsideClick: () => {
            history.push('/cargar-valores')
          },
          // dangerMode: true,
          content: (
            <div className="swal__content">
              <div className="mb-4">
                <CAlert color="success">Valor agregado correctamente</CAlert>
                <CTable>
                  <CTableBody>
                    <CTableRow>
                      <CTableDataCell className="text-sm-start fw-bold">
                        Fecha inicio
                      </CTableDataCell>
                      <CTableDataCell className="text-sm-end">{data.date_from}</CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell className="text-sm-start fw-bold">
                        Fecha termino
                      </CTableDataCell>
                      <CTableDataCell className="text-sm-end">{data.date_to}</CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell className="text-sm-start fw-bold">
                        Precio internet
                      </CTableDataCell>
                      <CTableDataCell className="text-sm-end">
                        {formatCLP(data.price_web)}
                      </CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell className="text-sm-start fw-bold">
                        Precio tienda
                      </CTableDataCell>
                      <CTableDataCell className="text-sm-end">
                        {formatCLP(data.price_day)}
                      </CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell className="text-sm-start fw-bold">
                        Precio camping
                      </CTableDataCell>
                      <CTableDataCell className="text-sm-end">
                        {formatCLP(data.price_night)}
                      </CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell className="text-sm-start fw-bold">
                        Precio colegio
                      </CTableDataCell>
                      <CTableDataCell className="text-sm-end">
                        {formatCLP(data.price_school)}
                      </CTableDataCell>
                    </CTableRow>
                    {data.blocked_application.length ? (
                      <CTableRow>
                        <CTableDataCell className="text-sm-start fw-bold">
                          Aplicaciones bloqueadas
                        </CTableDataCell>
                        <CTableDataCell className="text-sm-end">
                          <div style={{ display: 'flex', gap: 10 }}>
                            {data.blocked_application.map((app) => (
                              <CBadge color="info" key={app}>
                                {(Number(app) === 1 && 'Caja') ||
                                  (Number(app) === 2 && 'Website') ||
                                  (Number(app) === 3 && 'Totems') ||
                                  ''}
                              </CBadge>
                            ))}
                          </div>
                        </CTableDataCell>
                      </CTableRow>
                    ) : null}
                    <CTableRow>
                      <CTableDataCell className="text-sm-start fw-bold">Recargo</CTableDataCell>
                      <CTableDataCell className="text-sm-end">
                        {data.recharge ? 'Si' : 'No'}
                      </CTableDataCell>
                    </CTableRow>
                  </CTableBody>
                </CTable>
              </div>
            </div>
          ),
        }).then(async (value) => {
          history.push('/cargar-valores')
        })
    }
  }

  useEffect(() => {
    setFormState((prev) => ({
      ...prev,
      blocked_application: blockedApps,
    }))
  }, [blockedApps])

  useEffect(() => {
    setLoading(false)
  }, [response])

  useEffect(() => {
    setFormState((prev) => ({
      ...prev,
      ...days,
    }))
  }, [days])

  return loading ? (
    <CSpinner />
  ) : (
    <>
      <CCard>
        <CCardHeader>
          <h1 className="h3">Crear Valor por Día</h1>
        </CCardHeader>
        <CCardBody>
          {sending && (
            <div
              style={{
                position: 'absolute',
                inset: 0,
                background: 'rgba(255,255,255,.3)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CSpinner color="primary" variant="grow" />
            </div>
          )}
          <CRow>
            <CCol xs={12} md={4}>
              <div className="mb-5">
                <p>
                  <strong>Considerar lo siguiente antes de cargar un valor:</strong>
                </p>
                <ul>
                  <li>Los valores se cargan por rangos de Fecha.</li>
                  <li>
                    Si las fechas coinciden con un valor ya existente, el nuevo valor reemplazará al
                    anterior.
                  </li>
                </ul>
              </div>
            </CCol>
            <CCol xs={1}></CCol>
            <CCol xs={12} md={6}>
              <Form
                formState={formState}
                handleSubmit={handleSubmit}
                handleChangeInput={handleChangeInput}
                handleChangeSelector={handleChangeSelector}
                handleCheckboxSelector={handleCheckboxSelector}
                // handleChecksValidation={handleChecksValidation}
                handleCheckboxGroup={handleCheckboxGroup}
                setDays={setDays}
                validated={validated}
              />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </>
  )
}

export default Create
