/* eslint-disable react/prop-types */
import React from 'react'
import { CAlert, CCard, CCardBody } from '@coreui/react-pro'
import { Line } from 'react-chartjs-2'
import { useMonth } from '../hooks'
import { MONTH } from '../constants'

export const StatsMonth = ({ currentMonth }) => {
  const { chart, loading } = useMonth(currentMonth)

  console.log(chart)

  return (
    <CCard className="mb-3">
      <CCardBody>
        {loading ? (
          'cargando'
        ) : (
          <>
            <div className="mb-4">
              <h3>Ventas</h3>
              <h6>
                <span style={{ textTransform: 'capitalize' }}>{MONTH}</span>
              </h6>
            </div>
            {chart.total > 2 ? (
              <Line data={chart.months} height={75} options={chart.options} />
            ) : (
              <CAlert color="warning">No hay información suficiente para mostrar este mes</CAlert>
            )}
          </>
        )}
      </CCardBody>
    </CCard>
  )
}
