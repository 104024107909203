import axios from 'axios'
import Cookies from 'js-cookie'

const logoutToken = () => {
  Cookies.remove('token')
  Cookies.remove('user')
  window.location = '/login'
}

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
})

const apiWithoutAuth = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
})

apiWithoutAuth.interceptors.request.use((config) => {
  if (navigator.onLine) {
    return config
  }
})

const setInterceptor = (axiosInstance) => {

  axiosInstance.interceptors.response.use(null, async (err) => {
    const { config = {}, response = {} } = err
    const { status = {} } = response

    return new Promise((resolve, reject) => {
      if (status === 401 && config) {
        logoutToken()
      }

      throw err
    })
  })
}

const setTokenInterceptor = (axiosInstance) => {

  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = Cookies.get('token')
      if (token) config.headers.authorization = `Bearer ${token}`

      return config
    },
    (error) => {
      Promise.reject(error)
    },
  )
}

setInterceptor(apiWithoutAuth)
setInterceptor(api)
setTokenInterceptor(api)

export { api, apiWithoutAuth }
