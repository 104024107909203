// Libs
import React, { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormSwitch,
  CFormTextarea,
  CRow,
  CSpinner,
} from '@coreui/react-pro'
// import DatePicker from 'react-date-picker'
import {
  IoCashOutline,
  IoChevronDownOutline,
  IoLogOut,
  IoTicketOutline,
  IoWarning,
} from 'react-icons/io5'

// Utils
import { decodeISODate, formatCLP } from '../../utils/helpers'

// Requests
import {
  getOrderTicket,
  expireTicket,
  // getConfigTotems,
  AddOrderPayment,
  getPayments,
} from 'src/request/orders'
import { useSelector } from 'react-redux'
import { useCashierMP } from 'src/utils/hooks/useCashierMP'
import { STATUS } from './constants'

const PAYMENT_STATUS_PENDING = 1

const ExpireTicket = () => {
  const { id } = useParams()
  const location = useLocation()
  const { data: user } = useSelector((state) => state.auth)
  // const cashier = useCashierMP(user.id)
  const cashier = useCashierMP(123) // SOLO PUREBA
  const [state, setState] = useState(STATUS[0])
  const [error, setError] = useState('')
  const [loading, setLoading] = useState({
    global: true,
    pay: false,
  })
  const surchargeValor = location.state.surchargeValor
  const [surcharge, setSurcharge] = useState({})
  const [orderInfo, setOrderInfo] = useState({})
  const [payment, setPayment] = useState()
  const [printPdf, setPrintPdf] = useState({
    active: false,
    activeBtn: false,
    url: '',
  })
  const [comment, setComment] = useState('')
  const [skip, setSkip] = useState(false)
  // const [out, setOut] = useState(false)

  const handleSkip = ({ target: { checked } }) => {
    setSkip(checked)
    setPayment('')
    setComment('')
  }
  const handleComment = ({ target: { value } }) => setComment(value)

  const fetchExpireTicket = async () => {
    await expireTicket('', {
      transaction_id: id,
      application_id: 2,
    })
      .then((data) => {
        setState('expired')
        setPrintPdf({ ...printPdf, activeBtn: true, url: data.data.url })
        setLoading((prev) => ({ ...prev, pay: false }))
      })
      .catch(({ response = {} }) => {
        const { data } = response
        setSurcharge({ ...surcharge, ...data })
        setState('error')
        setError(data.error)
      })
  }

  const fetchOrderTicket = async () => {
    try {
      const { data } = await getOrderTicket(id)
      setOrderInfo(data)
      setLoading((prev) => ({ ...prev, global: false }))
    } catch (error) {
      console.log('fetchOrderTicket', error)
      setState('error')
      // setError(error)
    }
  }

  useEffect(() => {
    fetchOrderTicket().catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let interval
    if (printPdf.active && state !== 'expired') {
      interval = setInterval(async () => {
        const { data = {} } = await getPayments(surcharge.transaction_id)
        const { payments = [] } = data || {}
        const [firstPayment = {}] = payments || []
        const { payment_status: paymentStatus = {} } = firstPayment || {}
        const { id: paymentStatusId = 0, paid = false } = paymentStatus || {}

        if (paid && paymentStatusId !== PAYMENT_STATUS_PENDING) {
          fetchExpireTicket().catch(console.error)
          setState('expired')
          setLoading((prev) => ({ ...prev, pay: false }))
          clearInterval(interval)
          return
        }
        if (!paid && paymentStatusId !== PAYMENT_STATUS_PENDING) {
          setError('El ticket no pudo ser pagado')
          setState('error')
          return
        }
      }, 10000)
    } else {
      return () => clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printPdf])

  const handlePaymentMp = async (event) => {
    event.preventDefault()
    setLoading((prev) => ({ ...prev, pay: true }))

    const { identify, store_code, totem_code } = cashier || {}

    try {
      await AddOrderPayment(id, {
        payment_method_id: 1,
        identify,
        store_code,
        totem_code,
        amount: surchargeValor,
        payment_status_id: 1,
        free: skip,
        application_id: 2,
        payment_type: 'difference',
      }).then((data) => {
        setSurcharge({ ...surcharge, transaction_id: data.data.transaction_id })
        setPrintPdf({ ...printPdf, active: true })
        setState('pending')
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handlePaymentAp = async (event) => {
    event.preventDefault()
    setLoading((prev) => ({ ...prev, pay: true }))

    const { identify, store_code, totem_code } = cashier || {}

    console.log("on handlePaymentAp payment",payment)
    try {
      await AddOrderPayment(id, {
        payment_method_id: payment,
        amount: surchargeValor,
        payment_status_id: 4,
        identify,
        store_code,
        totem_code,
        free: skip,
        application_id: 2,
        comment,
        payment_type: 'difference',
      }).then((data) => {
        fetchExpireTicket().catch(console.error)
      })
    } catch (error) {
      console.log('AddOrderPayment', error)
      setState('error')
      // setError(error)
    }
  }

  const handlePrint = (event) => {
    console.log("printPdf.url ",printPdf.url)
    event.preventDefault()
    window.open(printPdf.url, 'PRINT', 'height=400,width=600')
    // setOut(true)
    setState('printed')
  }

  const paymentSelect = ({ target: { value } }) => {
    setPayment(Number(value))
  }

  if (loading.global) return <CSpinner />

  return (
    <>
      <div className="section">
        <CCard className="mb-3">
          <CCardHeader>
            <h2>#{orderInfo.transaction_id}</h2>
          </CCardHeader>
          <CCardBody>
            <CForm>
              <CRow>
                <CCol xxl={10}>
                  <CAlert color="warning" variant="solid">
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                      }}
                    >
                      <div>
                        <h4>
                          <IoWarning /> Aviso <IoWarning />
                        </h4>
                        Este usuario compró para el día{' '}
                        <strong>{decodeISODate(orderInfo.date_from)}</strong>, hay una diferencia en
                        el valor de <strong>{formatCLP(surchargeValor)}</strong>
                      </div>
                    </div>
                  </CAlert>
                  <CRow>
                    <CCol xs={12}>
                      <div
                        className="mb-4"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          textAlign: 'center',
                        }}
                      >
                        <CFormSwitch
                          name="free"
                          id="free"
                          label="Omitir Pago"
                          size="lg"
                          onChange={handleSkip}
                          disabled={loading.pay}
                        />
                      </div>
                      <hr />
                      {skip && (
                        <div className="mb-4">
                          <CFormLabel htmlFor="comment">Comentario</CFormLabel>
                          <CFormTextarea
                            name="comment"
                            id="comment"
                            onChange={handleComment}
                            placeholder="Ingresa el motivo"
                            disabled={loading.pay}
                          />
                        </div>
                      )}
                    </CCol>
                    {!skip && (
                      <>
                        <CCol xs={12} sm={6} lg={4}>
                          <div className="mb-4">
                            <CFormLabel htmlFor="ticket-number">Monto</CFormLabel>
                            <CFormInput
                              type="text"
                              id="ticket-mount"
                              readOnly="readOnly"
                              value={surchargeValor}
                            />
                          </div>
                        </CCol>

                        <CCol xs={12} sm={6} lg={4}>
                          <div className="mb-4">
                            <CFormLabel htmlFor="ticket-number">Nombre</CFormLabel>
                            <CFormInput
                              type="text"
                              id="ticket-name"
                              readOnly="readOnly"
                              value={`${orderInfo.user.firstname} ${orderInfo.user.lastname} `}
                            />
                          </div>
                        </CCol>

                        <CCol xs={12} sm={6} lg={4}>
                          <div className="mb-4">
                            <CFormLabel htmlFor="ticket-number">Email</CFormLabel>
                            <CFormInput
                              type="text"
                              id="ticket-email"
                              readOnly="readOnly"
                              value={orderInfo.user.email}
                            />
                          </div>
                        </CCol>
                        <CCol xs={12} sm={6} lg={4}>
                          <div className="mb-4">
                            <CFormLabel htmlFor="paid-type">Forma de Pago</CFormLabel>
                            <div className="custom-select">
                              <CFormSelect
                                aria-label="Forma de Pago"
                                id="paid-type"
                                name="paid-type"
                                defaultValue=""
                                onChange={paymentSelect}
                                disabled={state !== 'new' || loading.pay}
                              >
                                <option value="" hidden>
                                  Seleccionar
                                </option>
                                {cashier?.identify && <option value="1">Mercadopago</option>}
                                <option value="2">Transbank</option>
                                <option value="3">Efectivo</option>
                              </CFormSelect>
                              <IoChevronDownOutline className="custom-select__arrow" size="24" />
                            </div>
                          </div>
                        </CCol>
                        <CCol xs={12} sm={6} lg={4}>
                          <div className="mb-4">
                            <CFormLabel htmlFor="date">Fecha entrada</CFormLabel>
                            <CFormInput
                              type="text"
                              id="date"
                              readOnly="readOnly"
                              value={decodeISODate(orderInfo.date_from)}
                            />
                          </div>
                        </CCol>
                        <CCol xs={12} sm={6} lg={4}>
                          <div className="mb-4">
                            <CFormLabel htmlFor="cashier">Cajero(a)</CFormLabel>
                            <CFormInput
                              type="text"
                              id="cashier"
                              readOnly="readOnly"
                              defaultValue={`${user.firstname} ${user.lastname}`}
                            />
                          </div>
                        </CCol>
                      </>
                    )}
                  </CRow>

                  <hr />

                  {state === 'pending' && (
                    <CAlert color="info text-center">
                      <strong>Esperando pago</strong>
                      <br />
                      Avisar a cliente que escanee el código QR a través de la App de Mercado Pago
                    </CAlert>
                  )}
                  {state === 'expired' && (
                    <CAlert color="success text-center">Ticket expirado correctamente</CAlert>
                  )}
                  {state === 'error' && (
                    <CAlert color="danger text-center">
                      <strong>Ha ocurrido un error al caducar este ticket</strong>
                      <br />
                      {error}
                    </CAlert>
                  )}

                  <div style={{ textAlign: 'right' }} className="mb-5">
                    <div
                      style={{
                        display: 'flex',
                        gap: 20,
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end',
                      }}
                    >
                      {
                        // Salir

                        (state === 'printed' || state === 'error') && (
                          <CButton color="dark" size="lg" href="/ventas">
                            <IoLogOut size="24" style={{ marginRight: 10 }} />
                            Salir
                          </CButton>
                        )
                      }
                      {
                        // Imprimir

                        printPdf.activeBtn ? (
                          <CButton
                            color="primary"
                            size="lg"
                            style={{ color: 'white' }}
                            onClick={handlePrint}
                            dataPrint={printPdf.url}
                          >
                            <IoCashOutline size="24" style={{ marginRight: 10 }} />
                            Imprimir
                          </CButton>
                        ) : (
                          ''
                        )
                      }

                      {
                        // Omitir el pago

                        state === 'new' && skip && comment && (
                          <CButton
                            color="info"
                            size="lg"
                            style={{ color: 'white' }}
                            onClick={handlePaymentAp}
                          >
                            <IoTicketOutline size="24" style={{ marginRight: 10 }} />
                            Caducar
                            {loading.pay && <CSpinner size="sm" />}
                          </CButton>
                        )
                      }

                      {state !== 'expired' && state !== 'printed' && state !== 'error' && !skip && (
                        <>
                          {
                            // Pagar con Mercado Pago
                            payment === 1 && (
                              <CButton
                                color="secondary"
                                size="lg"
                                style={{ color: 'white' }}
                                onClick={handlePaymentMp}
                                disabled={loading.pay}
                              >
                                <IoCashOutline size="24" style={{ marginRight: 10 }} />
                                Pagar diferencia {loading.pay && <CSpinner size="sm" />}
                              </CButton>
                            )
                          }
                          {
                            // Pagar con efectivo o Transbank
                            payment !== 1 && (
                              <CButton
                                color="success"
                                size="lg"
                                style={{ color: 'white' }}
                                onClick={handlePaymentAp}
                              >
                                <IoCashOutline size="24" style={{ marginRight: 10 }} />
                                Pagar diferencia {loading.pay && <CSpinner size="sm" />}
                              </CButton>
                            )
                          }
                        </>
                      )}
                    </div>
                  </div>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
        </CCard>
      </div>
    </>
  )
}

export default ExpireTicket
