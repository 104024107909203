/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { CCard, CCardBody, CCardHeader, CSpinner } from '@coreui/react-pro'
import { format, validate } from 'rut.js'

import { getTickets } from 'src/request/tickets'
import {
  AddOrderPayment,
  AddOrderTicket,
  expireTicket as orderExchange,
  getPayments,
  CancelOrderTicket,
} from 'src/request/orders'
import { formatDateISO, validateEmail } from 'src/utils/helpers'
import { useHandleForm } from 'src/utils/hooks/useHandleForm'
import { getUsers } from 'src/request/user'
import { STATUS } from './constants'
import { handleDni } from './handlers'
import { Form } from './Form'
import { useCashierMP } from 'src/utils/hooks/useCashierMP'

export const VentasCaja = () => {
  const currentUser = JSON.parse(Cookies.get('user'))
  // const cashier = useCashierMP(currentUser.id)
  const cashier = useCashierMP(123) // SOLO PUREBA
  const [state, setState] = useState(STATUS[0])
  const [loading, setLoading] = useState({
    global: true,
    pay: false,
    dni: false,
  })
  const [disableInput, setDisableInput] = useState(true)
  const [dayValue, setDayValue] = useState(0)
  const [startDate, setStartDate] = useState(new Date())
  const [quantity, setQuantity] = useState(1)
  const [discount, setDiscount] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('')
  const [ticketData, setTicketData] = useState({})
  const [errors, setErrors] = useState({})
  const [validated, setValidated] = useState(false)
  const [transactionID, setTransactionID] = useState('')
  const [validUser, setValidUser] = useState(false)
  const [pdf, setPdf] = useState('')
  let intervalMP

  //-- formData and Update
  const [formState, setFormState] = useState({
    dni: '',
    firstname: '',
    lastname: '',
    email: '',
    date: new Date(),
    price_day: 0,
    quantity,
    total: 0,
    discount: '',
    payment_method: '',
    comment: '',
    amount: 0,
    cashier: currentUser.firstname + ' ' + currentUser.lastname,
  })

  const getTicketsList = (args) => {
    setLoading((prev) => ({
      ...prev,
      global: true,
    }))
    getTickets(args).then(({ data }) => {
      const { total = 0 } = data || {}
      setTicketData(total ? data.tickets[0] : {})
      setErrors((prev) => ({
        ...prev,
        ticket: total ? '' : 'No existen valores para la fecha seleccionada',
      }))
      setLoading((prev) => ({
        ...prev,
        global: false,
      }))
      setDisableInput(false)
    })
  }

  //-- Get data from tickets by day
  useEffect(() => {
    getTicketsList({
      date_from: formatDateISO(startDate),
      date_to: formatDateISO(startDate),
    })
  }, [startDate])

  //-- Update formData from ticket fetch
  useEffect(() => {
    const { price_day, price_school } = ticketData
    setFormState((prev) => ({
      ...prev,
      price_day,
      price_school,
    }))
    handleDayValue()
  }, [ticketData])

  //-- Update formState when inputs change
  useEffect(() => {
    setFormState((prev) => ({
      ...prev,
      quantity,
      discount,
      total: quantity * dayValue,
      amount: discount === 'free' ? 0 : quantity * dayValue,
      date: startDate,
      payment_method: paymentMethod,
    }))
  }, [quantity, discount, dayValue, startDate, paymentMethod])

  //-- Update paymentData when formData change
  useEffect(() => {
    handleDayValue()
    handleValidUser(formState)
  }, [formState])

  // Checkear si saltó un error para poder reintentar
  useEffect(() => {
    if (state === 'cancelled' || state === 'error') {
      setLoading((prev) => ({ ...prev, global: false }))
      setDisableInput(false)
      setValidated(false)
    }
  }, [state])

  const { handleChangeInput } = useHandleForm(setFormState)

  const handleState = (value = 0) => setState(STATUS[value])

  const handleUserDni = async ({ target: { value } }) => {
    if (value.length > 0) {
      if (validate(value)) {
        setLoading((prev) => ({
          ...prev,
          dni: value,
        }))
        setErrors((prev) => ({
          ...prev,
          dni: '',
        }))
        await getUsers({
          dni: format(value),
        })
          .then(({ data }) => {
            const { users } = data || {}
            console.log(users)
            const { firstname, lastname, email, id } = users[0] || {}
            setFormState((prev) => ({
              ...prev,
              firstname,
              lastname,
              email,
              user_id: id,
            }))
            setLoading((prev) => ({
              ...prev,
              dni: false,
            }))
          })
          .catch((e) => {
            setFormState((prev) => ({
              ...prev,
              firstname: '',
              lastname: '',
              email: '',
              user_id: '',
            }))
            setLoading((prev) => ({
              ...prev,
              dni: false,
            }))
          })
      } else {
        setErrors((prev) => ({
          ...prev,
          dni: 'Rut Inválido',
        }))
      }
    } else {
      setErrors((prev) => ({
        ...prev,
        dni: '',
      }))
    }
  }

  const handleValidUser = ({ dni = '', firstname = '', lastname = '', email = '' }) => {
    const userDefault = { dni: '', firstname: '', lastname: '', email: '' }
    const userErrors = {}
    if (dni || firstname || lastname || email) {
      userErrors.dni = validate(dni) ? '' : 'Campo requerido'
      userErrors.firstname = firstname ? '' : 'Campo requerido'
      userErrors.lastname = lastname ? '' : 'Campo requerido'
      userErrors.email = validateEmail(email) ? '' : 'Campo requerido'

      if (!userErrors.dni && !userErrors.firstname && !userErrors.lastname && !userErrors.email) {
        setValidUser(true)
        setErrors((prev) => ({
          ...prev,
          ...userDefault,
        }))
        return
      }
      setErrors((prev) => ({ ...prev, ...userErrors }))
      setValidUser(false)
      return
    } else {
      setErrors((prev) => ({
        ...prev,
        ...userDefault,
      }))
    }
    setValidUser(true)
  }

  const handleDayValue = () => {
    switch (discount) {
      case 'school':
        setDayValue(formState.price_school)
        break
      default:
        setDayValue(formState.price_day)
        break
    }
  }

  const handleDate = (date) => {
    date
      ? setStartDate(date)
      : setErrors((prev) => ({
          ...prev,
          date: 'Ingresar una fecha válida',
        }))
  }

  const handleQuantity = ({ target: { value } }) => {
    setQuantity(value)
  }

  const handleDiscount = ({ target: { value } }) => {
    setDiscount(value)
    value === 'free' ? setPaymentMethod(3) : setPaymentMethod('')
  }

  const handlePaymentMethod = ({ target: { value } }) => {
    setPaymentMethod(Number(value))
  }

  const handleOrderExchange = async (transaction_id) => {
    try {
      orderExchange(null, {
        transaction_id,
        application_id: 1,
      }).then(({ data: { url } }) => {
        handleState(url ? 2 : 6)
        url && setPdf(url)
        setLoading((prev) => ({
          ...prev,
          global: false,
          pay: false,
        }))
      })
    } catch (e) {
      console.log(e)
    }
  }

  const verifyMP = async (transaction_mp_id, transaction_id) => {
    intervalMP = setInterval(() => {
      try {
        getPayments(transaction_mp_id).then(({ data }) => {
          const { payments = [] } = data || {}
          const [firstPayment = {}] = payments || []
          const { payment_status: paymentStatus = {} } = firstPayment || {}
          const { id: paymentStatusId = 0, paid = false } = paymentStatus || {}
          console.log(paymentStatusId)
          if (paymentStatusId !== 1) {
            clearInterval(intervalMP)
            if (paid) {
              handleOrderExchange(transaction_id)
            }
          }
        })
      } catch (e) {
        console.log(e)
      }
    }, 10000)
  }

  const handleCancelMP = async () => {
    clearInterval(intervalMP)
    const { identify, store_code, totem_code } = cashier || {}
    try {
      await CancelOrderTicket({
        transaction_id: transactionID,
        store_code: store_code,
        totem_code: totem_code,
        identify: identify,
      }).then(({ status }) => {
        console.log(status)
        if (status === 204) {
          handleState(4)
          setLoading((prev) => ({
            ...prev,
            pay: false,
          }))
        }
      })
    } catch (e) {
      console.log(e)
    }
  }

  const handleCreateOrder = async () => {
    setDisableInput(true)
    setLoading((prev) => ({
      ...prev,
      global: true,
      pay: true,
    }))
    const payment_method_id = formState.payment_method ? formState.payment_method : 2;

    let orderData = {
      application_id: 1,
      order_type_id: 2,
      payment_method_id: payment_method_id,
      date_from: formatDateISO(formState.date),
      date_to: formatDateISO(formState.date),
      quantity: Number(formState.quantity),
      comment: formState.comment,
      user_id: formState.user_id || 0,

      user:
        !formState.user_id && formState.dni
          ? {
              dni: formState.dni,
              firstname: formState.firstname,
              lastname: formState.lastname,
              email: formState.email,
            }
          : {},
    }

    formState.discount && (orderData.entry_type = formState.discount)

    if (formState.payment_method !== 1) {
      orderData = {
        ...orderData,
        payment_status_id: 4,
      }
    }
    if (formState.payment_method === 1) {
      const { identify, store_code, totem_code } = cashier || {}
      orderData = {
        ...orderData,
        identify,
        store_code,
        totem_code,
      }
    }

    try {
      await AddOrderTicket(orderData).then(({ data }) => {
        const { order_status_id = 0, transaction_id = '', payments = [] } = data || {}
        setTransactionID(transaction_id)
        if (order_status_id === 2) {
          handleOrderExchange(transaction_id)
          return
        }

        if (order_status_id === 1 && paymentMethod === 1) {
          const [firstPayment = {}] = payments || []
          const { transaction_id: paymentTransactionId = '' } = firstPayment
          verifyMP(paymentTransactionId, transaction_id)
          return
        }

        if (paymentMethod !== 1) handleState(order_status_id)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handlePay = async () => {
    setDisableInput(true)
    setLoading((prev) => ({
      ...prev,
      pay: true,
    }))
    const paymentData = {}
    try {
      await AddOrderPayment(transactionID, paymentData).then(({ data }) => {
        console.log(data)
      })
    } catch (e) {
      console.log(e)
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    if (!validUser) return

    const form = event.currentTarget

    if (form.checkValidity()) {
      handleCreateOrder()
    }
    setValidated(true)
  }

  const handlePrint = () => {
    console.log("handlePrint ",pdf)
    if (pdf) {
/*       window.open(pdf, 'PRINT', 'height=400,width=600')
      setState('printed') */
      console.log("sendPrint pdf "+pdf)
      const urlPdf = pdf;
      const nombreImpresora = "TicketOasis";
      const url = `http://localhost:8080/url?urlPdf=${urlPdf}&impresora=${nombreImpresora}&grados=180`;

      fetch(url)
          .then(respuesta => {
              // Si la respuesta es OK, entonces todo fue bien
              if (respuesta.status === 200) {
                  console.log("Impreso correctamente (salvo que se haya indicado un error por parte de PDFtoPrinter");
                  setState('printed') 
              } else {
                  // Si no, decodificamos el mensaje para ver el error
                  respuesta.json()
                      .then(mensaje => {
                      
                          console.log("Error: " + mensaje);
                      });
              }
          });
    }
  }

  const handleTryAgain = () => {
    if (state === 'cancelled' || state === 'error') {
      setState('new')
    }
  }

  return (
    <div className="section">
      <CCard className="mb-3">
        <CCardHeader>
          <h3>Ventas por Caja {loading.global && <CSpinner />}</h3>
        </CCardHeader>
        <CCardBody>
          <Form
            state={state}
            formState={formState}
            validated={validated}
            errors={errors}
            loading={loading}
            disableInput={disableInput}
            dayValue={dayValue}
            pdf={pdf}
            cashier={cashier}
            handleDate={handleDate}
            handleDni={handleDni}
            handleUserDni={handleUserDni}
            handleDiscount={handleDiscount}
            handlePaymentMethod={handlePaymentMethod}
            handleQuantity={handleQuantity}
            handleChangeInput={handleChangeInput}
            handleSubmit={handleSubmit}
            handlePay={handlePay}
            handlePrint={handlePrint}
            paymentMethod={paymentMethod}
            handleCancelMP={handleCancelMP}
            handleTryAgain={handleTryAgain}
          />
        </CCardBody>
      </CCard>
    </div>
  )
}

export default VentasCaja
