export const LIMIT_TO_SHOW = 10
export const MIN_PAGE = 1

export const CREADO = 'CREADO'
export const APROBADO = 'APROBADO'
export const RECHAZADO = 'RECHAZADO'
export const CANCELADO = 'CANCELADO'
export const EXPIRADO = 'EXPIRADO'
export const TYPE_ID = 2

export const TABLE_COLUMNS = [
  {
    key: 'id',
    label: 'ID',
    filter: false,
    _style: { width: '100px' },
    _props: { align: 'middle', className: 'text-center' },
  },
  {
    key: 'transaction_id',
    label: 'Código',
    filter: false,
    _style: { width: '170px' },
    _props: { align: 'middle', className: 'text-center' },
  },
  {
    key: 'user_name',
    label: 'Cliente',
    filter: false,
    _props: { align: 'middle' },
  },
  {
    key: 'quantity',
    label: 'Cantidad',
    filter: false,
    _style: { width: '150px' },
    _props: { align: 'middle', className: 'text-center' },
  },
  {
    key: 'price',
    label: 'Precio ticket',
    filter: false,
    _style: { width: '10%' },
    _props: { align: 'middle' },
  },
  {
    key: 'total',
    label: 'Precio total',
    filter: false,
    _style: { width: '10%' },
    _props: { align: 'middle' },
  },
  {
    key: 'date_from',
    filter: false,
    label: 'Fecha entrada',
    _style: { width: '15%' },
    _props: { align: 'middle', className: 'text-center' },
  },
  {
    key: 'status',
    label: 'Estado',
    filter: false,
    _style: { width: '120px' },
    _props: { align: 'middle', className: 'text-center' },
  },
  {
    key: 'detail',
    label: 'Acciones',
    filter: false,
    sorter: false,
    _style: { width: '200px' },
    _props: { align: 'middle' },
  },
]

export const APPS = {
  1: 'Caja',
  2: 'Sitio web',
  3: 'Totem',
}

export const STATUS = {
  0: 'new',
  1: 'pending',
  2: 'approved',
  3: 'reject',
  4: 'cancelled',
  5: 'expired',
  6: 'error',
  7: 'printed',
}

export const ENTRY_TYPES = {
  school: {
    label: 'Colegio',
  },
  free: {
    label: 'Gratis',
  },
  full_day: {
    label: 'Full day',
    description: '09:00 a 16:00 hrs',
  },
  exempt_payment: {
    label: 'Día exento de pago',
    description: '18:00 a 19:30 hrs',
  },
}
