import React, { useEffect, useState } from 'react'
import {
  CCard,
  CCardBody,
  CForm,
  CFormFeedback,
  CFormInput,
  CImage,
  CInputGroup,
  CLoadingButton,
} from '@coreui/react-pro'
import { Redirect } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import bg from '../assets/img/banner.jpg'
import 'react-toastify/dist/ReactToastify.min.css'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { checkUser, loginUser } from 'src/actions/auth'
import 'react-toastify/dist/ReactToastify.min.css'

toast.configure()

const Login = () => {
  const dispatch = useDispatch()

  const auth = useSelector((state) => state.auth)
  const { isFetching = false, data = {}, isAuthenticated = false, errorMessage = '' } = auth

  const [formState, setFormState] = useState({ email: '', password: '' })
  const [validated, setValidated] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const handleChange = (value, name) => {
    setFormState((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    }
    dispatch(loginUser(formState))
    setValidated(true)
  }

  useEffect(() => {
    if (!isAuthenticated && !isEmpty(data)) {
      dispatch(checkUser(data))
    }
    setIsLoading(false)
    return () => setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (errorMessage) {
      toast.error('usuario y/o contraseña incorrecta', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }, [errorMessage])

  return isLoading ? (
    ''
  ) : isAuthenticated ? (
    <Redirect
      to={{
        pathname: '/dashboard',
      }}
    />
  ) : (
    <div className="min-vh-100 d-flex flex-row align-items-center justify-content-center login">
      <div className="login__bg">
        <CImage src={bg} />
      </div>
      <CCard className="login__card">
        <CCardBody>
          <CForm className="login__form" onSubmit={handleSubmit} noValidate validated={validated}>
            <h1>Login</h1>
            <p className="text-medium-emphasis">Ingresa al Dashboard de Parque Oasis</p>
            <div className="mb-4">
              <CInputGroup className="has-validation">
                <CFormInput
                  type="text"
                  onChange={(event) => {
                    const { target = {} } = event || {}
                    const { value = '' } = target
                    handleChange(value, 'email')
                  }}
                  value={formState?.email}
                  placeholder="Usuario"
                  required
                />
                <CFormFeedback invalid>Porfavor ingresa un mail.</CFormFeedback>
              </CInputGroup>
            </div>
            <div className="mb-4">
              <CFormInput
                type="password"
                value={formState?.password}
                onChange={(event) => {
                  const { target = {} } = event || {}
                  const { value = '' } = target
                  handleChange(value, 'password')
                }}
                placeholder="Password"
                required
              />
            </div>
            <div className="text-end">
              <CLoadingButton loading={isFetching} type="submit">
                Ingresar
              </CLoadingButton>
            </div>
          </CForm>
        </CCardBody>
      </CCard>
      <ToastContainer />
    </div>
  )
}

export default Login
