/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import {
  CBadge,
  CButtonGroup,
  CButton,
  CAlert,
  CTable,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CTableBody,
  CTableDataCell,
  CCard,
  CCardHeader,
  CCardBody,
  CCollapse,
  CRow,
  CCol,
  CCallout,
  CWidgetStatsF,
  CSmartPagination,
} from '@coreui/react-pro'
import {
  IoWarningOutline,
  IoCalendarClearOutline,
} from 'react-icons/io5'

import swal from '@sweetalert/with-react'
import SmartTable from 'src/components/SmartTable/SmartTable'

// import FilterTransactionId from './FilterTransactionId'
import { listOrders } from 'src/actions/orders'
import { getEventById } from 'src/actions/event'
import { getEvents } from 'src/request/event'
import { formatCLP, formatDateISO } from 'src/utils/helpers'
import { APROBADO, ENTRY_TYPES, LIMIT_TO_SHOW, MIN_PAGE, TABLE_COLUMNS, TYPE_ID } from './constants'
import { expireTicket } from 'src/request/orders'
import { getState, handleCompare, haveUpdateEvent } from './helpers'
import Detail from './detail'
import './styles.css'
import FilterTransactionId from '../Ventas/FilterTransactionId'

const Cotizaciones = () => {
  const dispatch = useDispatch()

  const orders = useSelector((state) => state.orders)
  const events = useSelector((state) => state.event)
  const [inputValue, setInputValue] = useState('')

  const { ordersList = {} } = orders || {}

  const { selected = {} } = events || {}
  const { loading = false, data: selectedEvent = {} } = selected
  const { orders: ordersData = [], total = 0 } = ordersList

  const [page, setPage] = useState(0)

  const [totalPages, setTotalPage] = useState(0)

  const [details, setDetails] = useState([])

  const showPage = page + MIN_PAGE

  // Handle Offset
  const handleOffset = (offset) => (offset >= 0 ? offset : 0)

  //Filter's handle
  const handleChangeFilter = ({ target }) => {
    setInputValue(target.value)
  }

  const handleClearFilter = () => {
    setInputValue('')
    dispatch(listOrders({ limit: LIMIT_TO_SHOW, offset: 0, order_type_id: TYPE_ID }))
    setPage(0)
    return
  }

  const handleSendInput = (e) => {
    e.preventDefault()
    let filterInput = document.getElementById('filter-transaction-id')

    setTimeout(() => {
      // setPage(0)
      dispatch(
        listOrders({
          transaction_id: String(inputValue),
          order_type_id: TYPE_ID,
        }),
      )
      // setInputValue('')
      filterInput.focus()
    }, 500)
    return true
  }

  //Use order ticket handle

  const getDetailsPurchasedEvent = async (eventId) => {
    dispatch(getEventById(eventId))
    return
  }

  const getTodayEvent = async () => {
    const { type = 0 } = selectedEvent
    const { id: typeId } = type
    const day = format(new Date(), 'yyyy-MM-dd')
    const responseCurrentEvent = await getEvents({ date: day, type_id: typeId })

    const { data } = responseCurrentEvent
    const { events = [] } = data || {}
    const [firstEvent = {}] = events || []

    return firstEvent
  }

  const handleCaducar = async (ticket) => {
    const { event = {}, id: orderId, paid = false, used = false, date_from } = ticket || {}

    // const { date_from = '' } = event

    const statusTicket = getState(paid, used)
    const { label: statusName = '' } = statusTicket

    // const transformDate = formatDateISO(start_date_time)
    const transformDate = formatDateISO(date_from)

    const ticketUpdated = haveUpdateEvent(date_from, statusName)

    let evenToAttend = event
    let differencePrice = 0

    if (ticketUpdated) {
      const { id: eventId } = event
      await getDetailsPurchasedEvent(eventId)
      evenToAttend = await getTodayEvent()
    }

    const compare = await handleCompare(evenToAttend, ticket)
    if (compare !== 0) {
      differencePrice = compare
    }

    ticket
      ? swal({
          buttons: {
            cancel: 'Cancelar',
            confirm: 'Caducar',
          },
          dangerMode: true,
          content: (
            <div className="swal__content">
              {differencePrice > 0 && (
                <CAlert
                  color="warning"
                  variant="solid"
                  className="mb-5"
                  style={{
                    marginTop: -50,
                  }}
                >
                  <h4
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 10,
                      justifyContent: 'center',
                    }}
                  >
                    <IoWarningOutline /> Aviso <IoWarningOutline />
                  </h4>
                  Este usuario compró para el día <strong>{transformDate}</strong>, hay una
                  diferencia en el valor de
                  <strong>{differencePrice >= 0 ? ` ${formatCLP(differencePrice)}` : ' $0'}</strong>
                </CAlert>
              )}
              <div className="pb-4">
                <h3>
                  <code>#{ticket.transaction_id}</code>
                </h3>
                <h3>¿Desea caducar este ticket?</h3>
                <p></p>
                <CTable color="dark" style={{ width: '100%' }}>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell>Cliente</CTableHeaderCell>
                      <CTableHeaderCell>Cantidad</CTableHeaderCell>
                      <CTableHeaderCell>Precio Total</CTableHeaderCell>
                      <CTableHeaderCell>Fecha Entrada</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    <CTableDataCell>{`${ticket.user_name.toUpperCase()}`}</CTableDataCell>
                    <CTableDataCell>{ticket.quantity}</CTableDataCell>
                    <CTableDataCell>{formatCLP(ticket.price * ticket.quantity)}</CTableDataCell>
                    <CTableDataCell>{formatDateISO(ticket?.date_from)}</CTableDataCell>
                  </CTableBody>
                </CTable>
              </div>
            </div>
          ),
        }).then(async (value) => {
          if (value) {
            const { id: currentEventId = 0 } = evenToAttend

            await expireTicket(orderId, {
              event_id: currentEventId,
              difference: differencePrice,
            })
              .then((data) => {
                /* const { status = 200 } = data */

                swal({
                  content: (
                    <div className="swal__content">
                      <h3>Se ha caducado el Sgte. ticket</h3>
                      <h3>
                        <code>#{ticket.transaction_id}</code>
                      </h3>
                    </div>
                  ),
                  icon: 'success',
                }).then((confirm) => {
                  if (confirm) {
                    dispatch(
                      listOrders({
                        limit: LIMIT_TO_SHOW,
                        offset: page * LIMIT_TO_SHOW,
                        order_type_id: TYPE_ID,
                      }),
                    )
                  }
                })
              })
              .catch(({ response = {} }) => {
                const { data = {} } = response
                const { error = '' } = data

                swal({
                  content: (
                    <div className="swal__content">
                      <h3>Ups! No se pudo caducar</h3>
                      <h3>
                        <code>{error}</code>
                      </h3>
                    </div>
                  ),
                  icon: 'warning',
                })
              })
          }
        })
      : swal(
          <div className="swal__content">
            <h3>Error al cargar el detalle de este ticket</h3>
          </div>,
          null,
          'error',
        )
  }
  const handleChangePage = (value = 1) => {
    const pageToQuery = value ? +value - 1 : 0
    setPage(pageToQuery)
  }

  useEffect(() => {
    dispatch(
      listOrders({
        limit: LIMIT_TO_SHOW,
        offset: handleOffset(Math.ceil(page * LIMIT_TO_SHOW)),
        order_type_id: TYPE_ID,
      }),
    )
  }, [page, dispatch])

  useEffect(() => {
    if (total) setTotalPage(Math.ceil(total / LIMIT_TO_SHOW))
  }, [total])

  const toggleDetails = (index) => {
    const position = details.indexOf(index)
    let newDetails = details.slice()
    if (position !== -1) {
      newDetails.splice(position, 1)
    } else {
      newDetails = [...details, index]
    }
    setDetails(newDetails)
  }

  return (
    <CCard className="table-list">
      <CCardHeader>
        <h1 className="h3">Cotizaciones</h1>
      </CCardHeader>
      <CCardBody>
        {/* <div className="position-relative">
          <div className="table-list__toolbar-table">
            <CButtonGroup>
              <CButton tabIndex="0" role="button" color="danger">
            Limpiar todo
          </CButton>
              <CDropdown variant="btn-group">
                <CDropdownToggle color="primary">Descargar</CDropdownToggle>
                <CDropdownMenu>
                  <CDropdownItem href="#">PDF</CDropdownItem>
                  <CDropdownItem href="#">CSV</CDropdownItem>
                  <CDropdownItem href="#">JSON</CDropdownItem>
                </CDropdownMenu>
              </CDropdown>
            </CButtonGroup>
          </div>
        </div> */}

        <FilterTransactionId
          handleChangeFilter={handleChangeFilter}
          handleSendInput={handleSendInput}
          handleClearFilter={handleClearFilter}
          inputValue={inputValue}
        />

        <SmartTable
          responsive="true"
          items={ordersData}
          columns={TABLE_COLUMNS}
          // itemsPerPageSelect
          itemsPerPage={LIMIT_TO_SHOW}
          itemsPerPageLabel="Filas por página"
          loading={loading}
          sorterValue={{
            key: 'id',
          }}
          tableProps={{
            hover: true,
          }}
          scopedColumns={{
            id: (item) => (
              <td
                className="text-center"
                style={{ display: details.includes(item?.id) ? 'none' : '' }}
              >
                {item?.id}
              </td>
            ),
            transaction_id: (item) => (
              <td
                className="text-center"
                style={{ display: details.includes(item.id) ? 'none' : '' }}
              >
                {item.transaction_id}
              </td>
            ),

            user_name: (item) => {
              return (
                <td style={{ display: details.includes(item.id) ? 'none' : '' }}>
                  {`${item?.user_name.toUpperCase()}`}
                </td>
              )
            },
            entry_type: ({ entry_type }) => {
              const { label = '', description = '' } = ENTRY_TYPES[entry_type] || {}
              return (
                <td className="text-center">
                  {entry_type && (
                    <>
                      {label && (
                        <div>
                          <strong>{label}</strong>
                        </div>
                      )}
                      {description && <div>{description}</div>}
                    </>
                  )}
                </td>
              )
            },

            quantity: (item) => (
              <td
                className="text-center"
                style={{ display: details.includes(item.id) ? 'none' : '' }}
              >
                {item?.quantity}
              </td>
            ),

            price: (item) => (
              <td className="text-end" style={{ display: details.includes(item.id) ? 'none' : '' }}>
                {formatCLP(item?.price)}
              </td>
            ),

            total: (item) => (
              <td className="text-end" style={{ display: details.includes(item.id) ? 'none' : '' }}>
                {/* {formatCLP(item?.price + item?.difference)} */}
                {formatCLP(item?.price * item?.quantity)}
              </td>
            ),

            date_from: (item) => (
              <td
                className="text-center"
                style={{ display: details.includes(item.id) ? 'none' : '' }}
              >
                {formatDateISO(item?.date_from)}
              </td>
            ),
            date_to: (item) => (
              <td
                className="text-center"
                style={{ display: details.includes(item.id) ? 'none' : '' }}
              >
                {formatDateISO(item?.date_to)}
              </td>
            ),

            status: (item) => {
              // const { paid = false, used = false } = item
              const status = getState(item.order_status_id)
              const { label = '', color = '' } = status
              return (
                <td
                  className="text-center"
                  style={{ display: details.includes(item.id) ? 'none' : '' }}
                >
                  <CBadge color={color} style={{ fontSize: '0,5em' }}>
                    {label}
                  </CBadge>
                </td>
              )
            },

            detail: (item) => {
              // const { paid = false, used = false } = item
              return (
                <td
                  className="text-center"
                  style={{ display: details.includes(item.id) ? 'none' : '' }}
                >
                  <CButtonGroup size="sm" role="group">
                    <CButton
                      color="secondary"
                      onClick={() => {
                        toggleDetails(item.id)
                      }}
                    >
                      Ver
                    </CButton>
                    {item.order_status_id !== 3 && (
                      <CButton color="danger" onClick={() => handleCaducar(item)}>
                        Caducar
                      </CButton>
                    )}
                  </CButtonGroup>
                </td>
              )
            },

            details: (item) => {
              const { used = false, used_date: usedDate = '' } = item
              const { label = '' } = getState(item.order_status_id)
              return (
                <CCollapse visible={details.includes(item.id)}>
                  <CCallout
                    color={label === APROBADO ? 'success' : 'dark'}
                    style={{ marginTop: 0 }}
                  >
                    {details.includes(item.id) && (
                      <Detail
                        item={item}
                        handleCaducar={handleCaducar}
                        toggleDetails={toggleDetails}
                      />
                    )}
                    <CRow>
                      {used && (
                        <CCol>
                          <CWidgetStatsF
                            className="mb-3"
                            color="warning"
                            icon={<IoCalendarClearOutline size="1.5em" />}
                            padding={false}
                            title="Fecha Caducada"
                            value={formatDateISO(usedDate)}
                          />
                        </CCol>
                      )}
                      {used && (
                        <CCol>
                          {/* <CWidgetStatsF
                            className="mb-3"
                            color="warning"
                            icon={<IoPersonSharp size="1.5em" />}
                            padding={false}
                            title="Caducado por"
                            value={`${item?.cashier?.firstname} ${item?.cashier?.lastname}`}
                          /> */}
                        </CCol>
                      )}
                    </CRow>
                    {/* <div className="text-end">
                      <CButtonGroup role="group" className="text-end">
                        <CButton
                          color="dark"
                          onClick={() => {
                            toggleDetails(item.id)
                          }}
                        >
                          Cerrar
                        </CButton>
                        {item.order_status_id !== 3 && (
                          <CButton
                            color="danger"
                            disabled={() => handleContent()}
                            onClick={() => handleCaducar(item)}
                          >
                            Caducar
                          </CButton>
                        )}
                      </CButtonGroup>
                    </div> */}
                  </CCallout>
                </CCollapse>
              )
            },
          }}
          clickableRows
        />

        {/* {total > LIMIT_TO_SHOW ? 'si' : 'no'} */}

        {ordersData && total > LIMIT_TO_SHOW && (
          <CSmartPagination
            activePage={showPage}
            pages={totalPages}
            onActivePageChange={handleChangePage}
          />
        )}
      </CCardBody>
    </CCard>
  )
}

export default Cotizaciones
