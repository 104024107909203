export const LIMIT_TO_SHOW = 10
export const MIN_PAGE = 1

export const APPS_NAMES = {
  cashier: { name: 'Caja', color: 'warning' },
  website: { name: 'Sitio Web', color: 'info' },
  totem: { name: 'Totem', color: 'success' },
}

export const TABLE_COLUMNS = [
  {
    key: 'id',
    label: 'ID',
    filter: false,
  },
  {
    key: 'date',
    label: 'Fecha',
    filter: false,
  },
  {
    key: 'web_value',
    label: 'Valor Internet',
    filter: false,
  },
  {
    key: 'store_value',
    label: 'Valor Tienda',
    filter: false,
  },
  {
    key: 'night_value',
    label: 'Valor Camping',
    filter: false,
  },
  {
    key: 'school_value',
    label: 'Valor Colegio',
    filter: false,
  },
  {
    key: 'locked',
    label: 'Aplicación bloqueada',
    filter: false,
    _props: { align: 'middle', className: 'text-center' },
  },
  // {
  //   key: 'edit',
  //   label: 'Acciones',
  //   filter: false,
  //   _props: { align: 'middle' },
  // },
]
