import { GET_ROLES_REQUEST, GET_ROLES_SUCCESS, GET_ROLES_FAILURE } from '../types/role'

const initialState = {
  err: {},
  isFetching: false,
  data: [],
}

const roleReducer = (state = initialState, action = {}) => {
  const { type = '', payload = {}, err = { message: 'No Allowed' } } = action
  switch (type) {
    case GET_ROLES_REQUEST: {
      return {
        ...state,
        isFetching: true,
        err: null,
      }
    }
    case GET_ROLES_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: payload,
      }
    }
    case GET_ROLES_FAILURE: {
      return {
        ...state,
        isFetching: false,
        err,
      }
    }
    default: {
      return state
    }
  }
}

export default roleReducer
