import React from 'react'
import { formatCLP, formatDateISO } from 'src/utils/helpers'

export const tableColumns = () => {
  return {
    id: (item) => <td className="text-center">{item?.id}</td>,
    transaction_id: (item) => <td className="text-center">{item.transaction_id}</td>,
    user_name: (item) => <td>{`${item?.user_name.toUpperCase()}`}</td>,
    quantity: (item) => <td className="text-center">{item?.quantity}</td>,
    price: (item) => <td className="text-end">{formatCLP(item?.price)}</td>,
    total: (item) => <td className="text-end">{formatCLP(item?.price * item?.quantity)}</td>,
    date_from: (item) => <td className="text-center">{formatDateISO(item?.date_from)}</td>,
    date_to: (item) => <td className="text-center">{formatDateISO(item?.date_to)}</td>,
  }
}
