import { useEffect } from 'react'
import { useState } from 'react'
import { getMonthStats } from 'src/request/orders'
import { formatCLP } from 'src/utils/helpers'
import { MONTH_DATE } from '../constants'

export const useMonth = () => {
  const [chart, setChart] = useState({
    total: 0,
    options: {
      plugins: {
        legend: false,
        tooltip: {
          callbacks: {
            label: (value) => `Total: ${formatCLP(value.parsed.y)}`,
          },
        },
      },
      widgets: {
        resposive: true,
      },
      scales: {
        yAxis: {
          ticks: {
            callback: (value) => formatCLP(value),
          },
        },
      },
    },
    months: {},
  })
  const [loading, setLoading] = useState(true)

  const handleChartFetch = async () => {
    setLoading(true)
    try {
      await getMonthStats(MONTH_DATE).then(({ data }) => {
        setChart((prev) => ({
          ...prev,
          total: data.length,
          months: {
            labels: data?.map((x) => x.day),
            datasets: [
              {
                data: data?.map((x) => x.total),
                fill: false,
                backgroundColor: 'rgba(51, 153, 255, 0.7)',
                borderColor: 'rgba(51, 153, 255, 0.2)',
              },
            ],
          },
        }))
      })
    } catch (e) {
      console.log(e)
    }
    setLoading(false)
  }

  useEffect(() => {
    handleChartFetch()
  }, [])

  console.log(MONTH_DATE)

  return {
    chart,
    loading,
  }
}
