/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react'
import {
  IoAlertCircleOutline,
  IoApps,
  IoCalendarClearOutline,
  IoCashOutline,
  IoPeopleOutline,
} from 'react-icons/io5'

// Core-UI
import {
  CBadge,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CSpinner,
  CWidgetStatsF,
} from '@coreui/react-pro'

// Requests
import { getUser } from 'src/request/user'

// Helpers
import { getState } from './helpers'
import { formatCLP, getExactDate } from 'src/utils/helpers'
import { format } from 'rut.js'
import { APPS, ENTRY_TYPES } from './constants'

// eslint-disable-next-line react/prop-types
const Detail = ({ item, toggleDetails, handleCaducar }) => {
  const [loading, setLoading] = useState(true)

  // User Data
  const [user, setUser] = useState([])
  const {
    id,
    user_id,
    transaction_id: code,
    order_status_id: status,
    quantity,
    price,
    updated,
    comment,
    application_id,
    entry_type,
  } = item || {}
  useEffect(() => {
    getUser(user_id).then((response) => {
      setUser(response.data)
      setLoading(false)
    })
  }, [user_id])

  // Order status helper
  const orderStatus = getState(status)
  const { label = '', color = '' } = orderStatus

  const entryType = (entry_type) => {
    const { label = '' } = ENTRY_TYPES[entry_type] || {}
    return (
      <CCol xs={12} md={6}>
        <CWidgetStatsF
          className="mb-3"
          color="primary"
          icon={<IoAlertCircleOutline size="1.5em" />}
          padding={false}
          title="Descuento"
          value={label}
        />
      </CCol>
    )
  }

  return loading ? (
    <CSpinner />
  ) : (
    <CCard color="dark" style={{ marginBottom: 20 }}>
      {/* <CCardHeader style={{ display: 'flex', gap: 20, alignItems: 'center' }}></CCardHeader> */}
      <CCardBody>
        <CRow>
          <CCol xs={12} md={6}>
            <CBadge color={color} style={{ fontSize: '0,5rem' }}>
              {label}
            </CBadge>
            <h3>
              <code>#{code}</code>
            </h3>
            <h5>
              {user?.firstname?.toUpperCase()} {user?.lastname?.toUpperCase()}
            </h5>
            {user?.email && (
              <p style={{ marginBottom: 10 }}>
                <strong>EMAIL:</strong> <br />
                {user.email}
              </p>
            )}
            {user?.additionals?.map((additional) => {
              const validateType = ({ type, value }) => {
                if (type === 'dni') {
                  return (
                    <p style={{ marginBottom: 10 }}>
                      <strong>{type.toUpperCase()}: </strong> <br />
                      {format(value)}
                    </p>
                  )
                }
                return (
                  <p style={{ marginBottom: 10 }}>
                    <strong>{additional.type.toUpperCase()}: </strong>
                    <br />
                    {value}
                  </p>
                )
              }
              return <div key={additional.value}>{validateType(additional)}</div>
            })}
            {comment && (
              <p style={{ marginBottom: 10 }}>
                <strong>COMENTARIO:</strong> <br />
                {comment}
              </p>
            )}
          </CCol>
          <CCol xs={12} md={6}>
            <CRow>
              <CCol xs={12} md={6}>
                <CWidgetStatsF
                  className="mb-3"
                  color="info"
                  icon={<IoPeopleOutline size="1.5em" />}
                  padding={false}
                  title="Cantidad"
                  value={quantity}
                />
              </CCol>
              <CCol xs={12} md={6}>
                <CWidgetStatsF
                  className="mb-3"
                  color="success"
                  icon={<IoCashOutline size="1.5em" />}
                  padding={false}
                  title="Precio total"
                  // value={formatCLP(item?.price + item?.difference)}
                  value={formatCLP(price * quantity)}
                />
              </CCol>
              <CCol xs={12} md={6}>
                <CWidgetStatsF
                  className="mb-3"
                  color="secondary"
                  icon={<IoApps size="1.5em" />}
                  padding={false}
                  title="Aplicación"
                  // value={formatCLP(item?.price + item?.difference)}
                  value={APPS[application_id]}
                />
              </CCol>
              {status === 5 && (
                <>
                  <CCol xs={12} md={6}>
                    <CWidgetStatsF
                      className="mb-3"
                      color="warning"
                      icon={<IoCalendarClearOutline size="1.5em" />}
                      padding={false}
                      title="Fecha Expiración"
                      value={getExactDate(updated)}
                    />
                  </CCol>
                </>
              )}
              {entry_type && entryType(entry_type)}
            </CRow>
          </CCol>
        </CRow>
        <div className="text-end">
          <CButtonGroup role="group" className="text-end">
            <CButton
              color="dark"
              onClick={() => {
                toggleDetails(id)
              }}
            >
              Cerrar
            </CButton>
            {status === 2 && (
              <CButton color="danger" disabled={loading} onClick={() => handleCaducar(item)}>
                Caducar
              </CButton>
            )}
          </CButtonGroup>
        </div>
      </CCardBody>
    </CCard>
  )
}

export default Detail
