import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { CContainer, CSpinner } from '@coreui/react-pro'

// routes config
import routes from '../routes'
import { hasMatchingRole } from 'src/utils/helpers/hasMatchingRole'

const AppContent = () => {
  const { data = {} } = useSelector((state) => state.auth)
  const { roles = [] } = data

  return (
    <CContainer lg>
      <Suspense fallback={<CSpinner color="primary" />}>
        <Switch>
          {routes.map((route, idx) => {
            if (hasMatchingRole(roles, route.auth_roles)) {
              return (
                route.component && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => (
                      <>
                        <route.component {...props} />
                      </>
                    )}
                  />
                )
              )
            } else {
              return null
            }
            // return (
            //   route.component && (
            //     <Route
            //       key={idx}
            //       path={route.path}
            //       exact={route.exact}
            //       name={route.name}
            //       render={(props) => (
            //         <>
            //           <route.component {...props} />
            //         </>
            //       )}
            //     />
            //   )
            // )
          })}
          <Redirect from="/" to="/dashboard" />
        </Switch>
      </Suspense>
    </CContainer>
  )
}

export default React.memo(AppContent)
