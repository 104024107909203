import { isToday } from 'date-fns'
import { APROBADO, RECHAZADO, CREADO, CANCELADO, EXPIRADO } from './constants'

const getState = (status = false) => {
  if (status === 1) {
    return { label: CREADO, color: 'info' }
  } else if (status === 2) {
    return { label: APROBADO, color: 'success' }
  } else if (status === 3) {
    return { label: RECHAZADO, color: 'danger' }
  } else if (status === 4) {
    return { label: CANCELADO, color: 'danger' }
  } else if (status === 5) {
    return { label: EXPIRADO, color: 'primary' }
  }
  return { label: '', color: '' }
}

const handleCompare = async (originalEvent, purchasedTicket) => {
  const { price: originalPrice = 0 } = originalEvent || {}
  const { tickets = 0, price = 0 } = purchasedTicket || {}

  const originalTotal = originalPrice * tickets
  const currentTotal = (price / tickets) * tickets
  const difference = originalTotal - currentTotal

  return difference
}

const haveUpdateEvent = (startTime = '', ticketStatus = '') => {
  const currentDayEvent = isToday(new Date(startTime))
  const update = ticketStatus === APROBADO && !currentDayEvent
  return update
}

export { getState, handleCompare, haveUpdateEvent }
