export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

export const TOKEN_REQUEST = 'TOKEN_REQUEST'
export const TOKEN_SUCCESS = 'TOKEN_SUCCESS'
export const TOKEN_ERROR = 'TOKEN_ERROR'
export const TOKEN_CHECK = 'TOKEN_CHECK'

export const RECOVERY_REQUEST = 'RECOVERY_REQUEST'
export const RECOVERY_SUCCESS = 'RECOVERY_SUCCESS'
export const RECOVERY_ERROR = 'RECOVERY_ERROR'

export const RESET_REQUEST = 'RESET_REQUEST'
export const RESET_SUCCESS = 'RESET_SUCCESS'
export const RESET_ERROR = 'RESET_ERROR'
