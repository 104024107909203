import React from 'react'
import swal from '@sweetalert/with-react'
import { format } from 'rut.js'

export const handleDefaultError = (
  title = '!Ups, ha ocurrido un error!',
  desc = 'Se ha generado un error desconocido al generar el pago, prueba reintentar',
) => {
  swal({
    buttons: {
      confirm: 'Reintentar',
    },
    dangerMode: true,
    content: (
      <div className="swal__content">
        <div className="mb-4">
          <h3>{title}</h3>
          <p>{desc}</p>
        </div>
      </div>
    ),
  }).then(async (value) => {
    window.location = '/ventas-caja'
  })
}

export const handleDni = (event) => {
  const { target } = event
  const { value } = target
  if (value.length > 3) target.value = format(value)
}
