import { api } from 'src/utils/helpers/api'

import { transformQueryParams } from 'src/utils/helpers/transformQueryParams'

export const getEvents = (queryParams = {}) => {
  const { limitFrom, limitTo } = queryParams
  const currentQuery = { ...queryParams, limitFrom: limitFrom || 0, limitTo: limitTo || 12 }
  const query = transformQueryParams(currentQuery)

  return api({
    method: 'GET',
    url: `/event`,
    params: query,
  })
}

export const getEvent = (eventId = 0) => {
  return api({
    method: 'GET',
    url: `/event/${eventId}`,
  })
}

export const getEventType = () => {
  return api({
    method: 'GET',
    url: '/event/type',
  })
}
